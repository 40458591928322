<template>
  <div v-if="show" class="section-box">
    <div class="container ">
      <div class="row text-white">
        <div class="col-lg-8">
          <h2 class="text-white">
            Please feel free to contact us with any query,
          </h2>
          <p>
            Feedback or just to get in touch with us. We look forward to hearing
            from you.
          </p>
        </div>
        <div class="col-lg-4">
          <router-link :to="$route.meta.toContactUs">
            <button type="button" class="btn btn-primary btn-lg contact-btn">
              Contact Us
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      show: true,
    };
  },

  mounted() {
    if (this.$route.path === this.$route.meta.toContactUs) {
      this.show = false;
    } else {
      this.show = true;
    }
  },
});
</script>

<style scoped>
.section-box {
  background-color: #1a5ec3;
  padding-top: 70px;
  padding-bottom: 70px;
}

.contact-btn {
  background: #1a5ec3;
  border-color: #1a5ec3;
  border-width: 2px;
  color: #fff;
  padding: 12px 30px 12px 30px;
  text-transform: uppercase;
  transition: 0.5s;
}

.contact-btn:hover {
  background: #de0f17;
  border-color: #de0f17;
  transform: translateY(-5px);
}

.contact-btn {
  background-color: #de0f17;
  color: #fff;
  margin-top: 25px;
}
.contact-btn:hover {
  background: #fff;
  border-color: #fff;
  color: #de0f17;
  transform: translateY(-5px);
}
</style>
