
import { defineComponent } from 'vue';
import { getIndividualDoctorDetails } from '@/graphql';
import { useQuery } from '@/hooks';

import Loader from '@/components/LoadingAnimation.vue';
import {
  DoctorDetailDataInterface,
  DoctorDetailInterface,
} from '@/ts/interfaces';
import { DOCTOR_DETAIL_PAGE_NAMES } from '@/ts/enum';

export default defineComponent({
  data(): DoctorDetailDataInterface {
    return {
      loading: true,
      doctorDetail: {},
    };
  },
  components: {
    Loader,
  },

  mounted() {
    if (this.$route.params?.doctorId) {
      (async () => {
        const { data, loading, errors } = await useQuery(
          getIndividualDoctorDetails,
          {
            id: this.$route.params?.doctorId,
          }
        );

        this.loading = loading;
        if (errors) {
          alert(
            'Something went wrong, - possible reason :' + JSON.stringify(errors)
          );
        }

        this.doctorDetail = data.doctors as DoctorDetailInterface;

        document.title =
          DOCTOR_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY +
          this.doctorDetail.name;
      })();
    }
  },
});
