
import { defineComponent } from 'vue';
import { FooterDateInterface } from '@/ts/interfaces';
import { ROUTING_PATHS } from '@/ts/enum';
import { ALAPPUZHA_HSP_SOCIAL_LINKS } from '@/static/data';
import {
  KOZHENCHERRY_HSP_SOCIAL_LINKS,
  PUNALUR_HSP_SOCIAL_LINKS,
} from '@/static/data/hospitalsSocialLinks';

export default defineComponent({
  name: 'Footer',
  data(): FooterDateInterface {
    return {
      footerClass: '',
      linksColor: '',
      address: { heading: '', address: '' },
      phone: '',
      email: '',
      toRoutes: {
        toDefaultHome: this.$route.meta.toDefaultHome,
        toHome: this.$route.meta.toHome,
        toAboutUs: this.$route.meta.toAboutUs,
        toDoctors: this.$route.meta.toDoctors,
        toDepartments: this.$route.meta.toDepartments,
        toFacilities: this.$route.meta.toFacilities,
        toGallery: this.$route.meta.toGallery,
        toNews: this.$route.meta.toNews,
        toCarrers: this.$route.meta.toCarrers,
        toTestimonials: this.$route.meta.toTestimonials,
        toContactUs: this.$route.meta.toContactUs,
        toAppointment: this.$route.meta.toAppointment,
      },
    };
  },
  props: {
    footerColor: {
      type: String,
      required: true,
    },
    showLogo: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    if (this.footerColor === 'blue') {
      this.footerClass = 'bg-color-white pt-5';
      this.linksColor = 'text-red link';
    }
    if (this.footerColor === 'white') {
      this.linksColor = 'text-white link';
      this.footerClass = 'bg-color-blue pt-5';
    }

    if (this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_HOME) {
      this.email = ALAPPUZHA_HSP_SOCIAL_LINKS.email;
      this.phone = ALAPPUZHA_HSP_SOCIAL_LINKS.contactNumber;
      this.address = {
        heading: 'Contact Providence Hospital',
        address:
          'Thumpoly Road, Near Pomkavu NH66 Junction, Thumpoly, Alappuzha, Kerala 688521',
      };
    }
    if (
      this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_HOME
    ) {
      this.email = KOZHENCHERRY_HSP_SOCIAL_LINKS.email;
      this.phone = KOZHENCHERRY_HSP_SOCIAL_LINKS.contactNumber;
      this.address = {
        heading: 'Contact Poyanil Hospital',
        address:
          'Poyanil Junction, Kozhencherry Pathanamthitta District, Kerala 689641',
      };
    }

    if (this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_PUNALUR_HOME) {
      this.email = PUNALUR_HSP_SOCIAL_LINKS.email;
      this.phone = PUNALUR_HSP_SOCIAL_LINKS.contactNumber;
      this.address = {
        heading: 'Contact Poyanil Hospital',
        address: 'Poyanil Hospital Chemmanthoor, Punalur, Kerala 691305',
      };
    }
  },
});
