import { apolloClient } from "@/apollo";
import { ApolloQueryResult, QueryOptions } from "apollo-client";
import { DocumentNode } from "graphql";

interface QueryStateInterface {
  loading: boolean;
  data: ApolloQueryResult<any> | undefined;
  error: any;
}

/**
 *
 * @deprecated
 */
export const useFetch: (
  query: DocumentNode
) => Promise<QueryStateInterface> = async (query) => {
  try {
    const { data } = await apolloClient.query({ query });
    const returnValues: QueryStateInterface = {
      data,
      error: null,
      loading: false,
    };

    return returnValues;
  } catch (e) {
    const error: Error = e;
    const returnValues: QueryStateInterface = {
      data: undefined,
      error,
      loading: false,
    };
    return returnValues;
  }
};

/**
 *
 * @param query - Document node
 * @param variables - type any
 */
export const useQuery: (
  query: DocumentNode,
  variables?: any
) => Promise<ApolloQueryResult<any>> = async (query, variables) => {
  let queryModel: QueryOptions = { query };
  if (variables) {
    queryModel = { query, variables };
  }
  return await apolloClient.query(queryModel);
};
