
import { defineComponent } from 'vue';

import Header from '@/components/CarouselHeader.vue';
import Stats from '@/components/Stats.vue';
import TestimonialDetails from '@/components/TestimonialDetail/Template1/Detail.vue';

export default defineComponent({
  components: {
    Header,
    Stats,
    TestimonialDetails,
  },
  data() {
    return {
      bgIMG: require('@/assets/img/doctors-banner.png'),
    };
  },
});
