<template>
  <section
    class="booking booking-overlay mt-5"
    :style="{ 'background-image': 'url(' + appointmentBGI + ')' }"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="header-inner-title">
            <h3 class="text-white text-center">Book Appointment</h3>
            <div class="d-flex justify-content-center mt-5">
              <router-link :to="$route.meta.toAppointment">
                <p>
                  <button type="button" class="btn btn-light">Book Now</button>
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      appointmentBGI: require("@/assets/img/booking-image.png"),
    };
  },
});
</script>
