<template>
  <div id="testimonials">
    <div class="container">
      <div class="section-title">
        <h2>Our Testimonials</h2>
        <p>
          We take our Motto "We Care, We serve" seriously.
          <br />
          Here are a few people who loved their experience at Providence
          Hospital.
        </p>
      </div>

      <section class="circle-1">
        <TestimonialSwipers />
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import TestimonialSwipers from '@/components/HospitalsHomePage/TestimonialsSwipers.vue';

export default defineComponent({
  components: { TestimonialSwipers },
});
</script>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
}
.section-title:after,
.section-title:before {
  content: '';
  position: absolute;
  width: 3px;
  height: 50%;
  left: 10px;
  top: 0;
  background: #de0f17;
  transition: 0.5s;
}

.section-title:after {
  left: 0;
  height: 100%;
}

#testimonials {
  margin-top: 100px;
}

#testimonials {
  background-color: #f1f1f1;
  padding-top: 60px;
  padding-bottom: 80px;
}
</style>
