<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>ENT [EAR, NOSE, AND THROAT]</h2>
      <p>
        Services of highly qualified consultants available 24 x7 with facilities
        to handle all ear, nose and throat surgeries including Endoscopic
        surgeries.
      </p>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <p>Services Include :</p>
        <ul>
          <li>FESS</li>
          <li>Tonsilectomy</li>
          <li>Rhinoplasty</li>
          <li>Ear surgeries</li>
        </ul>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DoctorsList from '@/components/Departments/common/DoctorsListTemplate3.vue';
import { DepartmentPropDataInterface } from '@/ts/interfaces';

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: 'ENT [EAR,NOSE,AND THROAT.] Doctors',
      queryName: 'E N T',
      teamName: 'ENT [EAR,NOSE,AND THROAT.] team',
    };
  },
  components: { DoctorsList },
});
</script>
