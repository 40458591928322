<template>
  <div id="carousel">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div
          v-for="({ image, title, description }, i) in carouselItems"
          :key="i"
          class="carousel-item"
          :class="{ active: i === 0 }"
        >
          <img class="d-block w-100" :src="image" :alt="title" />
          <div class="carousel-caption ">
            <h1>{{ title }}</h1>
            <h5>
              {{ description }}
            </h5>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
/**
 * @Shared between hospital 1 and 2
 */

import { defineComponent } from "vue";
import { CarouselPropType } from "@/ts/interfaces";

export default defineComponent({
  name: "Carousel",
  props: {
    carouselItems: {
      type: Object as () => CarouselPropType,
      required: true,
    },
  },
});
</script>
