<template>
  <Header
    :bgIMG="bgIMG"
    :isCenter="false"
    :subTitle="'Providence Hospital'"
    :title="'Doctors'"
  />
  <DoctorsList />
  <Stats />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import DoctorsList from "@/components/Doctors/Template1/DoctorsList.vue";
import Stats from "@/components/Stats.vue";

export default defineComponent({
  components: {
    Header,
    DoctorsList,
    Stats,
  },
  data() {
    return {
      bgIMG: require("@/assets/img/doctors-banner.png"),
    };
  },
});
</script>
