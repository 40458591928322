<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Interventional Cardiology</h2>
      <p>
        An interventional cardiologist is a cardiologist with one to two years
        of additional education and training in diagnosing and treating
        cardiovascular disease as well as congenital (present at birth) and
        structural heart conditions through catheter-based procedures, such as
        angioplasty and stenting.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/interventional-cardiology.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          The department of cardiology, providence hospital is the first private
          hospital in Alappuzha to offer 24 X 7 cardiac care facilities. The
          hospital is equipped with modern machines for conducting
          echocardiogram, treadmill tests and holter study. The intensive care
          unit is competent to take care of critically ill patients. The cardiac
          care lab is a work in progress and should be fully functional by
          December 2018.
        </p>

        <p>
          With that, the hospital will be among an elite group of hospitals in
          Kerala offering facilities for urgent angioplasty, pacemaker
          implantation, peripheral arterial procedures and device closures.
        </p>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Interventional Cardiology Doctors",
      queryName: "Interventional Cardiology",
      teamName: "Interventional Cardiology team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate2.scss";
</style>
