<template>
  <Carousel :carouselItems="caroselItems" />
  <WelcomeArea />
  <Appointment />
  <InsuranceList />
  <OurFacilities />
  <Departments />
  <WhatWeDoBest />
  <!-- <Testimonials /> -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { poyanilKozhencherryCarouselItems } from '@/static/data';

import Carousel from '@/components/Carousels/Type1.vue';
import WelcomeArea from '@/components/HospitalsHomePage/Hospital2/Welcome.vue';
import Appointment from '@/components/HospitalsHomePage/Hospital2/Appointment.vue';
import InsuranceList from '@/components/HospitalsHomePage/Hospital2/InsuranceList.vue';
import OurFacilities from '@/components/HospitalsHomePage/Hospital2/OurFacilities.vue';
import Departments from '@/components/HospitalsHomePage/Hospital2/Department.vue';
import WhatWeDoBest from '@/components/HospitalsHomePage/Hospital2/WhatWeDoBest.vue';
import Testimonials from '@/components/HospitalsHomePage/Hospital2/Testimonials.vue';

export default defineComponent({
  name: 'Template 2',
  components: {
    Carousel,
    WelcomeArea,
    Appointment,
    InsuranceList,
    OurFacilities,
    Departments,
    WhatWeDoBest,
    Testimonials,
  },
  data() {
    return {
      caroselItems: poyanilKozhencherryCarouselItems,
    };
  },
});
</script>
