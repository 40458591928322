
import { defineComponent } from "vue";

import Template from "@/components/Templates/Punalur.vue";
import Facilities from "@/components/Gallery/Template3/index.vue";
import { GALLERY_ROUTE_NAMES } from "@/ts/enum";

export default defineComponent({
  name: GALLERY_ROUTE_NAMES.GALLERY_POYANIL_PUNALUR,
  components: {
    Template,
    Facilities,
  },
});
