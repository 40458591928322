
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';
import { validateEmail } from '@/ts/functions';
import { defineComponent } from 'vue';

export interface ContactSchemaInterface {
  name: string | null;
  email: string | null;
  contactNumber: number | null;
  subject: string | null;
  message: string | null;
  to: string;
  hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES;
}

export interface ContactDataInterface {
  name: string | null;
  email: string | null;
  contactNumber: number | null;
  subject: string | null;
  message: string | null;
  mapIMG: string;
  error: null | string;
  sent: boolean;
  sending: boolean;
}

export default defineComponent({
  data(): ContactDataInterface {
    return {
      mapIMG: require('@/assets/img/map2.jpg'),
      name: null,
      email: null,
      contactNumber: null,
      subject: null,
      message: null,
      error: null,
      sent: false,
      sending: false,
    };
  },

  methods: {
    openMap() {
      window.open(
        `https://www.google.com/maps/place/Poyanil+Hospital/@9.3367747,76.705867,17z/data=!4m12!1m6!3m5!1s0x3b063db52f498b33:0x13367febf4164614!2sPoyanil+Hospital!8m2!3d9.3367747!4d76.7080611!3m4!1s0x3b063db52f498b33:0x13367febf4164614!8m2!3d9.3367747!4d76.7080611`,
        '_blank'
      );
    },
    handleEmail() {
      if (typeof window !== 'undefined') {
        window.open('email:poyanilhospitals@gmail.com');
      }
    },

    missingInputHandler(errMessage: string, ref: HTMLElement): void {
      this.error = errMessage;

      setTimeout(() => {
        this.error = null;
        ref.focus();
        ref.className = ref.className + ' is-invalid';
      }, 1000);

      setTimeout(() => {
        ref.className = ref.className.replace('is-invalid', '');
      }, 2000);
    },

    async sendContactUsForm() {
      if (!this.name && this.$refs.name instanceof HTMLInputElement) {
        this.missingInputHandler('Enter your name', this.$refs.name);
        return;
      }

      if (!this.email && this.$refs.email instanceof HTMLInputElement) {
        this.missingInputHandler('Enter your email-address', this.$refs.email);
        return;
      }

      if (
        this.email &&
        !validateEmail(this.email) &&
        this.$refs.email instanceof HTMLInputElement
      ) {
        this.missingInputHandler('Enter valid email-address', this.$refs.email);
        return;
      }

      if (
        !this.contactNumber &&
        this.$refs.contactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your contact number',
          this.$refs.contactNumber
        );
        return;
      }

      if (
        this.contactNumber &&
        this.contactNumber.toString().length !== 10 &&
        this.$refs.contactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter valid contact number',
          this.$refs.contactNumber
        );
        return;
      }

      if (!this.subject && this.$refs.subject instanceof HTMLInputElement) {
        this.missingInputHandler('Enter the subject', this.$refs.subject);
        return;
      }

      // if (!this.message && this.$refs.message instanceof HTMLInputElement) {
      //   this.missingInputHandler("Enter message", this.$refs.message);
      //   return;
      // }

      if (!process.env.VUE_APP_EMAIL_TWO) {
        return;
      }

      const postDataModel: ContactSchemaInterface = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
        to: process.env.VUE_APP_EMAIL_TWO,
        contactNumber: this.contactNumber,
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
      };

      try {
        this.sending = true;
        await this.axios.post(
          `${process.env.VUE_APP_BASE_URI}/api/v1/service/contact_us`,
          postDataModel
        );
        this.sending = false;
        this.sent = true;
      } catch (e) {
        console.clear();
        this.sending = false;
      }
    },
  },
});
