export enum LANDING_ROUTE_NAME {
  NAME = 'Poyanil',
}

export enum GRAPHQL_QUERYING_HOSPITAL_NAMES {
  PROVIDENCE_HOSPITAL_ALAPPUZHA = 'Providence Hospital Alappuzha',
  POYANIL_HOSPITAL_KOZHENCHERRY = 'Poyanil Hospital Kozhencherry',
  POYANIL_HOSPITAL_PUNALUR = 'Poyanil Hospital Punalur',
}

export enum MAIN_ROUTING_NAMES {
  POYANIL_HOSPITAL = 'Providence Hospital | Alappuzha',
  POYANIL_HOSPITAL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry',
  POYANIL_HOSPITAL_PUNALUR = 'Poyanil Hospital | Punalur',
}

export enum DOCTOR_DETAIL_PAGE_NAMES {
  POYANIL_HOSPITAL = 'Providence Hospital | Alappuzha | Doctors | ',
  POYANIL_HOSPITAL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Doctors | ',
  POYANIL_HOSPITAL_PUNALUR = 'Poyanil Hospital | Punalur | Doctors | ',
}

export enum NEWS_DETAIL_PAGE_NAMES {
  POYANIL_HOSPITAL = 'Providence Hospital | Alappuzha | News | ',
  POYANIL_HOSPITAL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | News | ',
  POYANIL_HOSPITAL_PUNALUR = 'Poyanil Hospital | Punalur | News | ',
}

export enum TESTIMONIAL_DETAIL_PAGE_NAMES {
  POYANIL_HOSPITAL = 'Providence Hospital | Alappuzha | Testimonial | ',
  POYANIL_HOSPITAL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Testimonial | ',
  POYANIL_HOSPITAL_PUNALUR = 'Poyanil Hospital | Punalur | Testimonial | ',
}

export enum CAREER_DETAIL_PAGE_NAMES {
  POYANIL_HOSPITAL = 'Providence Hospital | Alappuzha | Careers | ',
  POYANIL_HOSPITAL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Careers | ',
  POYANIL_HOSPITAL_PUNALUR = 'Poyanil Hospital | Punalur | Careers | ',
}

export enum ABOUT_US_ROUTE_NAMES {
  ABOUT_US_POYANIL = 'Providence Hospital | Alappuzha | About Us',
  ABOUT_US_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | About Us',
  ABOUT_US_PUNALUR = 'Poyanil Hospital | Punalur | About Us',
}

export enum DOCTORS_ROUTE_NAMES {
  DOCTORS_POYANIL = 'Providence Hospital | Alappuzha | Doctors',
  DOCTORS_POYANIL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Doctors',
  DOCTORS_POYANIL_PUNALUR = 'Poyanil Hospital | Punalur | Doctors',
}

export enum GALLERY_ROUTE_NAMES {
  GALLERY_POYANIL = 'Providence Hospital | Alappuzha | Gallery',
  GALLERY_POYANIL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Gallery',
  GALLERY_POYANIL_PUNALUR = 'Poyanil Hospital | Punalur | Gallery',
}

export enum NEWS_ROUTE_NAMES {
  NEWS_POYANIL = 'Providence Hospital | Alappuzha | News',
  NEWS_POYANIL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | News',
  NEWS_POYANIL_PUNALUR = 'Poyanil Hospital | Punalur | News',
}

export enum DEPARTMENT_PARENT_ROUTE_NAMES {
  DEPARTMENT_POYANIL = 'Providence Hospital | Alappuzha | Department',
  DEPARTMENT_POYANIL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Department',
  DEPARTMENT_POYANIL_PUNALUR = 'Poyanil Hospital | Punalur | Department',
}

export enum FACILITIES_PARENT_ROUTE_NAME {
  FACILITIES_POYANIL = 'Providence Hospital | Alappuzha | Facilities',
  FACILITIES_POYANIL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Facilities',
  FACILITIES_POYANIL_PUNALUR = 'Poyanil Hospital | Punalur | Facilities',
}

export enum CAREERS_ROUTE_NAME {
  CAREERS_POYANIL = 'Providence Hospital | Alappuzha | Careers',
  CAREERS_POYANIL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Careers',
  CAREERS_POYANIL_PUNALUR = 'Poyanil Hospital | Punalur | Careers',
}

export enum TESTIMONIALS_ROUTE_NAME {
  TESTIMONIALS_POYANIL = 'Providence Hospital | Alappuzha | Testimonials',
  TESTIMONIALS_POYANIL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Testimonials',
  TESTIMONIALS_POYANIL_PUNALUR = 'Poyanil Hospital | Punalur | Testimonials',
}

export enum CONTACT_US_ROUTE_NAME {
  CONTACT_US_POYANIL = 'Providence Hospital | Alappuzha | Contact Us',
  CONTACT_US_POYANIL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Contact Us',
  CONTACT_US_POYANIL_PUNALUR = 'Poyanil Hospital | Punalur | Contact Us',
}

export enum APPOINTMENT_ROUTE_NAME {
  APPOINTMENT_POYANIL = 'Providence Hospital | Alappuzha | Appointment',
  APPOINTMENT_POYANIL_KOZHENCHERRY = 'Poyanil Hospital | Kozhencherry | Appointment',
  APPOINTMENT_POYANIL_PUNALUR = 'Poyanil Hospital | Punalur | Appointment',
}

export enum ROUTING_PATHS {
  HOME = '/',
  // Home pages of three hospitals.
  TO_POYANIL_HOME = '/poyanil_hospital',
  TO_POYANIL_KOZHENCHERRY_HOME = '/poyanil_hospital_kozhencherry',
  TO_POYANIL_PUNALUR_HOME = '/poyanil_hospital_punalur',

  // About Us pages
  TO_POYANIL_ABOUT_US = '/poyanil_hospital/about_us',
  TO_POYANIL_KOZHENCHERRY_ABOUT_US = '/poyanil_hospital_kozhencherry/about_us',
  TO_POYANIL_PUNALUR_ABOUT_US = '/poyanil_hospital_punalur/about_us',

  // Doctors Pages
  TO_POYANIL_DOCTORS = '/poyanil_hospital/doctors',
  TO_POYANIL_KOZHENCHERRY_DOCTORS = '/poyanil_hospital_kozhencherry/doctors',
  TO_POYANIL_PUNALUR_DOCTORS = '/poyanil_hospital_punalur/doctors',

  // Departments Pages
  TO_POYANIL_DEPARTMENT = '/poyanil_hospital/department',
  TO_POYANIL_KOZHENCHERRY_DEPARTMENT = '/poyanil_hospital_kozhencherry/department',
  TO_POYANIL_PUNALUR_DEPARTMENT = '/poyanil_hospital_punalur/department',

  // Facilities Pages
  TO_POYANIL_FACILITIES = '/poyanil_hospital/facilities',
  TO_POYANIL_KOZHENCHERRY__FACILITIES = '/poyanil_hospital_kozhencherry/facilities',
  TO_POYANIL_PUNALUR_FACILITIES = '/poyanil_hospital_punalur/facilities',

  // Gallery Pages
  TO_POYANIL_GALLERY = '/poyanil_hospital/gallery',
  TO_POYANIL_KOZHENCHERRY_GALLERY = '/poyanil_hospital_kozhencherry/gallery',
  TO_POYANIL_PUNALUR_GALLERY = '/poyanil_hospital_punalur/gallery',

  // News Pages
  TO_POYANIL_NEWS = '/poyanil_hospital/news',
  TO_POYANIL_KOZHENCHERRY_NEWS = '/poyanil_hospital_kozhencherry/news',
  TO_POYANIL_PUNALUR_NEWS = '/poyanil_hospital_punalur/news',

  // Careers Pages
  TO_POYANIL_CAREERS = '/poyanil_hospital/careers',
  TO_POYANIL_KOZHENCHERRY_CAREERS = '/poyanil_hospital_kozhencherry/careers',
  TO_POYANIL_PUNALUR_CAREERS = '/poyanil_hospital_punalur/careers',

  // Testimonial Pages
  TO_POYANIL_TESTIMONIALS = '/poyanil_hospital/testimonials',
  TO_POYANIL_KOZHENCHERRY_TESTIMONIALS = '/poyanil_hospital_kozhencherry/testimonials',
  TO_POYANIL_PUNALUR_TESTIMONIALS = '/poyanil_hospital_punalur/testimonials',

  // Testimonial Pages
  TO_POYANIL_CONTACT_US = '/poyanil_hospital/contact_us',
  TO_POYANIL_KOZHENCHERRY_CONTACT_US = '/poyanil_hospital_kozhencherry/contact_us',
  TO_POYANIL_PUNALUR_CONTACT_US = '/poyanil_hospital_punalur/contact_us',

  // Appointment
  TO_POYANIL_APPOINTMENT = '/poyanil_hospital/appointment',
  TO_POYANIL_KOZHENCHERRY_APPOINTMENT = '/poyanil_hospital_kozhencherry/appointment',
  TO_POYANIL_PUNALUR_APPOINTMENT = '/poyanil_hospital_punalur/appointment',

  // Doctors Detail Pages
  TO_POYANIL_DOCTORS_DETAIL = '/poyanil_hospital/doctors/details/:doctorId',
  TO_POYANIL_KOZHENCHERRY_DOCTORS_DETAIL = '/poyanil_hospital_kozhencherry/doctors/details/:doctorId',
  TO_POYANIL_PUNALUR_DOCTORS_DETAIL = '/poyanil_hospital_punalur/doctors/details/:doctorId',

  // News Detail Pages
  TO_POYANIL_NEWS_DETAIL = '/poyanil_hospital/news/detail/:newsId',
  TO_POYANIL_KOZHENCHERRY_NEWS_DETAIL = '/poyanil_hospital_kozhencherry/news/detail/:newsId',
  TO_POYANIL_PUNALUR_NEWS_DETAIL = '/poyanil_hospital_punalur/news/detail/:newsId',

  // Career Detail Pages
  TO_POYANIL_CAREER_DETAIL = '/poyanil_hospital/careers/detail/:careerId',
  TO_POYANIL_KOZHENCHERRY_CAREER_DETAIL = '/poyanil_hospital_kozhencherry/careers/detail/:careerId',
  TO_POYANIL_PUNALUR_CAREER_DETAIL = '/poyanil_hospital_punalur/careers/detail/:careerId',

  // Testimonial Detail Pages
  TO_POYANIL_TESTIMONIALS_DETAIL = '/poyanil_hospital/testimonials/detail/:id',
  TO_POYANIL_KOZHENCHERRY_TESTIMONIALS_DETAIL = '/poyanil_hospital_kozhencherry/testimonials/detail/:id',
  TO_POYANIL_PUNALUR_TESTIMONIALS_DETAIL = '/poyanil_hospital_punalur/testimonials/detail/:id',
}

// TO_POYANIL_ = ""
// TO_POYANIL_KOZHENCHERRY_
// TO_POYANIL_PUNALUR_

export enum DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES {
  ANESTHESIOLOGY = 'Providence Hospital | Alappuzha | Department | Anesthesiology',
  DIABETOLOGY = 'Providence Hospital | Alappuzha | Department | Diabetology ',
  EMERGENCY_MEDICINE = 'Providence Hospital | Alappuzha | Department | Emergency Medicine',
  GENERAL_MEDICINE = 'Providence Hospital | Alappuzha | Department | General Medicine',
  GENERAL_SURGERY = 'Providence Hospital | Alappuzha | Department | General Surgery',
  INTERVENTIONAL_CARDIOLOGY = 'Providence Hospital | Alappuzha | Department | Interventional Cardiology',
  NEPHROLOGY = 'Providence Hospital | Alappuzha | Department | Nephrology',
  NEUROMEDICINE = 'Providence Hospital | Alappuzha | Department | Neuromedicine',
  NEUROSURGERY = 'Providence Hospital | Alappuzha | Department | Neurosurgery',
  OBSTETRICSANDGYNAECOLOGY = 'Providence Hospital | Alappuzha | Department | Obstetricsandgynaecology',
  ORTHOPAEDICS = 'Providence Hospital | Alappuzha | Department | Orthopaedics',
  PEDIATRICS = 'Providence Hospital | Alappuzha | Department | Pediatrics',
  PULMONOLOGY = 'Providence Hospital | Alappuzha | Department | Pulmonology',
  RADIOLOGY = 'Providence Hospital | Alappuzha | Department | Radiology',
  UROLOGY = 'Providence Hospital | Alappuzha | Department | Urology',
  ORHTHALMOLOGY = 'Providence Hospital | Alappuzha | Department | Ophthalmology',
  MEDICAL_GASTROENTEROLOGY = 'Poyanil Alappuzha | Punalur | Department | Medical Gastroenterology',
  DERMATOLOGY = 'Providence Hospital | Alappuzha | Department | Dermatology',
  ENT = 'Providence Hospital | Alappuzha | Department | EAR,NOSE,AND THROAT',
  CARDIOLOGY = 'Providence Hospital | Alappuzha | Department | Cardiology',
  PSYCHIATRY = 'Providence Hospital | Alappuzha | Department | Psychiatry',
  DENTAL = 'Providence Hospital | Alappuzha | Department | Dental',
  NEUROLOGY = 'Providence Hospital | Alappuzha | Department | Neurology',
  AYURVEDAM = 'Providence Hospital | Alappuzha | Department | Ayurvedam',
  FERTILITY_CENTER = 'Providence Hospital | Alappuzha | Department | Fertility Center',
}

export enum DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES {
  ANESTHESIOLOGY = 'Poyanil Hospital | Kozhencherry | Department | Anesthesiology',
  DIABETOLOGY = 'Poyanil Hospital | Kozhencherry | Department | Diabetology ',
  EMERGENCY_MEDICINE = 'Poyanil Hospital | Kozhencherry | Department | Emergency Medicine',
  GENERAL_MEDICINE = 'Poyanil Hospital | Kozhencherry | Department | General Medicine',
  GENERAL_SURGERY = 'Poyanil Hospital | Kozhencherry | Department | General Surgery',
  INTERVENTIONAL_CARDIOLOGY = 'Poyanil Hospital | Kozhencherry | Department | Interventional Cardiology',
  NEPHROLOGY = 'Poyanil Hospital | Kozhencherry | Department | Nephrology',
  NEUROMEDICINE = 'Poyanil Hospital | Kozhencherry | Department | Neuromedicine',
  NEUROSURGERY = 'Poyanil Hospital | Kozhencherry | Department | Neurosurgery',
  OBSTETRICSANDGYNAECOLOGY = 'Poyanil Hospital | Kozhencherry | Department | Obstetricsandgynaecology',
  ORTHOPAEDICS = 'Poyanil Hospital | Kozhencherry | Department | Orthopaedics',
  PEDIATRICS = 'Poyanil Hospital | Kozhencherry | Department | Pediatrics',
  PULMONOLOGY = 'Poyanil Hospital | Kozhencherry | Department | Pulmonology',
  RADIOLOGY = 'Poyanil Hospital | Kozhencherry | Department | Radiology',
  UROLOGY = 'Poyanil Hospital | Kozhencherry | Department | Urology',
  OPHTHALMOLOGY = 'Poyanil Hospital | Kozhencherry | Department | Ophthalmology',
  MEDICAL_GASTROENTEROLOGY = 'Poyanil Hospital | Kozhencherry | Department | Medical Gastroenterology',
  DERMATOLOGY = 'Poyanil Hospital | Kozhencherry | Department | Dermatology',
  ENT = 'Poyanil Hospital | Kozhencherry | Department | EAR,NOSE,AND THROAT',
  CARDIOLOGY = 'Poyanil Hospital | Kozhencherry | Department | Cardiology',
  PSYCHIATRY = 'Poyanil Hospital | Kozhencherry | Department | Psychiatry',
  DENTAL = 'Poyanil Hospital | Kozhencherry | Department | Dental',
  NEUROLOGY = 'Poyanil Hospital | Kozhencherry | Department | Neurology',
  AYURVEDAM = 'Poyanil Hospital | Kozhencherry | Department | Ayurvedam',
  FERTILITY_CENTER = 'Poyanil Hospital | Kozhencherry | Department | Fertility Center',
}

export enum DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES {
  ANESTHESIOLOGY = 'Poyanil Hospital | Punalur | Department | Anesthesiology',
  DIABETOLOGY = 'Poyanil Hospital | Punalur | Department | Diabetology ',
  EMERGENCY_MEDICINE = 'Poyanil Hospital | Punalur | Department | Emergency Medicine',
  GENERAL_MEDICINE = 'Poyanil Hospital | Punalur | Department | General Medicine',
  GENERAL_SURGERY = 'Poyanil Hospital | Punalur | Department | General Surgery',
  INTERVENTIONAL_CARDIOLOGY = 'Poyanil Hospital | Punalur | Department | Interventional Cardiology',
  NEPHROLOGY = 'Poyanil Hospital | Punalur | Department | Nephrology',
  NEUROMEDICINE = 'Poyanil Hospital | Punalur | Department | Neuromedicine',
  NEUROSURGERY = 'Poyanil Hospital | Punalur | Department | Neurosurgery',
  OBSTETRICSANDGYNAECOLOGY = 'Poyanil Hospital | Punalur | Department | Obstetricsandgynaecology',
  ORTHOPAEDICS = 'Poyanil Hospital | Punalur | Department | Orthopaedics',
  PEDIATRICS = 'Poyanil Hospital | Punalur | Department | Pediatrics',
  PULMONOLOGY = 'Poyanil Hospital | Punalur | Department | Pulmonology',
  RADIOLOGY = 'Poyanil Hospital | Punalur | Department | Radiology',
  UROLOGY = 'Poyanil Hospital | Punalur | Department | Urology',
  ORHTHALMOLOGY = 'Poyanil Hospital | Punalur | Department | Ophthalmology',
  MEDICAL_GASTROENTEROLOGY = 'Poyanil Hospital | Punalur | Department | Medical Gastroenterology',
  DERMATOLOGY = 'Poyanil Hospital | Punalur | Department | Dermatology',
  ENT = 'Poyanil Hospital | Punalur | Department | EAR,NOSE,AND THROAT',
  CARDIOLOGY = 'Poyanil Hospital | Punalur | Department | Cardiology',
  PSYCHIATRY = 'Poyanil Hospital | Punalur | Department | Psychiatry',
  DENTAL = 'Poyanil Hospital | Punalur | Department | Dental',
  NEUROLOGY = 'Poyanil Hospital | Punalur | Department | Neurology',
  AYURVEDAM = 'Poyanil Hospital | Punalur | Department | Ayurvedam',
  FERTILITY_CENTER = 'Poyanil Hospital | Punalur | Department | Fertility Center',
}
//
export enum DEPARTMENT_CHILDREN_ROUTING_PATHS {
  TO_ANESTHESIOLOGY = 'anesthesiology',
  TO_DIABETOLOGY = 'diabetology',
  TO_EMERGENCY_MEDICINE = 'emergency_medicine',
  TO_GENERAL_MEDICINE = 'general_medicine',
  TO_GENERAL_SURGERY = 'general_surgery',
  TO_INTERVENTIONAL_CARDIOLOGY = 'interventional_cardiology',
  TO_NEPHROLOGY = 'nephrology',
  TO_NEUROMEDICINE = 'neuromedicine',
  TO_NEUROSURGERY = 'neurosurgery',
  TO_OBSTETRICSANDGYNAECOLOGY = 'obstetrics_and_gynaecology',
  TO_ORTHOPAEDICS = 'orthopaedics',
  TO_PEDIATRICS = 'pediatrics',
  TO_PULMONOLOGY = 'pulmonology',
  TO_RADIOLOGY = 'radiology',
  TO_UROLOGY = 'urology',
  TO_OPHTHALMOLOGY = 'ophthalmology',
  TO_MEDICAL_GASTROENTEROLOGY = 'medical_gastroenterology',
  TO_DERMATOLOGY = 'dermatology',
  TO_ENT = 'e_n_t',
  TO_CARDIOLOGY = 'cardiology',
  TO_PSYCHIATRY = 'psychiatry',
  TO_DENTAL = 'dental',
  TO_NEUROLOGY = 'neurology',
  TO_AYURVEDAM = 'ayurvedam',
  TO_FERTILITY_CENTER = 'fertility_center',
}

// Facilities

export enum FACILITIES_CHILDREN_ROUTING_PATHS {
  CATHLAB = 'cathlab_24_x_7',
  CLINIC_24_x_7 = '24_hour_clinic',
  PHARMACY_24_x_7 = '24_hour_pharmacy',
  PARAMEDICAL_SUPPORT = '24_x_7_doctors,_nurses_&_paramedical_support',
  POWER_BACKUP = '24_x_7_power_back_up',
  CLINICAL_LABORATORY = 'clinical_laboratory',
  CLINICAL_PHYSIOTHERAPY_STARTING_SOON = 'clinical_physiotherapy-starting_soon',
  CSSD = 'CSSD',
  DIALYSIS = 'dialysis_24_x_7',
  EMERGENCY_OR_CASUALITY = 'emergency_/_casuality',
  ICU = 'ICU',
  IN_PATIENT_DEPARTMENT = 'in_patient_department',
  OPERATION_THEATERS = 'operation_theaters',
  OUT_PATIENT_DEPARTMENT = 'out_patient_department',
  PREVENTIVE_HEALTH_CHECKUP = 'preventive_health_checkup',
  RADIOLOGY_IMAGING = 'radiology_/_imaging',
  PHYSIOTHERAPY = 'physiotherapy',
  BLOOD_BANK = 'blood_bank',
}

export enum FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES {
  CATHLAB = 'Providence Hospital | Alappuzha | Facilities | Cathlab 24 X 7',
  CLINIC_24_x_7 = 'Providence Hospital | Alappuzha | Facilities | 24 Hour Clinic',
  PHARMACY_24_x_7 = 'Providence Hospital | Alappuzha | Facilities | 24 Hour Pharmacy',
  PARAMEDICAL_SUPPORT = 'Providence Hospital | Alappuzha | Facilities | 24 x 7 Doctors, Nurses & Paramedical Support',
  POWER_BACKUP = 'Providence Hospital | Alappuzha | Facilities | 24 x 7 Power Back Up',
  CLINICAL_LABORATORY = 'Providence Hospital | Alappuzha | Facilities | Clinical Laboratory',
  CLINICAL_PHYSIOTHERAPY_STARTING_SOON = 'Providence Hospital | Alappuzha | Facilities | Clinical Physiotherapy',
  CSSD = 'Providence Hospital | Alappuzha | Facilities | CSSD',
  DIALYSIS = 'Providence Hospital | Alappuzha | Facilities | Dialysis 24 x 7',
  EMERGENCY_OR_CASUALITY = 'Providence Hospital | Alappuzha | Facilities | Emergency / Casuality',
  ICU = 'Providence Hospital | Alappuzha | Facilities | ICU',
  IN_PATIENT_DEPARTMENT = 'Providence Hospital | Alappuzha | Facilities | In Patient Department',
  OPERATION_THEATERS = 'Providence Hospital | Alappuzha | Facilities | Operation Theaters',
  OUT_PATIENT_DEPARTMENT = 'Providence Hospital | Alappuzha | Facilities | Out Patient Department',
  PREVENTIVE_HEALTH_CHECKUP = 'Providence Hospital | Alappuzha | Facilities | Preventive Health Checkup',
  RADIOLOGY_IMAGING = 'Providence Hospital | Alappuzha | Facilities | Radiology / Imaging',
  PHYSIOTHERAPY = 'Providence Hospital | Alappuzha | Facilities | Physiotherapy',
  BLOOD_BANK = 'Providence Hospital | Alappuzha | Facilities | Blood Bank',
}

export enum FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES {
  CATHLAB = 'Poyanil Hospital | Kozhencherry | Facilities | Cathlab 24 X 7',
  CLINIC_24_x_7 = 'Poyanil Hospital | Kozhencherry | Facilities |  24 Hour Clinic',
  PHARMACY_24_x_7 = 'Poyanil Hospital | Kozhencherry | Facilities | 24 Hour Pharmacy',
  PARAMEDICAL_SUPPORT = 'Poyanil Hospital | Kozhencherry | Facilities | 24 x 7 Doctors, Nurses & Paramedical Support',
  POWER_BACKUP = 'Poyanil Hospital | Kozhencherry | Facilities | 24 x 7 Power Back Up',
  CLINICAL_LABORATORY = 'Poyanil Hospital | Kozhencherry | Facilities | Clinical Laboratory',
  CLINICAL_PHYSIOTHERAPY_STARTING_SOON = 'Poyanil Hospital | Kozhencherry | Facilities | Clinical Physiotherapy',
  CSSD = 'Poyanil Hospital | Kozhencherry | Facilities | CSSD',
  DIALYSIS = 'Poyanil Hospital | Kozhencherry | Facilities | Dialysis 24 x 7',
  EMERGENCY_OR_CASUALITY = 'Poyanil Hospital | Kozhencherry | Facilities | Emergency / Casuality',
  ICU = 'Poyanil Hospital | Kozhencherry | Facilities | ICU',
  IN_PATIENT_DEPARTMENT = 'Poyanil Hospital | Kozhencherry | Facilities | In Patient Department',
  OPERATION_THEATERS = 'Poyanil Hospital | Kozhencherry | Facilities | Operation Theaters',
  OUT_PATIENT_DEPARTMENT = 'Poyanil Hospital | Kozhencherry | Facilities | Out Patient Department',
  PREVENTIVE_HEALTH_CHECKUP = 'Poyanil Hospital | Kozhencherry | Facilities | Preventive Health Checkup',
  RADIOLOGY_IMAGING = 'Poyanil Hospital | Kozhencherry | Facilities | Radiology / Imaging',
  PHYSIOTHERAPY = 'Poyanil Hospital | Kozhencherry | Facilities | Physiotherapy',
  BLOOD_BANK = 'Poyanil Hospital | Kozhencherry | Facilities | Blood Bank',
}

export enum FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES {
  CATHLAB = 'Poyanil Hospital | Punalur | Facilities | Cathlab 24 X 7',
  CLINIC_24_x_7 = 'Poyanil Hospital | Punalur | Facilities |  24 Hour Clinic',
  PHARMACY_24_x_7 = 'Poyanil Hospital | Punalur | Facilities | 24 Hour Pharmacy',
  PARAMEDICAL_SUPPORT = 'Poyanil Hospital | Punalur | Facilities | 24 x 7 Doctors, Nurses & Paramedical Support',
  POWER_BACKUP = 'Poyanil Hospital | Punalur | Facilities | 24 x 7 Power Back Up',
  CLINICAL_LABORATORY = 'Poyanil Hospital | Punalur | Facilities | Clinical Laboratory',
  CLINICAL_PHYSIOTHERAPY_STARTING_SOON = 'Poyanil Hospital | Punalur | Facilities | Clinical Physiotherapy',
  CSSD = 'Poyanil Hospital | Punalur | Facilities | CSSD',
  DIALYSIS = 'Poyanil Hospital | Punalur | Facilities | Dialysis 24 x 7',
  EMERGENCY_OR_CASUALITY = 'Poyanil Hospital | Punalur | Facilities | Emergency / Casuality',
  ICU = 'Poyanil Hospital | Punalur | Facilities | ICU',
  IN_PATIENT_DEPARTMENT = 'Poyanil Hospital | Punalur | Facilities | In Patient Department',
  OPERATION_THEATERS = 'Poyanil Hospital | Punalur | Facilities | Operation Theaters',
  OUT_PATIENT_DEPARTMENT = 'Poyanil Hospital | Punalur | Facilities | Out Patient Department',
  PREVENTIVE_HEALTH_CHECKUP = 'Poyanil Hospital | Punalur | Facilities | Preventive Health Checkup',
  RADIOLOGY_IMAGING = 'Poyanil Hospital | Punalur | Facilities | Radiology / Imaging',
  PHYSIOTHERAPY = 'Poyanil Hospital | Punalur | Facilities | Physiotherapy',
  BLOOD_BANK = 'Poyanil Hospital | Punalur | Facilities | Blood Bank',
}
