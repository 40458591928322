
/**
 * @CommonHeader accross all three websites
 * @prop {headerTitle,sinceYear,imgSub?,logoIco}
 * @method = goToHome():redirect to landing site
 */

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Header',
  props: ['headerTitle', 'sinceYear', 'imgSub', 'logoIco'],
  methods: {
    goToHome(): void {
      this.$router.push('/');
    },
  },
});
