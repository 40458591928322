<template>
  <div class="container" id="facilities">
    <div class="section-title">
      <h2>Our Facilities</h2>
      <p>
        Our state of the art facilties will ensure that you can avail the best
        care and service possible in (Allapuzha, Kozhencherry or Punelur).
        <br />To avail our facilities please contact or visit us.
      </p>
    </div>

    <div class="row">
      <div
        class="col-lg-4 col-sm-6 mb-5"
        v-for="({ title, icon, description, link }, i) in ourFacilities"
        :key="i"
        style="display:grid;"
      >
        <div class="facilities-box">
          <img :src="icon" class="img-fluid" />
          <h3>{{ title }}</h3>
          <p>
            {{ description }}
          </p>
          <router-link :to="$route.meta.toHome + '/facilities/' + link">
            <div class="more-btn"><i class="fa fa-arrow-right"></i></div
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { OurFacilitiesDataInterface } from "@/ts/interfaces";
import { ourFacilities } from "@/static/data";

export default defineComponent({
  data(): OurFacilitiesDataInterface {
    return {
      ourFacilities,
    };
  },
});
</script>

<style scoped>
h3 {
  font-size: 20px;
  font-family: "ProximaNovaBold";
}

.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
}
.section-title:after,
.section-title:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 50%;
  left: 10px;
  top: 0;
  background: #de0f17;
  transition: 0.5s;
}

.section-title:after {
  left: 0;
  height: 100%;
}

#facilities {
  margin-top: 100px;
}

.facilities-box {
  box-shadow: 0 8px 16px 0 rgba(146, 184, 255, 0.2);
  padding: 30px 20px;
}
.facilities-box img {
  margin-bottom: 25px;
}
.facilities-box p {
  margin-top: 20px;
  padding-bottom: 20px;
}

.facilities-box .more-btn {
  position: absolute;
  right: 15px;
  bottom: 0;
  width: 45px;
  height: 40px;
  line-height: 44px;
  text-align: center;
  box-shadow: 0 0 25px 0 rgba(0, 27, 103, 0.2);
  border-radius: 50% 0 0 0;
}
.facilities-box .more-btn:hover {
  background: #de0f17;
  color: #fff;
}
</style>
