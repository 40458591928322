<template>
  <Template>
    <Departments>
      <router-view></router-view>
    </Departments>
  </Template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Template from "@/components/Templates/Punalur.vue";
import Departments from "@/components/Departments/Template3/index.vue";
import { DEPARTMENT_PARENT_ROUTE_NAMES } from "@/ts/enum";

export default defineComponent({
  name: DEPARTMENT_PARENT_ROUTE_NAMES.DEPARTMENT_POYANIL_PUNALUR,
  components: {
    Template,
    Departments,
  },
});
</script>
