<template>
  <Template>
    <AboutUs />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ABOUT_US_ROUTE_NAMES } from "@/ts/enum";

import Template from "@/components/Templates/Punalur.vue";
import AboutUs from "@/components/AboutUs/Template3/index.vue";

export default defineComponent({
  name: ABOUT_US_ROUTE_NAMES.ABOUT_US_PUNALUR,
  components: {
    AboutUs,
    Template,
  },
});
</script>
