
import { defineComponent } from "vue";

import { DepartmentDataInterface } from "@/ts/interfaces";
import { departments } from "@/static/data";

export default defineComponent({
  data(): DepartmentDataInterface {
    return {
      departments,
    };
  },

  methods: {
    goToDepartments(): void {
      this.$router.push(this.$route.meta.toHome + "/department");
    },
  },
});
