<template>
  <div
    class="about-your-health mt-5"
    id="about-your-health"
    :style="{ 'background-image': 'url(' + healthCareBGI + ')' }"
  >
    <div class="container mt-5 mb-5">
      <div class="section-title">
        <h2 class="text-white">We Care About Your Health</h2>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <p>
            In the vicinity of Venice of the East, Alappuzha, Providence Super
            Speciality Hospital is the latest addition to the Poyanil Group of
            Hospitals piloted by Dr Joseph George. Located at a view stretch at
            the eastern side of NH66 between Poomkavu and Thumpoly, Providence
            is designed to be the leader in the Super Speciality Healthcare
            scenario of this part of Kerala.
          </p>

          <p>
            Providence Hospital Alappuzha with its committed group of medical
            professionals and state-of-the-art facilities aims at providing
            quality treatment at a reasonable cost. Providence Super Speciality
            Hospital is a 100 bedded multi-specialty hospital providing
            progressive outpatient and inpatient care. Providence has a
            scientifically designed Emergency Block with 24 hrs Casualty
            management facility and Life support Systems
          </p>

          <p>
            Providence is enriched with well provided modern OPs, Hepafilter
            Suites, Operation Theaters with Anesthesia Work Station, 3-Chip HD
            Camera Systems for Surgeries etc. Theaters are exceptionally
            designed and built to carryout investigative, diagnostic,
            therapeutic, palliative invasive procedures of varying degrees.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <h2 class="text-center mb-4">
      We provide primary care, preventive and curative services.
    </h2>

    <p>
      The hospital provides a broad range of services in Anesthesiology,
      Cardiology,Diabetology, Emergency/Casualty, General Medicine, Obstetrics
      and Gynaecology, Orthopedics,Pulmonology, Pediatrics, Neuro Surgery, Pain
      clinic, Spine clinic, Radiology, Urology etc. The Labour Room is equipped
      to deal with normal as well as high-risk pregnancies. The hospital has an
      Endoscopy Room for Gastroenterology, Bronchoscopy & Urology facilities.
    </p>

    <p>
      The hospital offers comprehensive health check-up packages as per
      requirements tailored for patient benefit that include a complete array of
      blood tests, X - ray, Ultrasound, TMT, ECG, Pulmonary function tests,
      Echocardiography etc. We plan to have Neonatology, Neonatal ICU,
      Neurosurgery ICU, Neurology, Cathlab, Dialysis unit, Physiotherapy,
      Movement disorder clinics etc.
    </p>

    <p>
      From diagnosis to treatment, each phase of treatment is bestowed with
      utmost care and dedication.
    </p>
  </div>
</template>

<script lang="ts">
import { WeCareDataInterface } from "@/ts/interfaces";
import { defineComponent } from "vue";
export default defineComponent({
  data(): WeCareDataInterface {
    return {
      healthCareBGI: require("@/assets/img/about-your-health.png"),
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate1.scss";

.about-your-health {
  background-color: #fff;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  color: #fff;
}
.about-your-health::before {
  position: absolute;
  content: "";
  background-color: rgba(26, 94, 195, 0.6);
  top: 0;
  width: 100%;
  height: 4000%;
  left: 0;
  transform: skewY(67deg);
}
</style>
