
import { defineComponent } from 'vue';
import { poyanilKozhencherryCarouselItems } from '@/static/data';

import Carousel from '@/components/Carousels/Type1.vue';
import WelcomeArea from '@/components/HospitalsHomePage/Hospital3/Welcome.vue';
import Appointment from '@/components/HospitalsHomePage/Hospital3/Appointment.vue';
import OurFacilities from '@/components/HospitalsHomePage/Hospital3/OurFacilities.vue';
import Department from '@/components/HospitalsHomePage/Hospital3/Department.vue';
import WhatWeDoBest from '@/components/HospitalsHomePage/Hospital3/WhatWeDoBest.vue';
import Testimonials from '@/components/HospitalsHomePage/Hospital3/Testimonials.vue';

export default defineComponent({
  name: 'Template 2',
  components: {
    Carousel,
    WelcomeArea,
    Appointment,
    OurFacilities,
    Department,
    WhatWeDoBest,
    Testimonials,
  },
  data() {
    return {
      caroselItems: poyanilKozhencherryCarouselItems,
    };
  },
});
