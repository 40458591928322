<template>
  <Loader v-if="loading" />
  <h2
    v-else-if="!careersList.length && !loading"
    class="mt-3"
    style="text-align:center;"
  >
    No Careers found
  </h2>
  <div v-else class="container mt-5">
    <div class="section-title">
      <h2>We found {{ jobCount }} available job(s) for you</h2>
      <p>
        Here are our job postings. Please apply through our website and we will
        get back to you.
        <br />
        For any additional queries kindly contact us.
      </p>
    </div>

    <div
      class="career-list"
      v-for="{ sys: { id }, jobShortDescription, jobTitle } in careersList"
      :key="id"
    >
      <div class="d-flex justify-content-between">
        <div>
          <h3>{{ jobTitle }}</h3>
          <p>
            {{ jobShortDescription }}
          </p>
        </div>
        <div class="career-list-btn-middle">
          <router-link :to="`${$route.meta.toCarrers}/detail/${id}`">
            <button
              type="button"
              class="btn btn-outline-primary career-list-btn"
            >
              View more
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useQuery } from "@/hooks";
import { getCarrersList } from "@/graphql";
import Loader from "@/components/LoadingAnimation.vue";
import { CareerListDataInterface, JobListInterface } from "@/ts/interfaces";
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from "@/ts/enum";

export default defineComponent({
  data(): CareerListDataInterface {
    return {
      loading: true,
      jobCount: 0,
      careersList: [],
    };
  },
  components: { Loader },

  mounted() {
    (async () => {
      const { data, loading, errors } = await useQuery(getCarrersList, {
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.PROVIDENCE_HOSPITAL_ALAPPUZHA,
      });

      this.loading = loading;
      this.jobCount = data.careersCollection.total;
      this.careersList = data.careersCollection.items as JobListInterface[];
    })();
  },
});
</script>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
}
.section-title:after,
.section-title:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 50%;
  left: 10px;
  top: 0;
  background: #de0f17;
  transition: 0.5s;
}

.section-title:after {
  left: 0;
  height: 100%;
}

.career-list {
  border: rgba(112, 112, 112, 0.2) solid 1px;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.career-list-btn-middle {
  display: flex;
  align-items: center;
}
.career-list-btn {
  border-color: #3b3b3b;
  color: #3b3b3b;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 20px;
  line-height: 15px;
}

.career-list-btn:hover {
  background-color: #1a5ec3;
  border-color: #1a5ec3;
  color: #fff;
}
.career-list-btn:focus {
  background-color: #1a5ec3;
  border-color: #1a5ec3;
  color: #fff;
  box-shadow: inherit;
}

.career-list-btn-middle > a > button {
  min-width: 115px;
}
</style>
