
import { defineComponent } from 'vue';

import Template from '@/components/Templates/Kozhencherry.vue';
import ContactUs from '@/components/ContactUs/screen/Template2/index.vue';
import { CONTACT_US_ROUTE_NAME } from '@/ts/enum';

export default defineComponent({
  name: CONTACT_US_ROUTE_NAME.CONTACT_US_POYANIL_KOZHENCHERRY,
  components: {
    ContactUs,
    Template,
  },
});
