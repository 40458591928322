
import { defineComponent } from "vue";

import Template from "@/components/Templates/Kozhencherry.vue";
import Gallery from "@/components/Gallery/Template2/index.vue";
import { GALLERY_ROUTE_NAMES } from "@/ts/enum";

export default defineComponent({
  name: GALLERY_ROUTE_NAMES.GALLERY_POYANIL_KOZHENCHERRY,
  components: {
    Template,
    Gallery,
  },
});
