
import { defineComponent } from "vue";
import { ABOUT_US_ROUTE_NAMES } from "@/ts/enum";

import Template from "@/components/Templates/index.vue";
import AboutUs from "@/components/AboutUs/Template1/index.vue";

export default defineComponent({
  name: ABOUT_US_ROUTE_NAMES.ABOUT_US_POYANIL,
  components: {
    AboutUs,
    Template,
  },
});
