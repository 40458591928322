
import { defineComponent } from "vue";

import Template from "@/components/Templates/Kozhencherry.vue";
import NewsDetails from "@/components/NewsDetails/Template2/index.vue";
import { NEWS_DETAIL_PAGE_NAMES } from "@/ts/enum";

export default defineComponent({
  name: NEWS_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
  components: {
    NewsDetails,
    Template,
  },
});
