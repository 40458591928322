<template>
  <Template>
    <DoctorsDetails />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DOCTOR_DETAIL_PAGE_NAMES } from "@/ts/enum";

import Template from "@/components/Templates/index.vue";
import DoctorsDetails from "@/components/DoctorDetailPages/Template2/index.vue";

export default defineComponent({
  name: DOCTOR_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
  components: {
    DoctorsDetails,
    Template,
  },
});
</script>
