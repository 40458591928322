
import { getTestimonial } from '@/graphql';
import { useQuery } from '@/hooks';
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';
import { defineComponent } from 'vue';
import Loader from '@/components/LoadingAnimation.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default defineComponent({
  components: { Loader, VideoPlayer },
  data(): TestimonailDataInterface {
    return {
      loading: true,
      testimonials: [],
      videoOptions: {
        autoplay: false,
        controls: true,
      },
      options: {
        renderMark: {
          [MARKS.BOLD]: (text: string) => `<custom-bold>${text}<custom-bold>`,
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node: any, next: any) =>
            `<custom-paragraph>${next(node.content)}</custom-paragraph>`,
          [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const { content } = this.newsDetail as any;

            const img = content.links.assets.block.find(
              (entries: any) => entries.sys.id === node.data.target.sys.id
            );

            return `
            <div>
            <img
                style="width:100%"
               src=${img?.url}
               alt='content-img'
             />
            </div>
             `;
          },
        },
      },
    };
  },
  methods: {
    documentToHtmlString,
  },

  mounted() {
    (async () => {
      const { data, loading, errors } = await useQuery(getTestimonial, {
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.PROVIDENCE_HOSPITAL_ALAPPUZHA,
      });

      this.loading = loading;
      this.testimonials = data.testimonialsCollection
        .items as TestimonailInterface[];
    })();
  },
});

interface TestimonailInterface {
  reviewPoster?: {
    url: string;
  };
  shortReview: {
    json: any;
  };
  video?: {
    url: string;
  };
  testimonialContent?: {
    json: any;
  };
}

interface TestimonailDataInterface {
  loading: boolean;
  testimonials: TestimonailInterface[];
  videoOptions: {
    autoplay: boolean;
    controls: boolean;
    sources?: {
      src: string;
      type: string;
    }[];
  };
  options: any;
}
