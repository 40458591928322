
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import Stats from "@/components/Stats.vue";
import NewsDetails from "@/components/NewsDetails/Template1/Detail.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
    NewsDetails,
  },
  data() {
    return {
      bgIMG: require("@/assets/img/doctors-banner.png"),
    };
  },
});
