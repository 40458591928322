<template>
  <div class="news-right-box">
    <h3 class="text-blue">Important Numbers</h3>

    <dl class="row mt-4">
      <dd class="col-sm-5">Enquiries :</dd>
      <dd class="col-sm-6">+91 477 2250100</dd>

      <dd class="col-sm-5">Emergency :</dd>
      <dd class="col-sm-6">
        +91 477 2250100, <br />
        +91 8281998173
      </dd>

      <dd class="col-sm-5">Appointments :</dd>
      <dd class="col-sm-6">+91 477 2250100</dd>

      <dd class="col-sm-5">PRO Helpline :</dd>
      <dd class="col-sm-6">+91 8281998173</dd>

      <dd class="col-sm-5">Email id :</dd>
      <dd class="col-sm-6">contact@providencepoyanil.com</dd>
    </dl>
  </div>
</template>
