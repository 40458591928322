<script lang="ts">
import { defineComponent } from 'vue';
import Facilities from '@/components/Appointment/Facilities.vue';
import { useQuery } from '@/hooks';
import {
  getDepartmentsList,
  getDoctorsListBasedOfDepartmentSelected,
} from '@/graphql';
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';
import Loader from '@/components/LoadingAnimation.vue';
import {
  AppointmentDataInterface,
  DepartmentListPickTypeInterface,
  DoctorDataTypeInterface,
  MailSchemaInterface,
} from '@/ts/interfaces';
import { validateEmail } from '@/ts/functions';

export default defineComponent({
  data(): AppointmentDataInterface {
    return {
      departmentsList: [],
      loading: true,
      selectedDeptId: '',
      doctorsList: [],
      selectedDoctor: '',
      selectedDate: null,
      patientName: '',
      patientEmail: '',
      patientContactNumber: null,
      opNumber: null,
      patientMessage: '',
      error: null,
      sending: false,
      doctorsLoading: false,
      sent: false,
    };
  },
  components: {
    Facilities,
    Loader,
  },

  mounted() {
    (async () => {
      const { data, loading, errors } = await useQuery(getDepartmentsList, {
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.PROVIDENCE_HOSPITAL_ALAPPUZHA,
      });

      this.loading = loading;

      this.departmentsList = data
        .doctorsListingForParticularDepartmentCollection
        .items as DepartmentListPickTypeInterface[];
    })();
  },

  watch: {
    async selectedDeptId(curr, _next): Promise<void> {
      if (curr) {
        this.doctorsLoading = true;
        const { data, loading } = await useQuery(
          getDoctorsListBasedOfDepartmentSelected,
          {
            deptId: curr,
          }
        );

        this.doctorsLoading = loading;

        this.doctorsList = data.doctorsListingForParticularDepartment
          .doctorCollection.items as DoctorDataTypeInterface[];
      } else {
        this.selectedDoctor = '';
      }
    },
  },

  methods: {
    missingInputHandler(errMessage: string, ref: HTMLElement): void {
      this.error = errMessage;

      setTimeout(() => {
        this.error = null;
        ref.focus();
        ref.className = ref.className + ' is-invalid';
      }, 1000);

      setTimeout(() => {
        ref.className = ref.className.replace('is-invalid', '');
      }, 2000);
    },

    async sendMail() {
      if (
        !this.selectedDeptId &&
        this.$refs.selectedDeptId instanceof HTMLSelectElement
      ) {
        this.missingInputHandler(
          'Select a department',
          this.$refs.selectedDeptId
        );
        return;
      }

      // if (
      //   !this.selectedDoctor &&
      //   this.$refs.selectedDoctor instanceof HTMLSelectElement
      // ) {
      //   this.missingInputHandler("Select a doctor", this.$refs.selectedDoctor);
      //   return;
      // }

      // if (
      //   !this.selectedDate &&
      //   this.$refs.selectedDate instanceof HTMLElement
      // ) {
      //   this.missingInputHandler(
      //     "Select a date for appointment",
      //     this.$refs.selectedDate
      //   );
      //   return;
      // }

      if (
        !this.patientName &&
        this.$refs.patientName instanceof HTMLInputElement
      ) {
        this.missingInputHandler('Enter your name', this.$refs.patientName);
        return;
      }

      if (
        !this.patientEmail &&
        this.$refs.patientEmail instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your email-address',
          this.$refs.patientEmail
        );
        return;
      }

      if (
        this.patientEmail &&
        !validateEmail(this.patientEmail) &&
        this.$refs.patientEmail instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter valid email-address',
          this.$refs.patientEmail
        );
        return;
      }

      if (
        !this.patientContactNumber &&
        this.$refs.patientContactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your contact number',
          this.$refs.patientContactNumber
        );
        return;
      }

      if (
        this.patientContactNumber &&
        this.patientContactNumber.toString().length !== 10 &&
        this.$refs.patientContactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter valid contact number',
          this.$refs.patientContactNumber
        );
        return;
      }

      if (!process.env.VUE_APP_EMAIL_ONE) {
        return;
      }

      // AJAX
      const postDataModel: MailSchemaInterface = {
        patientName: this.patientName,
        doctor: this.selectedDoctor,
        department: this.departmentsList.filter(
          ({ sys: { id } }) => id === this.selectedDeptId
        )[0].departments,
        patientContactNumber: this.patientContactNumber,
        outPatientNumber: this.opNumber,
        message: this.patientMessage,
        appointmentOn: new Date(this.selectedDate!).toString().split('00')[0],
        patientEmail: this.patientEmail,
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.PROVIDENCE_HOSPITAL_ALAPPUZHA,
      };

      try {
        this.sending = true;
        await this.axios.post(
          `${process.env.VUE_APP_BASE_URI}/api/v1/service/email`,
          {
            ...postDataModel,
            to: process.env.VUE_APP_EMAIL_ONE,
          }
        );

        this.sending = false;
        this.sent = true;
      } catch (e) {
        this.sending = false;
        console.clear();
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/scss/sectionTemplate1.scss';
</style>

<template>
  <Loader v-if="loading" />
  <div v-else class="container mt-5">
    <div class="row">
      <div class="col-lg-7">
        <div class="section-title">
          <h2>Appointment</h2>
          <p>
            Please fill in the details below and submit to have our dedicated
            concierge set you up with an appointment at your convenience
          </p>
          <p class="text-blue"><strong>+91 8281998174</strong></p>
        </div>
        <div class="appointment-form">
          <h3 class="text-blue mb-4">Department & Doctor Details</h3>
          <form @submit.prevent="sendMail" novalidate>
            <div class="row">
              <div class="col-lg-6 form-group ">
                <select
                  v-model="selectedDeptId"
                  id="inputState"
                  class="form-control form-box"
                  ref="selectedDeptId"
                >
                  <option value="">Select a department</option>
                  <option
                    v-for="{ sys: { id }, departments } in departmentsList"
                    :key="id"
                    :value="id"
                    >{{ departments }}</option
                  >
                </select>
              </div>

              <div class="col-lg-6 form-group mb-4">
                <select
                  :disabled="!selectedDeptId.trim() || doctorsLoading"
                  id="inputState"
                  class="form-control form-box"
                  v-model="selectedDoctor"
                  ref="selectedDoctor"
                >
                  <option value="">{{
                    doctorsLoading ? 'Please wait' : 'Select Doctor'
                  }}</option>
                  <option
                    v-for="{ sys: { id }, name } in doctorsList"
                    :key="id"
                    :value="name"
                    >{{ name }}</option
                  >
                </select>
              </div>
            </div>

            <h3 class="text-blue mb-1">Date</h3>
            <div class="row mb-4 mt-4">
              <div class="col-lg-12" id="date-picker">
                <v-date-picker
                  :min-date="new Date()"
                  v-model="selectedDate"
                  ref="selectedDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="bg-white border px-2 py-1 rounded form-control form-box"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Select a date"
                    />
                  </template>
                </v-date-picker>
              </div>
            </div>

            <h3 class="text-blue mb-4">Patient Details</h3>

            <div class="row">
              <div class="col-lg-6">
                <input
                  ref="patientName"
                  v-model.trim="patientName"
                  type="text"
                  class="form-control form-box"
                  placeholder="Name"
                />
              </div>
              <div class="col-lg-6">
                <input
                  ref="patientEmail"
                  v-model.trim="patientEmail"
                  type="email"
                  class="form-control form-box"
                  placeholder="E-mail"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-lg-6">
                <input
                  ref="patientContactNumber"
                  v-model.number="patientContactNumber"
                  type="tel"
                  class="form-control form-box"
                  placeholder="Phone"
                />
              </div>
              <div class="col-lg-6">
                <input
                  ref="opNumber"
                  v-model.number="opNumber"
                  type="text"
                  class="form-control form-box"
                  placeholder="OP Number (If any)"
                />
              </div>
            </div>

            <div class="form-group mt-4">
              <textarea
                ref="patientMessage"
                v-model.trim="patientMessage"
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="4"
                placeholder="Message"
              ></textarea>
            </div>

            <p
              class="alert alert-danger"
              style="text-align:center;"
              v-if="error"
            >
              {{ error }}
            </p>

            <p
              class="alert alert-success"
              style="text-align:center;"
              v-if="sent"
            >
              Your appointment request has been sent.
            </p>

            <div class="col-lg-4 col-12 mt-5">
              <button
                :disabled="sent || sending"
                type="submit"
                style="width: auto;"
                class="btn btn-primary btn-lg btn-block submit"
              >
                {{ sending ? 'Please wait' : 'Submit' }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Facilities />
    </div>
  </div>
</template>
