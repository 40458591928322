<template>
  <div class="container mt-5">
    <div class="row flex-column-reverse flex-lg-row">
      <div class="col-lg-6">
        <div class="welcome-image">
          <img src="@/assets/img/welcome-image-hsp1.png" class="img-fluid" />
        </div>
      </div>

      <div class="col-lg-6">
        <h6 class="text-red">We Care We Serve</h6>
        <h2>Welcome to Providence Hospital</h2>

        <p class="mt-5">
          In the vicinity of Venice of the East, Alappuzha, Providence Super
          Speciality Hospital is the latest addition to the Poyanil Group of
          Hospitals piloted by Dr Joseph George. Located at a view stretch at
          the eastern side of NH66 between Poomkavu and Thumpoly, Providence is
          designed to be the leader in the Super Speciality Healthcare scenario
          of this part of Kerala.
        </p>

        <div class="row mt-5">
          <div class="col-lg-8 col-8">
            <ul class="list-unstyled">
              <WelcomeServiceList />
            </ul>
          </div>
          <div class="col-lg-4 col-2">
            <div class="branding">
              <img src="@/assets/img/logo.png" class="img-fluid" />
            </div>
          </div>
        </div>

        <div @click="goToAbtUs" class="btn btn-primary read-more-btn">
          Read More
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WelcomeServiceList from "@/components/HospitalsHomePage/common/WelcomeServiceList.vue";

export default defineComponent({
  name: "Welcome Area For Template 1",
  components: { WelcomeServiceList },
  methods: {
    goToAbtUs(): void {
      if (typeof this.$route.meta.toAboutUs === "string") {
        this.$router.push(this.$route.meta.toAboutUs);
      }
    },
  },
});
</script>

<style scoped>
.welcome-image::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 160px;
  background: #de0f17;
  border-radius: 10px;
}

/* .branding {
  background: #fff;
  border-radius: 85px;
  height: 150px;
  width: 150px;
  -webkit-box-shadow: 1px 1px 6px 0px rgba(50, 50, 50, 0.3);
  -moz-box-shadow: 1px 1px 6px 0px rgba(50, 50, 50, 0.3);
  box-shadow: 1px 1px 6px 0px rgba(50, 50, 50, 0.3);
}

.branding img {
  width: 80%;
  margin: auto;
  display: inherit;
  padding-top: 21px;
} */

.read-more-btn,
.contact-btn {
  background: #1a5ec3;
  border-color: #1a5ec3;
  padding: 12px 30px 12px 30px;
  text-transform: uppercase;
  transition: 0.5s;
}
.read-more-btn:hover {
  background: #de0f17;
  border-color: #de0f17;
  transform: translateY(-5px);
}
</style>
