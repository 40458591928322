
import { defineComponent } from "vue";

import Template from "@/components/Templates/Punalur.vue";
import Facilities from "@/components/Facilities/Template3/index.vue";
import { FACILITIES_PARENT_ROUTE_NAME } from "@/ts/enum";

export default defineComponent({
  name: FACILITIES_PARENT_ROUTE_NAME.FACILITIES_POYANIL_PUNALUR,
  components: {
    Template,
    Facilities,
  },
});
