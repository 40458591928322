// meta lists for navigation.
import { RouteMeta } from 'vue-router';

import {
  DEPARTMENT_CHILDREN_ROUTING_PATHS,
  FACILITIES_CHILDREN_ROUTING_PATHS,
  ROUTING_PATHS,
} from '@/ts/enum';

export const poyanil_routes: RouteMeta = {
  toDefaultHome: ROUTING_PATHS.HOME,
  toAppointment: ROUTING_PATHS.TO_POYANIL_APPOINTMENT,
  toHome: ROUTING_PATHS.TO_POYANIL_HOME,
  toAboutUs: ROUTING_PATHS.TO_POYANIL_ABOUT_US,
  toDoctors: ROUTING_PATHS.TO_POYANIL_DOCTORS,
  toGallery: ROUTING_PATHS.TO_POYANIL_GALLERY,
  toNews: ROUTING_PATHS.TO_POYANIL_NEWS,
  toCarrers: ROUTING_PATHS.TO_POYANIL_CAREERS,
  toTestimonials: ROUTING_PATHS.TO_POYANIL_TESTIMONIALS,
  toContactUs: ROUTING_PATHS.TO_POYANIL_CONTACT_US,

  toDepartments: [
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ANESTHESIOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ANESTHESIOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DIABETOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DIABETOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_EMERGENCY_MEDICINE,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_EMERGENCY_MEDICINE,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_MEDICINE,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_MEDICINE,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_SURGERY,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_SURGERY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_INTERVENTIONAL_CARDIOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_INTERVENTIONAL_CARDIOLOGY,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEPHROLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEPHROLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROMEDICINE,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROMEDICINE,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROSURGERY,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROSURGERY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OBSTETRICSANDGYNAECOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OBSTETRICSANDGYNAECOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ORTHOPAEDICS,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ORTHOPAEDICS,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PEDIATRICS,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PEDIATRICS,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PULMONOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PULMONOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_RADIOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_RADIOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_UROLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_UROLOGY,
    },
  ],
  toFacilities: [
    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.CLINIC_24_x_7,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.CLINIC_24_x_7,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.CATHLAB,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.CATHLAB,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_LABORATORY,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_LABORATORY,
    },

    {
      name:
        FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.CSSD,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.CSSD,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.DIALYSIS,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.DIALYSIS,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.EMERGENCY_OR_CASUALITY,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.EMERGENCY_OR_CASUALITY,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.ICU,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.ICU,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.IN_PATIENT_DEPARTMENT,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.IN_PATIENT_DEPARTMENT,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.OPERATION_THEATERS,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.OPERATION_THEATERS,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.OUT_PATIENT_DEPARTMENT,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.OUT_PATIENT_DEPARTMENT,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.PARAMEDICAL_SUPPORT,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.PARAMEDICAL_SUPPORT,
    },
    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.PHARMACY_24_x_7,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.PHARMACY_24_x_7,
    },
    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.POWER_BACKUP,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.POWER_BACKUP,
    },
    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.PREVENTIVE_HEALTH_CHECKUP,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.PREVENTIVE_HEALTH_CHECKUP,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.RADIOLOGY_IMAGING,
      path:
        ROUTING_PATHS.TO_POYANIL_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.RADIOLOGY_IMAGING,
    },
  ],
};

export const poyanil_kozhencherry_routes: RouteMeta = {
  toDefaultHome: ROUTING_PATHS.HOME,
  toHome: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_HOME,
  toAboutUs: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_ABOUT_US,
  toDoctors: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DOCTORS,
  toGallery: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_GALLERY,
  toAppointment: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_APPOINTMENT,
  toNews: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_NEWS,
  toCarrers: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_CAREERS,
  toTestimonials: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_TESTIMONIALS,
  toContactUs: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_CONTACT_US,
  toDepartments: [
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ANESTHESIOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ANESTHESIOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DIABETOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DIABETOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_EMERGENCY_MEDICINE,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_EMERGENCY_MEDICINE,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_MEDICINE,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_MEDICINE,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_SURGERY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_SURGERY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OPHTHALMOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OPHTHALMOLOGY,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEPHROLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEPHROLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROMEDICINE,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROMEDICINE,
    },
    // {
    //   name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROSURGERY,
    //   path:
    //     ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
    //     "/" +
    //     DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROSURGERY,
    // },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OBSTETRICSANDGYNAECOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OBSTETRICSANDGYNAECOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ORTHOPAEDICS,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ORTHOPAEDICS,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PEDIATRICS,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PEDIATRICS,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_RADIOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_RADIOLOGY,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_UROLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_UROLOGY,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_MEDICAL_GASTROENTEROLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_MEDICAL_GASTROENTEROLOGY,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DERMATOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DERMATOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ENT,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ENT,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_CARDIOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_CARDIOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PSYCHIATRY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PSYCHIATRY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DENTAL,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DENTAL,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PULMONOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PULMONOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_FERTILITY_CENTER,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_FERTILITY_CENTER,
    },
  ],
  toFacilities: [
    // {
    //   name: FACILITIES_CHILDREN_ROUTING_PATHS.CATHLAB,
    //   path:
    //     ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
    //     '/' +
    //     FACILITIES_CHILDREN_ROUTING_PATHS.CATHLAB,
    // },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_LABORATORY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_LABORATORY,
    },

    // {
    //   name:
    //     FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
    //   path:
    //     ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
    //     "/" +
    //     FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
    // },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.CLINIC_24_x_7,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.CLINIC_24_x_7,
    },

    // {
    //   name: FACILITIES_CHILDREN_ROUTING_PATHS.CSSD,
    //   path:
    //     ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
    //     "/" +
    //     FACILITIES_CHILDREN_ROUTING_PATHS.CSSD,
    // },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.DIALYSIS,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.DIALYSIS,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.EMERGENCY_OR_CASUALITY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.EMERGENCY_OR_CASUALITY,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.ICU,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.ICU,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.IN_PATIENT_DEPARTMENT,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.IN_PATIENT_DEPARTMENT,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.OPERATION_THEATERS,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.OPERATION_THEATERS,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.PHYSIOTHERAPY,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.PHYSIOTHERAPY,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.BLOOD_BANK,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.BLOOD_BANK,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.OUT_PATIENT_DEPARTMENT,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.OUT_PATIENT_DEPARTMENT,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.PARAMEDICAL_SUPPORT,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.PARAMEDICAL_SUPPORT,
    },
    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.PHARMACY_24_x_7,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.PHARMACY_24_x_7,
    },
    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.POWER_BACKUP,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.POWER_BACKUP,
    },
    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.PREVENTIVE_HEALTH_CHECKUP,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.PREVENTIVE_HEALTH_CHECKUP,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.RADIOLOGY_IMAGING,
      path:
        ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.RADIOLOGY_IMAGING,
    },
  ],
};

export const poyanil_punalur_routes: RouteMeta = {
  toDefaultHome: ROUTING_PATHS.HOME,
  toHome: ROUTING_PATHS.TO_POYANIL_PUNALUR_HOME,
  toAboutUs: ROUTING_PATHS.TO_POYANIL_PUNALUR_ABOUT_US,
  toDoctors: ROUTING_PATHS.TO_POYANIL_PUNALUR_DOCTORS,
  toGallery: ROUTING_PATHS.TO_POYANIL_PUNALUR_GALLERY,
  toNews: ROUTING_PATHS.TO_POYANIL_PUNALUR_NEWS,
  toCarrers: ROUTING_PATHS.TO_POYANIL_PUNALUR_CAREERS,
  toTestimonials: ROUTING_PATHS.TO_POYANIL_PUNALUR_TESTIMONIALS,
  toContactUs: ROUTING_PATHS.TO_POYANIL_PUNALUR_CONTACT_US,
  toAppointment: ROUTING_PATHS.TO_POYANIL_PUNALUR_APPOINTMENT,
  toDepartments: [
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ANESTHESIOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ANESTHESIOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DIABETOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DIABETOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_EMERGENCY_MEDICINE,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_EMERGENCY_MEDICINE,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_MEDICINE,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_MEDICINE,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_SURGERY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_SURGERY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_INTERVENTIONAL_CARDIOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_INTERVENTIONAL_CARDIOLOGY,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEPHROLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEPHROLOGY,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROMEDICINE,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROMEDICINE,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROLOGY,
    },

    // {
    //   name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROSURGERY,
    //   path:
    //     ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
    //     '/' +
    //     DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROSURGERY,
    // },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OBSTETRICSANDGYNAECOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OBSTETRICSANDGYNAECOLOGY,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ORTHOPAEDICS,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ORTHOPAEDICS,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PEDIATRICS,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PEDIATRICS,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PULMONOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PULMONOLOGY,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_RADIOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_RADIOLOGY,
    },

    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_UROLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_UROLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_AYURVEDAM,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_AYURVEDAM,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DERMATOLOGY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DERMATOLOGY,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ENT,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ENT,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DENTAL,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DENTAL,
    },
    {
      name: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_FERTILITY_CENTER,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT +
        '/' +
        DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_FERTILITY_CENTER,
    },
  ],
  toFacilities: [
    // {
    //   name: FACILITIES_CHILDREN_ROUTING_PATHS.CATHLAB,
    //   path:
    //     ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
    //     '/' +
    //     FACILITIES_CHILDREN_ROUTING_PATHS.CATHLAB,
    // },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_LABORATORY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_LABORATORY,
    },

    // {
    //   name:
    //     FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
    //   path:
    //     ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
    //     "/" +
    //     FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
    // },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.CLINIC_24_x_7,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.CLINIC_24_x_7,
    },

    // {
    //   name: FACILITIES_CHILDREN_ROUTING_PATHS.CSSD,
    //   path:
    //     ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
    //     "/" +
    //     FACILITIES_CHILDREN_ROUTING_PATHS.CSSD,
    // },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.DIALYSIS,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.DIALYSIS,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.EMERGENCY_OR_CASUALITY,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.EMERGENCY_OR_CASUALITY,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.ICU,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.ICU,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.IN_PATIENT_DEPARTMENT,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.IN_PATIENT_DEPARTMENT,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.OPERATION_THEATERS,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.OPERATION_THEATERS,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.OUT_PATIENT_DEPARTMENT,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.OUT_PATIENT_DEPARTMENT,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.PARAMEDICAL_SUPPORT,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.PARAMEDICAL_SUPPORT,
    },
    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.PHARMACY_24_x_7,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.PHARMACY_24_x_7,
    },
    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.POWER_BACKUP,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.POWER_BACKUP,
    },
    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.PREVENTIVE_HEALTH_CHECKUP,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.PREVENTIVE_HEALTH_CHECKUP,
    },

    {
      name: FACILITIES_CHILDREN_ROUTING_PATHS.RADIOLOGY_IMAGING,
      path:
        ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES +
        '/' +
        FACILITIES_CHILDREN_ROUTING_PATHS.RADIOLOGY_IMAGING,
    },
  ],
};
