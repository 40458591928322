<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>General Surgery</h2>
      <p>
        General surgery is a surgical specialty that focuses on abdominal
        contents including esophagus, stomach, small intestine, large intestine
        <br />
        liver, pancreas, gallbladder, appendix and bile ducts, and often the
        thyroid gland (depending on local referral patterns).
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/general-medicine.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          The General, Laparoscopic and Bariatric Surgery Department here at
          Poyanil, is equipped with the latest technology and infrastructure,
          supported by an experienced surgical team to ensure surgical
          excellence and best patient outcome. Laparoscopic surgery is also
          known as key-hole surgery or minimally invasive surgery. This surgical
          technique requires precision, skill and experience.
        </p>

        <p>
          Patients receive specialized assessment, latest treatments along with
          clinical post-operative care and a regular follow-up. The advantages
          of this technique over traditional open surgery include a shorter
          hospital stay and faster recovery time; less pain and bleeding after
          the operation; and reduced scarring.
        </p>

        <p>
          This department incorporates surgical management of the endocrine
          system, gastrointestinal tract, colon, liver, pancreas and rectum.
          Common ailments like Gallbladder stones, Hernia, Appendicitis etc. are
          very efficiently treated through laparoscopy . Advanced & anesthetic,
          post-operative analgesic techniques are available at this Centre.
        </p>

        <p><strong>Our Services:</strong></p>

        <div class="row">
          <div class="col-lg-6">
            <ul>
              <li>Advanced General & Minimal Access Surgeries.</li>
              <li>Lap. Hernia Surgeries.</li>
              <li>Varicose Vein Clinic.</li>
              <li>Breast Clinic.</li>
              <li>Thyroid Clinic.</li>
            </ul>
          </div>

          <div class="col-lg-6">
            <ul>
              <li>Obesity Clinic.</li>
              <li>Surgical Oncology.</li>
              <li>Upper GI & Colo-rectal Surgeries.</li>
              <li>Cosmetic & Reconstructive Surgery.</li>
              <li>Day care Surgery.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "General Surgery Doctors",
      queryName: "General Surgery",
      teamName: "General Surgery team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate2.scss";
</style>
