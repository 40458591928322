
import { defineComponent } from "vue";

import Template from "@/components/Templates/Kozhencherry.vue";
import Testimonials from "@/components/Testimonials/Template2/index.vue";

export default defineComponent({
  components: {
    Template,
    Testimonials,
  },
});
