<template>
  <Template>
    <Facilities>
      <router-view></router-view>
    </Facilities>
  </Template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Template from "@/components/Templates/index.vue";
import Facilities from "@/components/Facilities/Template1/index.vue";
import { FACILITIES_PARENT_ROUTE_NAME } from "@/ts/enum";

export default defineComponent({
  name: FACILITIES_PARENT_ROUTE_NAME.FACILITIES_POYANIL,
  components: {
    Template,
    Facilities,
  },
});
</script>
