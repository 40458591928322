<template>
  <Header
    :bgIMG="bgImg"
    :isCenter="true"
    :subTitle="'Poyanil Hospital'"
    :title="'Facilities'"
  />
  <div class="container mt-5">
    <div class="row">
      <slot>
        <div class="col-lg-7">
          <h3>Facilities</h3>
        </div>
      </slot>
      <OtherFacilitiesList />
    </div>
  </div>
  <Stats />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import Stats from "@/components/Stats.vue";
import OtherFacilitiesList from "@/components/Facilities/OtherFacilitiesList.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
    OtherFacilitiesList,
  },
  data() {
    return {
      bgImg: require("@/assets/img/doctors-banner.png"),
    };
  },
});
</script>
