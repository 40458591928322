
import { MAIN_ROUTING_NAMES } from '@/ts/enum';
import { LandingPageDataInterface } from '@/ts/interfaces';
import { defineComponent } from 'vue';
import SocialIcons from '../SocialIcons.vue';
// import AppointmentShortCut from "@/components/AppointmentFlatIcon.vue"

export default defineComponent({
  data(): LandingPageDataInterface {
    return {
      observer: null,
      fixNav: false,
      alappuzhaRoute: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL,
      kozhencherryRoute: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
      pulnalurRoute: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_PUNALUR,
    };
  },
  components: {
    SocialIcons,
    // AppointmentShortCut
  },
  methods: {
    handleNursingRedirect: () => {
      window.open('https://www.poyanilcollegeofnursing.in', '_blank');
    },
  },

  mounted() {
    const element: HTMLElement | null = document.getElementById(
      'fixed-nav-ref'
    );

    this.observer = new IntersectionObserver((entries) => {
      const firstEntery = entries[0];
      if (firstEntery.isIntersecting) {
        this.fixNav = false;
      } else {
        this.fixNav = true;
      }
    });
    if (element && this.observer instanceof IntersectionObserver) {
      this.observer.observe(element);
    }
  },
  unmounted() {
    if (this.observer instanceof IntersectionObserver) {
      this.observer.disconnect();
    }
  },
});
