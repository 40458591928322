
import { defineComponent } from "vue";
import { useQuery } from "@/hooks";
import { getCarrersList } from "@/graphql";
import Loader from "@/components/LoadingAnimation.vue";
import { CareerListDataInterface, JobListInterface } from "@/ts/interfaces";
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from "@/ts/enum";

export default defineComponent({
  data(): CareerListDataInterface {
    return {
      loading: true,
      jobCount: 0,
      careersList: [],
    };
  },
  components: { Loader },

  mounted() {
    (async () => {
      const { data, loading, errors } = await useQuery(getCarrersList, {
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.PROVIDENCE_HOSPITAL_ALAPPUZHA,
      });

      this.loading = loading;
      this.jobCount = data.careersCollection.total;
      this.careersList = data.careersCollection.items as JobListInterface[];
    })();
  },
});
