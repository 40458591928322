<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Orthopaedics</h2>
      <p>
        Orthopaedics is the medical specialty that focuses on injuries and
        diseases of your body's musculoskeletal system. This complex system,
        which includes your bones, joints, ligaments, tendons, muscles, and
        nerves, allows you to move, work, and be active.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/orthopaedics.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          Our mission is to return patients to good health and productivity,
          reliably and efficiently. At present, the department is manned by
          orthopaedic surgeons who honed their craft from CMC Vellore. Hence all
          surgeons have received adequate exposure to all facets of orthopaedic
          trauma and care.
        </p>

        <p>
          Additional training has well equipped the department to successfully
          conduct surgeries in Hand and upper extremity, Paediatric
          Orthopaedics, spine, Reconstructive Orthopaedics, Arthroscopy, Sports
          Injuries, Joint Replacement , Trauma, Reformity corrections etc. The
          department regularly conducts medical camps and provide affordable
          services to the local popular.
        </p>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate3.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Orthopaedics Doctors",
      queryName: "Orthopaedics",
      teamName: "Orthopaedics team",
    };
  },
  components: { DoctorsList },
});
</script>
