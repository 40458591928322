<template>
  <Template>
    <NewsDetails />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Template from "@/components/Templates/index.vue";
import NewsDetails from "@/components/NewsDetails/Template1/index.vue";
import { NEWS_DETAIL_PAGE_NAMES } from "@/ts/enum";

export default defineComponent({
  name: NEWS_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL,
  components: {
    NewsDetails,
    Template,
  },
});
</script>
