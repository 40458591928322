import { App, createApp } from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import VCalendar from 'v-calendar';

import AppComponent from './App.vue';
import router from './router';
import store from './store';
const app: App = createApp(AppComponent);

app
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VCalendar, {})
  .mount('#app');
