
import { WeCareDataInterface } from "@/ts/interfaces";
import { defineComponent } from "vue";
export default defineComponent({
  data(): WeCareDataInterface {
    return {
      healthCareBGI: require("@/assets/img/about-your-health.png"),
    };
  },
});
