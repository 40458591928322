
import { facilitiesData } from "@/serverless/data/facilitiesData";
import { defineComponent } from "vue";

export default defineComponent({
  data(): FacilityContentDisplayInterface {
    return {
      title: "",
      description: "",
      contents: [],
      highlightText: "",
    };
  },
  methods: {
    contentProvider(): void {
      const data = facilitiesData.filter(
        ({ name }) => this.$route.name === name
      )[0];

      if (data) {
        this.contents = data.contents;
        this.highlightText = data.highlightText;
        this.title = data.title;
        this.description = data.description;
      } else {
        this.title = "Something went wrong while fetching data";
        this.description = "Something went wrong while fetching data";
        this.contents = [];
        this.highlightText = "";
      }
    },
  },

  mounted() {
    this.contentProvider();
  },

  watch: {
    $route() {
      this.contentProvider();
    },
  },
});

interface FacilityContentDisplayInterface {
  title: string;
  description: string;
  contents?: string[];
  highlightText?: string;
}
