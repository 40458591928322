
import { defineComponent } from "vue";
import WelcomeServiceList from "@/components/HospitalsHomePage/common/WelcomeServiceList.vue";

export default defineComponent({
  name: "Welcome Area For Template 1",
  components: { WelcomeServiceList },
  methods: {
    goToAbtUs(): void {
      if (typeof this.$route.meta.toAboutUs === "string") {
        this.$router.push(this.$route.meta.toAboutUs);
      }
    },
  },
});
