
import { defineComponent } from "vue";

import Template from "@/components/Templates/index.vue";
import ContactUs from "@/components/ContactUs/screen/Template1/index.vue";
import { CONTACT_US_ROUTE_NAME } from "@/ts/enum";

export default defineComponent({
  name: CONTACT_US_ROUTE_NAME.CONTACT_US_POYANIL,
  components: {
    ContactUs,
    Template,
  },
});
