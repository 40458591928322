
import { defineComponent } from 'vue';
import { useQuery } from '@/hooks';
import { getListOfDoctors } from '@/graphql';
import {
  DoctorsBasicListDataType,
  DoctorsListDataInterface,
} from '@/ts/interfaces';
import Loader from '@/components/LoadingAnimation.vue';
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';

export default defineComponent({
  data(): DoctorsListDataInterface {
    return {
      loading: true,
      doctorsList: [],
      limit: 6,
      moreLoading: false,
      total: 0,
    };
  },
  components: {
    Loader,
  },
  methods: {
    handleViewMore() {
      this.moreLoading = true;
      this.limit! += 6;
      (async () => {
        const { data, loading, errors } = await useQuery(getListOfDoctors, {
          hospital:
            GRAPHQL_QUERYING_HOSPITAL_NAMES.PROVIDENCE_HOSPITAL_ALAPPUZHA,
          limit: this.limit,
        });

        this.moreLoading = loading;
        this.doctorsList = data.doctorsCollection
          .items as DoctorsBasicListDataType[];
      })();
    },
  },

  mounted() {
    (async () => {
      const { data, loading, errors } = await useQuery(getListOfDoctors, {
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.PROVIDENCE_HOSPITAL_ALAPPUZHA,
        limit: this.limit,
      });

      this.loading = loading;
      this.doctorsList = data.doctorsCollection
        .items as DoctorsBasicListDataType[];
      this.total = data.doctorsCollection.total as number;
    })();
  },
});
