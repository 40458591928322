import {
  FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES,
  FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES,
  FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES,
} from '@/ts/enum';
import { FacilitiesData } from '@/ts/interfaces';

const facilitiesDataPoyanil: FacilitiesData[] = [
  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CATHLAB,
    title: 'Cathlab 24 X 7',
    description:
      'A catheterization laboratory, commonly referred to as a cath lab, is an examination room in a hospital or clinic with diagnostic imaging equipment used to visualize the arteries of the heart and the chambers of the heart and treat any stenosis or abnormality found.',
    contents: ['CATHLAB 24 X 7'],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CLINIC_24_x_7,
    title: '24 Hour Clinic',
    description:
      'Our Pharmacy consists of certified professionals providing round the clock pharmacy service to patients.',
    contents: [
      'Our Pharmacy consists of certified professionals providing round the clock pharmacy service to patients. Our pharmacies cater to around 450 out patients and around 100 in-patients a day.',
      'We counsel patients on the proper use of medicines and all special instructions are conveyed to the patient or their bystanders. Pharmacy services are dynamically growing and adopting newer technologies to ensure 100 per cent patient safety and accountability. Streamlining of pharmacy service is done constantly to match the needs of the growing patient’s medicinal requirements. Our future satellite pharmacies will reduce the turnaround time for medication dispensing in the hospital.',
      'A 24 hour pharmacy functions here with separate IP and OP units. The pharmacy stocks quality drugs which patients can avail at affordable prices. The pharmacy is dedicated to extending the service to all those involved in patient care. To ensure quality, the pharmacy department has taken steps for quality testing of drugs dispensed in the pharmacy. A certificate of Good Manufacturing Practice (GMP) is required from all pharmaceutical suppliers.',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CLINICAL_LABORATORY,
    title: 'Clinical Laboratory',
    description:
      'High-end auto analysers are the hallmark of our lab facilities.',
    contents: [
      'High-end auto analysers are the hallmark of our lab facilities. Augmenting this is an informatics enabled workflow which will help in easy scheduling, availing, evaluating and archiving of the tests. We perform all major tests of Biochemistry, Histopathology-Cytology, Haematology, Microbiology, Clinical Pathology.',
    ],
  },

  {
    name:
      FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
    title: 'Clinical Physiotherapy-Starting Soon',
    description: 'Clinical Physiotherapy-Starting Soon',
    contents: [],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CLINIC_24_x_7,
    title: '24 Hour Clinic',
    description:
      'Our Pharmacy consists of certified professionals providing round the clock pharmacy service to patients.',
    contents: [
      'Our Pharmacy consists of certified professionals providing round the clock pharmacy service to patients. Our pharmacies cater to around 450 out patients and around 100 in-patients a day',
      'We counsel patients on the proper use of medicines and all special instructions are conveyed to the patient or their bystanders. Pharmacy services are dynamically growing and adopting newer technologies to ensure 100 per cent patient safety and accountability. Streamlining of pharmacy service is done constantly to match the needs of the growing patient’s medicinal requirements. Our future satellite pharmacies will reduce the turnaround time for medication dispensing in the hospital.',
      'A 24 hour pharmacy functions here with separate IP and OP units. The pharmacy stocks quality drugs which patients can avail at affordable prices. The pharmacy is dedicated to extending the service to all those involved in patient care. To ensure quality, the pharmacy department has taken steps for quality testing of drugs dispensed in the pharmacy. A certificate of Good Manufacturing Practice (GMP) is required from all pharmaceutical suppliers.',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CSSD,
    title: 'CSSD',
    description:
      'Our state of art the CSSD is a benchmark when compared to other facilities',
    contents: [
      'Our state of art the CSSD is a benchmark when compared to other facilities existing with other Healthcare providers in the state. We ensure clean and sterile working environment for optimum delivery of service. We provide around 1000 sterile packs daily to serve our out-patient and in-patient requirements.',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.DIALYSIS,
    title: 'Dialysis 24 X 7',
    description:
      "Dialysis is a treatment that filters and purifies the blood using a machine. This helps keep your fluids and electrolytes in balance when the kidneys can't do their job. Dialysis has been used since the 1940s to treat people with kidney problems.",
    contents: ['DIALYSIS 24 X 7'],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.EMERGENCY_OR_CASUALITY,
    title: 'Emergency / Casuality',
    description:
      'The Emergency Department in our hospital holds a pivotal role in the field of healthcare. We provide 24 X 7 service of all emergency conditions requiring urgent care.',
    contents: [
      'The Emergency Department in our hospital holds a pivotal role in the field of healthcare. We provide 24 X 7 service of all emergency conditions requiring urgent care. All kinds of acute medical, surgical and obstetric conditions are treated here.',
      'Our triage system divides the admission areas into 1, 2 and 3 depending on the severity. Our department is manned by a skilled and dedicated workforce comprising of Doctors, Nurses,Paramedics and Radiographers , who work in collaborative manner to provide optimised health care for all.',
      'Emergency department is equipped with the latest health devices such as Cardiac Defibrillators,Cardiac Monitors,Intubation Kits, ECG, Crash Carts, 24 X 7 X-ray, CT Scan Machine, Lab Facilities etc.',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.ICU,
    title: 'ICU',
    description:
      'The Emergency Department in our hospital holds a pivotal role in the field of healthcare. We provide 24 X 7 service of all emergency conditions requiring urgent care.',
    contents: [
      'ICU is the most important unit of a hospital and is considered as the backbone of all medical and surgical procedures. The department has a 24 X 7 critical care unit that is led by senior anaesthetists and physicians assisted by Nurse Practitioners, Junior Doctors, Technicians, Physiotherapists, Nutritionists, Radiologists and Imaging Technicians.',
      'The aim of the unit is to provide vigilant and constant care to critical patients. It is a multidisciplinary unit that handles patients from various specialties like General Medicine, General Surgery, Pulmonology, Emergency and Trauma, Neurology, Cardiology etc. This unique design of the unit enables us to reduce cross-infection rates, an essential factor in caring for the critically ill.',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.IN_PATIENT_DEPARTMENT,
    title: 'In Patient Department',
    description:
      'Providence Hospital provides a wide range of treatment options for patients with simple and complex health conditions.',
    contents: [
      'We have the following accommodation options available for Inpatients:-',
      '1. General ward',
      '2. Non air conditioned room without TV',
      '3. Non air conditioned room with TV',
      '4. Deluxe',
      '5. Suite',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.OPERATION_THEATERS,
    title: 'Operation Theaters',
    description:
      'The dedicated theatre staff of more than 40 nurses and technicians, along with the surgeons and anaesthetists, are focused on maximizing the success of each surgery.',
    contents: [
      'There are a total of 5 operation theatres, including dedicated theatres for emergency surgery, arthroplasty and other orthopaedic cases, urology, obstetrics and gynaecology. Each theatre is equipped with modern anaesthetic equipment, overhead lighting and hydraulic operation tables. These excellent equipments are complemented by rigorous guidelines for infection control and surgical protocols that enable seamless operation. Our 5 Operation theatres are backed by an excellent CSSD department for sterilisation of instruments, and intensive care units for the care of the critically ill, in close proximity.',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.OUT_PATIENT_DEPARTMENT,
    title: 'Out Patient Department',
    description:
      'Providence Hospital offers consultation, diagnostic services, health checkups and treatment facilities to patients.',
    contents: [
      'Our OPD timing in the morning is from 9 am to 1 pm and evening OPD timing is from 3.30 pm to 6 pm.',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.PARAMEDICAL_SUPPORT,
    title: '24 X 7 Doctors, Nurses & Paramedical Support',
    description:
      'A paramedic is a health care professional whose primary role is to provide advanced emergency medical care for critical and emergent patients who access the emergency medical system.',
    contents: [
      'Our team of Doctors, Nurses and Paramedical staff is ready to take care of your health in the right way at right time round the clock.',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.PHARMACY_24_x_7,
    title: '24 Hour Pharmacy',
    description:
      'Our Pharmacy consists of certified professionals providing round the clock pharmacy service to patients.',
    contents: [
      'Our Pharmacy consists of certified professionals providing round the clock pharmacy service to patients. Our pharmacies cater to around 450 out patients and around 100 in-patients a day.',
      'We counsel patients on the proper use of medicines and all special instructions are conveyed to the patient or their bystanders. Pharmacy services are dynamically growing and adopting newer technologies to ensure 100 per cent patient safety and accountability. Streamlining of pharmacy service is done constantly to match the needs of the growing patient’s medicinal requirements. Our future satellite pharmacies will reduce the turnaround time for medication dispensing in the hospital.',
      'A 24 hour pharmacy functions here with separate IP and OP units. The pharmacy stocks quality drugs which patients can avail at affordable prices. The pharmacy is dedicated to extending the service to all those involved in patient care. To ensure quality, the pharmacy department has taken steps for quality testing of drugs dispensed in the pharmacy. A certificate of Good Manufacturing Practice (GMP) is required from all pharmaceutical suppliers.',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.POWER_BACKUP,
    title: '24 X 7 Power Back Up',
    description:
      'Other than KSEB connection our hospital is backed up with 2 Heavy Duty Generators',
    contents: [
      'Other than KSEB connection our hospital is backed up with 2 Heavy Duty Generators of 250 KVA each that guarantees unmatched surety towards continuous delivery of service for patients without any compromise on treatment due to power failure/ fluctuation.',
    ],
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.PREVENTIVE_HEALTH_CHECKUP,
    title: 'Preventive Health Checkup',
    description:
      'At Providence Hospital, we strongly believe in preventive care. By taking control of your health and being aware of any potential risks associated with your current lifestyle or family..',
    contents: [
      'At Providence Hospital, we strongly believe in preventive care. By taking control of your health and being aware of any potential risks associated with your current lifestyle or family medical history, you are in a better position to ensure that you stay healthy by diagnosing, managing and reversing any health issues early.',
      'Not all health problems show symptoms in their early stages, which is why we offer a range of screening packages, tailored to your individual health needs. Our experienced medical team will be able to advice on the appropriate screening package for you.',
      'Our health screening services can be tailored for individuals and corporate organisations.',
    ],
    highlightText: 'Please Dial +91 8281998173 For Further Assistance / Query',
  },

  {
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.RADIOLOGY_IMAGING,
    title: 'Radiology / Imaging',
    description:
      'Radiology is a branch of medical science, which use radiation, ultrasound, and magnetic resonance as diagnostic, therapeutic, and research tools in medical practice.',
    contents: [
      'Radiology is a branch of medical science, which use radiation, ultrasound, and magnetic resonance as diagnostic, therapeutic, and research tools in medical practice. The Department of Radiology plays an important and significant role in the health care and academic activities of the Institute.',
      'Not all health problems show symptoms in their early stages, which is why we offer a range of screening packages, tailored to your individual health needs. Our experienced medical team will be able to advice on the appropriate screening package for you.',
      'We are a 24 hour service department that provides the following services: CT Scan, Ultrasonography Including Doppler, X - rays and Digital Radiographs.',
      'Radiology department provides advanced equipments, well qualified and experienced professionals. The equipment and technology here are of international standard. Our facility imaging and diagnostics are integrated, and enabling transmission and archiving of images and reports.',
    ],
  },
];

/*
 {
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.,
    title: "",
    description:
      "",
    contents: [""],
  },
 

*/

const facilitiesKozhencherryData: FacilitiesData[] = [
  {
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.CATHLAB,
    title: 'Cathlab 24 X 7',
    description:
      'A catheterization laboratory, commonly referred to as a cath lab, is an examination room in a hospital or clinic with diagnostic imaging equipment used to visualize the arteries of the heart and the chambers of the heart and treat any stenosis or abnormality found.',
    contents: ['CATHLAB 24 X 7'],
  },

  {
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.DIALYSIS,
    title: 'Dialysis 24 X 7',
    description:
      "Dialysis is a treatment that filters and purifies the blood using a machine. This helps keep your fluids and electrolytes in balance when the kidneys can't do their job. Dialysis has been used since the 1940s to treat people with kidney problems.",
    contents: ['DIALYSIS 24 X 7'],
  },

  {
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.CLINIC_24_x_7,
    title: 'Clinic 24/7',
    description:
      'If it’s an emergency, every second is crucial and so is the treatment given. Our 24 Hour Clinic provides daily, around the clock, medical services to patients in need.',
    contents: [
      'Our medical professionals are available night and day, twenty-four hours, seven days a week to treat and consult. The clinic has beds, with separate wards for men and women, and is ready to handle day-to-day sicknesses, illnesses and health concerns as well as trauma cases. Our emergency team is exceptionally competent in tackling all types of emergencies and is functional 24 X 7.',
      'Once the patient is given the required primary care and is stabilized, he/she is evaluated for the nature and severity of illness/injury and is then referred to the appropriate department or specialty for further treatment.',
    ],
  },

  {
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PHARMACY_24_x_7,
    title: 'Pharmacy 24/7',
    description:
      'Our Pharmacy consists of certified professionals providing round the clock pharmacy service to patients. Our pharmacies cater to around 450 out patients and around 100 in-patients a day.',
    contents: [
      'We counsel patients on the proper use of medicines and all special instructions are conveyed to the patient or their bystanders. Pharmacy services are dynamically growing and adopting newer technologies to ensure 100 per cent patient safety and accountability. Streamlining of pharmacy service is done constantly to match the needs of the growing patient’s medicinal requirements. Our future satellite pharmacies will reduce the turnaround time for medication dispensing in the hospital.',
      'A 24 hour pharmacy functions here with separate IP and OP units. The pharmacy stocks quality drugs which patients can avail at affordable prices.',
      'The pharmacy is dedicated to extending the service to all those involved in patient care. To ensure quality, the pharmacy department has taken steps for quality testing of drugs dispensed in the pharmacy. A certificate of Good Manufacturing Practice (GMP) is required from all pharmaceutical suppliers.',
    ],
  },

  {
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PARAMEDICAL_SUPPORT,
    title: 'Doctors, Nurses and Paramedical Support 24/7',
    description:
      'Our team of Doctors, Nurses and Paramedical staff is ready to take care of your health in the right way at right time round the clock.',
    contents: [],
  },

  {
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.POWER_BACKUP,
    title: 'Power Backup 24/7',
    description:
      'Other than KSEB connection our hospital is backed up with 2 Heavy Duty Generators of 250 KVA each that guarantees unmatched surety towards continuous delivery of service for patients without any compromise on treatment due to power failure/ fluctuation.',
    contents: [],
  },

  {
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.CLINICAL_LABORATORY,
    title: 'Clinical Lab',
    description:
      'State of art automated analyzers and other equipment that render accurate and precise test results at the shortest time possible. Manned by highly qualified and experienced technicians and ancillary staff who follow stringent quality control measures.',
    contents: [
      'High-end auto analyzers are the hallmark of our lab facilities. Augmenting this is an informatics enabled workflow which will help in easy scheduling, availing, evaluating and archiving of the tests. We perform all major tests of Biochemistry, Histopathology-Cytology, Hematology, Microbiology, Clinical Pathology.',
    ],
  },

  {
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PHYSIOTHERAPY,
    title: 'Physiotherapy',
    description:
      'The physiotherapy unit functions systematically offering comprehensive treatment facilities for our patients. The mission of this department is to ensure that every patient regains the ability to use their body parts affected by injuries, illness or long term conditions. Patients are given scientific advice and education of their problem',
    contents: [],
  },

  {
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.BLOOD_BANK,
    title: 'Blood Bank',
    description:
      'There is a 24 x 7 blood bank. Facilities to provide blood components like Platelets, Plasma and Red Cells.',
    contents: [],
  },

  {
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.EMERGENCY_OR_CASUALITY,
    title: 'Emergency and Causality 24/7',
    description:
      'The Emergency Department in our hospital holds a pivotal role in the field of healthcare. We provide 24 X 7 service of all emergency conditions requiring urgent care. All kinds of acute medical, surgical and obstetric conditions are treated here.',
    contents: [],
  },

  {
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.ICU,
    title: 'ICU',
    description:
      'ICU is the  most important unit of a hospital and is considered as the backbone of all medical and surgical procedures. The department has a 24 X 7  critical care unit that is led by senior anesthetists and physicians assisted by Nurse Practitioners, Junior Doctors,   Technicians, Physiotherapists, Nutritionists, Radiologists and Imaging Technicians.',
    contents: [
      'The aim of the unit is to provide vigilant and constant care to critical patients. It is a multidisciplinary unit that handles patients from various specialties like General Medicine, General Surgery, Pulmonology, Emergency and Trauma, Neurology, Cardiology etc.',
      'This unique design of the unit enables us to reduce cross-infection rates, an essential factor in caring for the critically ill.',
    ],
  },

  {
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.IN_PATIENT_DEPARTMENT,
    title: 'In Patient Department',
    description:
      'Poyanil Hospital provides a wide range of treatment options for patients with simple and complex health conditions.',
    contents: [
      'We have the following accommodation options available for Inpatients:-',
      '1. General ward',
      '2. Non air conditioned room without TV',
      '3. Non air conditioned room with TV',
      '4. Deluxe',
      '5. Suite',
    ],
  },

  {
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.OPERATION_THEATERS,
    title: 'Operation Theatres',
    description:
      'The dedicated theatre staff of more than 40 nurses and technicians, along with the surgeons and anaesthetists, are focused on maximizing the success of each surgery.',
    contents: [
      'There are a total of 5 operation theatres, including dedicated theatres for emergency surgery, arthroplasty and other orthopaedic cases, urology, obstetrics and gynaecology. Each theatre is equipped with modern anaesthetic equipment, overhead lighting and hydraulic operation tables. These excellent equipments are complemented by rigorous guidelines for infection control and  surgical protocols that enable seamless operation. Our 5 Operation theatres are backed  by an excellent CSSD department for sterilisation of instruments, and intensive care units for the care of the critically ill, in close proximity.',
    ],
  },

  {
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.OUT_PATIENT_DEPARTMENT,
    title: 'Out Patient Department',
    description:
      'Poyanil Hospital offers consultation, diagnostic services, health checkups and treatment facilities to patients.',
    contents: [
      'Our OPD timing in the morning is from 9 am to 1 pm and evening OPD timing is from 3.30 pm to 6 pm.',
    ],
  },

  {
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PREVENTIVE_HEALTH_CHECKUP,
    title: 'Preventive Health Check up',
    description:
      'At Poyanil Hospital, we strongly believe in preventive care. By taking control of your health and being aware of any potential risks associated with your current lifestyle or family medical history, you are in a better position to ensure that you stay healthy by diagnosing, managing and reversing any health issues early',
    contents: [
      'Not all health problems show symptoms in their early stages, which is why we offer a range of screening packages, tailored to your individual health needs. Our experienced medical team will be able to advice on the appropriate screening package for you.',
      'Our health screening services can be tailored for  individuals and corporate organisations.',
    ],
    highlightText: 'Please Dial  +91 8113051234 For Further Assistance / Query',
  },

  {
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.RADIOLOGY_IMAGING,
    title: 'Radiology',
    description:
      'The Department of Radiology plays an important and significant role in the health care activities of the Institute.',
    contents: [
      'We are a 24 hour service department that provides the following services:  Ultrasonography Including Doppler, X - rays and Digital Radiographs.',
    ],
  },
];

const facilitiesPunalurData: FacilitiesData[] = [
  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.CATHLAB,
    title: 'Cathlab 24 X 7',
    description:
      'A catheterization laboratory, commonly referred to as a cath lab, is an examination room in a hospital or clinic with diagnostic imaging equipment used to visualize the arteries of the heart and the chambers of the heart and treat any stenosis or abnormality found.',
    contents: ['CATHLAB 24 X 7'],
  },

  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.CLINIC_24_x_7,
    title: 'Clinic 24/7',
    description:
      'Our 24 Hour Clinic provides daily, around the clock, medical services to patients in need. Our medical professionals are available night and day, twenty-four hours, seven days a week to treat and consult. The clinic has beds, with separate wards for men and women, and is ready to handle day-to-day sicknesses, illnesses and health concerns as well as trauma cases. Our emergency team is exceptionally competent in tackling all types of emergencies and is functional 24 X 7. ',
    contents: [
      'Once the patient is given the required primary care and is stabilized, he/she is evaluated for the nature and severity of illness/injury and is then referred to the appropriate department or specialty for further treatment.',
    ],
  },

  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.PHARMACY_24_x_7,
    title: 'Pharmacy 24/7',
    description:
      'Our Pharmacy consists of certified professionals providing round the clock pharmacy service to patients. We counsel patients on the proper use of medicines and all special instructions A 24 hour pharmacy functions here with separate IP and OP units. The pharmacy stocks quality drugs which patients can avail at affordable prices.',
    contents: [
      'The pharmacy is dedicated to extending the service to all those involved in patient care. To ensure quality, the pharmacy department has taken steps for quality testing of drugs dispensed in the pharmacy. A certificate of Good Manufacturing Practice (GMP) is required from all pharmaceutical suppliers.',
    ],
  },

  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.PARAMEDICAL_SUPPORT,
    title: 'Doctors, Nurses and Paramedical Support 24/7',
    description:
      'Our team of Doctors, Nurses and Paramedical staff is ready to take care of your health in the right way at right time round the clock.',
    contents: [],
  },

  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.POWER_BACKUP,
    title: 'Power Backup 24/7',
    description:
      'Other than KSEB connection our hospital is backed up with 2 Heavy Duty Generators of 250 KVA each that guarantees unmatched surety towards continuous delivery of service for patients without any compromise on treatment due to power failure/ fluctuation.',
    contents: [],
  },

  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.CLINICAL_LABORATORY,
    title: 'Clinical Lab',
    description:
      'High-end auto analysers are the hallmark of our lab facilities. Augmenting this is an informatics enabled workflow which will help in easy scheduling, availing, evaluating and archiving of the tests. We perform all major tests of Biochemistry, Histopathology-Cytology, Haematology, Microbiology, Clinical Pathology.',
    contents: [],
  },

  {
    name:
      FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.EMERGENCY_OR_CASUALITY,
    title: 'Emergency and Casuality 24/7',
    description:
      'The Emergency Department in our hospital holds a pivotal role in the field of healthcare. We provide 24 X 7 service of all emergency conditions requiring urgent care. All kinds of acute medical, surgical and obstetric conditions are treated here.',
    contents: [],
  },

  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.ICU,
    title: 'ICU',
    description:
      'ICU is the  most important unit of a hospital and is considered as the backbone of all medical and surgical procedures. The department has a 24 X 7  critical care unit that is led by senior anaesthetists and physicians assisted by Nurse Practitioners, Junior Doctors,   Technicians, Physiotherapists, Nutritionists, Radiologists and Imaging Technicians.',
    contents: [
      'The aim of the unit is to provide vigilant and constant care to critical patients. It is a multidisciplinary unit that handles patients from various specialties like General Medicine, General Surgery, Pulmonology, Emergency and Trauma, Neurology, Cardiology etc.',
      'This unique design of the unit enables us to reduce cross-infection rates, an essential factor in caring for the critically ill.',
    ],
  },
  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.DIALYSIS,
    title: 'Dialysis 24 X 7',
    description:
      "Dialysis is a treatment that filters and purifies the blood using a machine. This helps keep your fluids and electrolytes in balance when the kidneys can't do their job. Dialysis has been used since the 1940s to treat people with kidney problems.",
    contents: ['DIALYSIS 24 X 7'],
  },

  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.IN_PATIENT_DEPARTMENT,
    title: 'In Patient Department',
    description:
      'Poyanil Hospital provides a wide range of treatment options for patients with simple and complex health conditions.',
    contents: [],
  },

  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.OPERATION_THEATERS,
    title: 'Operation Theatres',
    description:
      'The dedicated theatre staff  of nurses and technicians, along with the surgeons and anaesthetists, are focused on maximizing the success of each surgery.',
    contents: [
      'There are a total of 5 operation theatres, including dedicated theatres for emergency surgery, arthroplasty and other orthopaedic cases, urology, obstetrics and gynaecology. Each theatre is equipped with modern anaesthetic equipment, overhead lighting and hydraulic operation tables. These excellent equipments are complemented by rigorous guidelines for infection control and  surgical protocols that enable seamless operation. Our 5 Operation theatres are backed  by an excellent CSSD department for sterilisation of instruments, and intensive care units for the care of the critically ill, in close proximity.',
    ],
  },

  {
    name:
      FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.OUT_PATIENT_DEPARTMENT,
    title: 'Out Patient Department',
    description:
      'Poyanil Hospital offers consultation, diagnostic services, health checkups and treatment facilities to patients.',
    contents: [
      'Our OPD timing in the morning is from 9 am to 1 pm and evening OPD timing is from 3.30 pm to 6 pm.',
    ],
  },

  {
    name:
      FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.PREVENTIVE_HEALTH_CHECKUP,
    title: 'Preventive Health Check up',
    description:
      'At Providence Hospital, we strongly believe in preventive care. By taking control of your health and being aware of any potential risks associated with your current lifestyle or family medical history, you are in a better position to ensure that you stay healthy by diagnosing, managing and reversing any health issues early.',
    contents: [
      'Not all health problems show symptoms in their early stages, which is why we offer a range of screening packages, tailored to your individual health needs. Our experienced medical team will be able to advice on the appropriate screening package for you. Our health screening services can be tailored for  individuals and corporate organisations.',
    ],
    highlightText: 'Please Dial  +91 8281998173 For Further Assistance / Query',
  },

  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.RADIOLOGY_IMAGING,
    title: 'Radiology',
    description:
      'We are a 24 hour service department that provides the following services:   Ultrasonography Including Doppler, X - rays and Digital Radiographs.',
    contents: [],
  },
];

/**
  {
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.,
    title: "",
    description:
      "",
    contents: [""],
  },
 */

export const facilitiesData = [
  ...facilitiesDataPoyanil,
  ...facilitiesKozhencherryData,
  ...facilitiesPunalurData,
];
