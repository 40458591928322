<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Fertility Center</h2>
      <p>
        This center offers consultation and evaluation of Male and Female
        Primary and secondary infertility. This center also offers IVF and ET
        procedures.
      </p>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Fertility Center Doctors",
      queryName: "Fertility Center",
      teamName: "Fertility Center team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate2.scss";
</style>
