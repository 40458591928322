<template>
  <router-view />
  <social-icons />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/src/jquery.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'video.js/dist/video-js.css';

import Header from '@/components/Header.vue';
import SocialIcons from '@/components/SocialIcons.vue';

import { AppDataInterface } from '@/ts/interfaces';

export default defineComponent({
  name: 'App',
  components: { Header, SocialIcons },
  data(): AppDataInterface {
    return {
      hospitalName: 'A Unit Of Poyanil Hospital',
      sinceYear: 1990,
    };
  },
});
</script>

<style>
* {
  -webkit-overflow-scrolling: touch;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: white;
}

*::-webkit-scrollbar {
  width: 13px;
  background-color: white;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: #d1c8c8;
}

@media (max-width: 380px) {
  *::-webkit-scrollbar {
    display: none;
  }

  *::-webkit-scrollbar-thumb {
    display: none;
  }
}
</style>
