<template>
  <section class="section gallery">
    <div class="container">
      <div class="gallery-grid">
        <div class="gallery-item">
          <img src="@/assets/img/gallery/director.jpg" alt="photo 1" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-1.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image1.jpg" alt="photo 2" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-2.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image2.jpg" alt="photo 3" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-3.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image3.jpg" alt="photo 4" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-4.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image4.jpg" alt="photo 5" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-5.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image5.jpg" alt="photo 6" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-6.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image6.jpg" alt="photo 7" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-7.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image7.jpg" alt="photo 8" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-8.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image8.jpg" alt="photo 9" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-9.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image9.jpg" alt="photo 10" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-10.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image10.jpg" alt="photo 11" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-11.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image11.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image12.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image13.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image14.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image15.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image16.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image17.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image18.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image19.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image20.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image21.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image22.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image23.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image24.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image25.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image26.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image27.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image28.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image29.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image30.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image31.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>

        <div class="gallery-item">
          <img src="@/assets/img/gallery/image32.jpg" alt="photo 12" />
          <div
            class="gallery-item-text"
            data-url="@/assets/img/gallery-img-12.png"
          ></div>
        </div>
      </div>
    </div>
  </section>

  <div class="lightbox preload">
    <div class="lb-content">
      <!-- You may put a loader gif or svg here for starters -->
      <img
        class="lb-img"
        src="https://res.cloudinary.com/ahmedagadir/image/upload/v1530730083/pokemon-API/loader-1.svg"
        alt="lightbox image"
      />
      <div>
        <a class="lb-url" href="" rel="noopener" target="_blank"></a>
        <div class="lb-text"></div>
      </div>
      <a href="#" class="close-button">&times;</a>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // Helper functions
    let qs = (selector, context = document) => context.querySelector(selector);
    let qsa = (selector, context = document) =>
      Array.from(context.querySelectorAll(selector));

    // Get gallery item into Lightbox
    function openLightbox(e) {
      const gitem = e.currentTarget,
        itemimg = qs("img", gitem),
        itemtext = qs(".gallery-item-text", gitem),
        itemUrl = itemtext.dataset.url;
      // Fill in the elements of lightbox.
      const lightbox = qs(".lightbox"),
        lightboximg = qs(".lb-img", lightbox),
        lightboxtext = qs(".lb-text", lightbox),
        lightboxDataURL = qs(".lb-url", lightbox);
      lightboximg.onload = () => {
        // fires as soon as image.src is assigned a URL.
        lightboxtext.innerHTML = itemtext.innerHTML;
        lightboxDataURL.setAttribute("href", itemUrl);
        lightbox.classList.add("open");
      };
      // Assigns a relative url. This will fire onload.
      lightboximg.setAttribute("src", itemimg.getAttribute("src"));
    }

    function closeLightbox(e) {
      e.preventDefault();
      const lightbox = e.currentTarget.closest(".lightbox");
      lightbox.classList.remove("open");
    }

    document.addEventListener("DOMContentLoaded", () => {
      const lightbox = qs(".lightbox.preload");
      if (lightbox) lightbox.classList.remove("preload");

      const gitems = qsa(".gallery-item");
      gitems.forEach((el, i) => {
        el.addEventListener("click", openLightbox);
        el.style.setProperty("--staggered-delay", (i + 3) * 150 + "ms"); // 2020-09-21
      });

      const lbclose = qs(".lightbox .close-button");
      if (lbclose) lbclose.addEventListener("click", closeLightbox);
    });
  },
};
</script>
