<template>
  <div>
    loading...
  </div>
</template>

<script>
export default {
  mounted() {
    this.$router.push('/');
  },
};
</script>
