<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Testimonials</h2>
      <p>
        We take our Motto "We Care, We serve" seriously.
        <br />
        Here are a few people who loved their experience at Providence Hospital.
      </p>
    </div>
    <Loader v-if="loading" />
    <h2 v-else-if="!loading && !testimonials.length">No Reviews found</h2>
    <div id="testimonial-section" v-else>
      <div class="row">
        <div
          class="col-lg-4 col-sm-12 col-12 testimonials"
          v-for="{
            sys: { id },
            video,
            shortReview,
            reviewPoster,
            testimonialContent,
          } in testimonials"
          :key="id"
          style="display: grid"
        >
          <router-link
            :to="`${$route.meta.toTestimonials}/detail/${id}`"
            :style="{ pointerEvents: testimonialContent ? '' : 'none' }"
            class="introduce pb-3"
          >
            <div>
              <img
                v-if="!video && reviewPoster"
                :src="reviewPoster.url"
                style="width:100%;height:200px"
              />
              <img
                v-if="!video && !reviewPoster"
                src="@/assets/img/noImg.png"
                style="width:100%;height:200px"
              />
              <div
                v-if="video"
                @click.prevent=""
                style="height:200px;pointerEvents:all"
              >
                <video-player
                  :options="{
                    ...videoOptions,
                    sources: [{ src: video.url, type: video.contentType }],
                    poster: reviewPoster?.url,
                  }"
                />
              </div>

              <div
                class="container mt-2"
                v-html="documentToHtmlString(shortReview.json, options)"
              ></div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getTestimonial } from '@/graphql';
import { useQuery } from '@/hooks';
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';
import { defineComponent } from 'vue';
import Loader from '@/components/LoadingAnimation.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default defineComponent({
  components: { Loader, VideoPlayer },
  data(): TestimonailDataInterface {
    return {
      loading: true,
      testimonials: [],
      videoOptions: {
        autoplay: false,
        controls: true,
      },
      options: {
        renderMark: {
          [MARKS.BOLD]: (text: string) => `<custom-bold>${text}<custom-bold>`,
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node: any, next: any) =>
            `<custom-paragraph>${next(node.content)}</custom-paragraph>`,
          [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const { content } = this.newsDetail as any;

            const img = content.links.assets.block.find(
              (entries: any) => entries.sys.id === node.data.target.sys.id
            );

            return `
            <div>
            <img
                style="width:100%"
               src=${img?.url}
               alt='content-img'
             />
            </div>
             `;
          },
        },
      },
    };
  },
  methods: {
    documentToHtmlString,
  },

  mounted() {
    (async () => {
      const { data, loading, errors } = await useQuery(getTestimonial, {
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
      });

      this.loading = loading;
      this.testimonials = data.testimonialsCollection
        .items as TestimonailInterface[];
    })();
  },
});

interface TestimonailInterface {
  reviewPoster?: {
    url: string;
  };
  shortReview: {
    json: any;
  };
  video?: {
    url: string;
  };
  testimonialContent?: {
    json: any;
  };
}

interface TestimonailDataInterface {
  loading: boolean;
  testimonials: TestimonailInterface[];
  videoOptions: {
    autoplay: boolean;
    controls: boolean;
    sources?: {
      src: string;
      type: string;
    }[];
  };
  options: any;
}
</script>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
  text-align: center;
}

.profic-pic-testimonials img {
  width: 70px;
  height: 70px;
}

.profic-pic-testimonials:hover {
  transform: scale(1.1);
  transition: all 0.5s;
}

.introduce {
  height: 250px;
  background-color: white;
  box-shadow: 0 3px 10px #5969f642;
  border-bottom: 4px solid #5969f6;
  color: black;
}

.introduce:hover {
  box-shadow: 0 3px 15px #5969f666;
  border-bottom: 4px solid #de0f17;
  color: black;
  text-decoration: none;
}
</style>
