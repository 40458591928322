
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import Stats from "@/components/Stats.vue";
import Insurance from "@/components/HospitalsHomePage/Hospital1/InsuranceList.vue";
import NewsList from "@/components/News/Template1/NewsList.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
    Insurance,
    NewsList,
  },
  data() {
    return {
      bgImg: require("@/assets/img/doctors-banner.png"),
    };
  },
});
