import { SocialLinks } from '@/ts/interfaces';

export const ALAPPUZHA_HSP_SOCIAL_LINKS: SocialLinks = {
  whatsApp: 8281998173,
  email: 'providencepoyanil@gmail.com',
  contactNumber: '0477-2250100',
  fbLink:
    'https://m.facebook.com/providencehospitalalappuzha/?tsid=0.7830931174725102&source=result',
};

export const KOZHENCHERRY_HSP_SOCIAL_LINKS: SocialLinks = {
  whatsApp: 7025653963,
  email: 'poyanilhospitals@gmail.com',
  contactNumber: '0468-2211999',
  fbLink: 'https://www.facebook.com/poyanilgroup/',
};

export const PUNALUR_HSP_SOCIAL_LINKS: SocialLinks = {
  whatsApp: 9656986668,
  email: 'admn.poyanilplr@gmail.com',
  contactNumber: '0475-2230600',
  fbLink: 'https://www.facebook.com/poyanilhospitalpunalur/',
};
