<template>
  <div class="container" id="top-navbar">
    <div class="row" style="align-items:center;">
      <div @click="goToHome" class="col-lg-6 col-4" id="logo">
        <img :src="logoIco" class="logo img-fluid" />
        <p v-if="imgSub" class="logo-title">Poyanil Hospital</p>
      </div>
      <div class="col-lg-6 col-8">
        <ul class="list-inline float-right">
          <li class="list-inline-item">
            {{ headerTitle }}
            <br />
            Since {{ sinceYear }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/**
 * @CommonHeader accross all three websites
 * @prop {headerTitle,sinceYear,imgSub?,logoIco}
 * @method = goToHome():redirect to landing site
 */

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Header',
  props: ['headerTitle', 'sinceYear', 'imgSub', 'logoIco'],
  methods: {
    goToHome(): void {
      this.$router.push('/');
    },
  },
});
</script>

<style>
.logo-title {
  white-space: nowrap;
}
</style>
