import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  Router,
  RouteRecordRaw,
  RouterOptions,
} from 'vue-router';

import Home from '../views/Home.vue';

import PoyanilHospital from '@/views/HospitalsHomeScreen/index.vue';
import PoyanilHospitalKozhencherry from '@/views/HospitalsHomeScreen/Kozhencherry.vue';
import PoyanilHospitalPunalur from '@/views/HospitalsHomeScreen/Punalur.vue';

import AboutUs from '@/views/AboutUs/index.vue';
import AboutUsKozhencherry from '@/views/AboutUs/Kozhencherry.vue';
import AboutUsPunalur from '@/views/AboutUs/Punalur.vue';

import Doctors from '@/views/Doctors/index.vue';
import DoctorsKozhencherry from '@/views/Doctors/Kozhencherry.vue';
import DoctorsPunalur from '@/views/Doctors/Punalur.vue';

import Department from '@/views/Departments/index.vue';
import DepartmentKozhencherry from '@/views/Departments/Kozhencherry.vue';
import DepartmentPunalur from '@/views/Departments/Punalur.vue';

import Facilities from '@/views/Facilities/index.vue';
import FacilitiesKozhencherry from '@/views/Facilities/Kozhencherry.vue';
import FacilitiesPunalur from '@/views/Facilities/Punalur.vue';

import Gallery from '@/views/Gallary/index.vue';
import GallertKozhencherry from '@/views/Gallary/Kozhencherry.vue';
import GalleryPunalur from '@/views/Gallary/Punalur.vue';

import News from '@/views/News/index.vue';
import NewsKozhencherry from '@/views/News/Kozhencherry.vue';
import NewsPunalur from '@/views/News/Punalur.vue';

import Careers from '@/views/Careers/index.vue';
import CareersKozhencherry from '@/views/Careers/Kozhencherry.vue';
import CareersPunalur from '@/views/Careers/Punalur.vue';

import Testimonials from '@/views/Testimonials/index.vue';
import TestimonialsKozhencherry from '@/views/Testimonials/Kozhencherry.vue';
import TestimonialsPunalur from '@/views/Testimonials/Punalur.vue';

import ContactUs from '@/views/ContactUs/index.vue';
import ContactUsKozhencherry from '@/views/ContactUs/Kozhencherry.vue';
import ContactUsPunalur from '@/views/ContactUs/Punalur.vue';

import Appointment from '@/views/Appointment/index.vue';
import AppointmentKozhencherry from '@/views/Appointment/Kozhencherry.vue';
import AppointmentPunalur from '@/views/Appointment/Punalur.vue';

import DoctorDetails from '@/views/DoctorDetailView/index.vue';
import DoctorDetailsKozhencherry from '@/views/DoctorDetailView/Kozhencherry.vue';
import DoctorDetailsPunalur from '@/views/DoctorDetailView/Punalur.vue';

import NewsDetails from '@/views/NewsDetail/index.vue';
import NewsDetailsKozhencherry from '@/views/NewsDetail/Kozhencherry.vue';
import NewsDetailsPunalur from '@/views/NewsDetail/Punalur.vue';

import CareerDetail from '@/views/CareersDetail/index.vue';
import CareerDetailKuzhencherry from '@/views/CareersDetail/Kozhencherry.vue';
import CareerDetailPunalur from '@/views/CareersDetail/Punalur.vue';

import TestimonialDetail from '@/views/TestimonialDetail/index.vue';
import TestimonialDetailKuzhencherry from '@/views/TestimonialDetail/Kozhencherry.vue';
import TestimonialDetailPunalur from '@/views/TestimonialDetail/Punalur.vue';

// import CareerJobApplyLink from '@/components/Job/index.vue';

import NFPage from '@/views/NFPage.vue';

// Facilities Children

import {
  ABOUT_US_ROUTE_NAMES,
  DEPARTMENT_PARENT_ROUTE_NAMES,
  DOCTORS_ROUTE_NAMES,
  FACILITIES_PARENT_ROUTE_NAME,
  MAIN_ROUTING_NAMES,
  ROUTING_PATHS,
  GALLERY_ROUTE_NAMES,
  NEWS_ROUTE_NAMES,
  CAREERS_ROUTE_NAME,
  TESTIMONIALS_ROUTE_NAME,
  CONTACT_US_ROUTE_NAME,
  APPOINTMENT_ROUTE_NAME,
  LANDING_ROUTE_NAME,
  DOCTOR_DETAIL_PAGE_NAMES,
  NEWS_DETAIL_PAGE_NAMES,
  CAREER_DETAIL_PAGE_NAMES,
  TESTIMONIAL_DETAIL_PAGE_NAMES,
} from '@/ts/enum';

import {
  poyanil_routes,
  poyanil_kozhencherry_routes,
  poyanil_punalur_routes,
} from '@/meta/router';

import {
  DepartmentChildrenRoutes,
  DepartmentChildrenRoutesKozhencherry,
  DepartmentChildrenRoutesPunalur,
} from '@/router/router';
import {
  facilitiesChildrenRoute,
  facilitiesKozhencherryChildrenRoute,
  facilitiesPunalurChildrenRoute,
} from './facilitiesChildren';

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTING_PATHS.HOME,
    name: LANDING_ROUTE_NAME.NAME,
    component: Home,
  },
  // Home Pages of Hospitals
  {
    path: ROUTING_PATHS.TO_POYANIL_HOME,
    name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL,
    component: PoyanilHospital,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_HOME,
    name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
    component: PoyanilHospitalKozhencherry,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_HOME,
    name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_PUNALUR,
    component: PoyanilHospitalPunalur,
    meta: poyanil_punalur_routes,
  },

  // About Us
  {
    path: ROUTING_PATHS.TO_POYANIL_ABOUT_US,
    component: AboutUs,
    name: ABOUT_US_ROUTE_NAMES.ABOUT_US_POYANIL,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_ABOUT_US,
    component: AboutUsKozhencherry,
    name: ABOUT_US_ROUTE_NAMES.ABOUT_US_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_ABOUT_US,
    component: AboutUsPunalur,
    name: ABOUT_US_ROUTE_NAMES.ABOUT_US_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // Doctors
  {
    path: ROUTING_PATHS.TO_POYANIL_DOCTORS,
    component: Doctors,
    name: DOCTORS_ROUTE_NAMES.DOCTORS_POYANIL,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DOCTORS,
    component: DoctorsKozhencherry,
    name: DOCTORS_ROUTE_NAMES.DOCTORS_POYANIL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_DOCTORS,
    component: DoctorsPunalur,
    name: DOCTORS_ROUTE_NAMES.DOCTORS_POYANIL_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // Departments
  {
    path: ROUTING_PATHS.TO_POYANIL_DEPARTMENT,
    component: Department,
    name: DEPARTMENT_PARENT_ROUTE_NAMES.DEPARTMENT_POYANIL,
    meta: poyanil_routes,
    children: DepartmentChildrenRoutes,
  },

  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DEPARTMENT,
    component: DepartmentKozhencherry,
    name: DEPARTMENT_PARENT_ROUTE_NAMES.DEPARTMENT_POYANIL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
    children: DepartmentChildrenRoutesKozhencherry,
  },

  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_DEPARTMENT,
    component: DepartmentPunalur,
    name: DEPARTMENT_PARENT_ROUTE_NAMES.DEPARTMENT_POYANIL_PUNALUR,
    meta: poyanil_punalur_routes,
    children: DepartmentChildrenRoutesPunalur,
  },

  // Facilities
  {
    path: ROUTING_PATHS.TO_POYANIL_FACILITIES,
    component: Facilities,
    name: FACILITIES_PARENT_ROUTE_NAME.FACILITIES_POYANIL,
    meta: poyanil_routes,
    children: facilitiesChildrenRoute,
  },

  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY__FACILITIES,
    component: FacilitiesKozhencherry,
    name: FACILITIES_PARENT_ROUTE_NAME.FACILITIES_POYANIL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
    children: facilitiesKozhencherryChildrenRoute,
  },

  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_FACILITIES,
    component: FacilitiesPunalur,
    name: FACILITIES_PARENT_ROUTE_NAME.FACILITIES_POYANIL_PUNALUR,
    meta: poyanil_punalur_routes,
    children: facilitiesPunalurChildrenRoute,
  },

  // Gallery
  {
    path: ROUTING_PATHS.TO_POYANIL_GALLERY,
    component: Gallery,
    name: GALLERY_ROUTE_NAMES.GALLERY_POYANIL,
    meta: poyanil_routes,
  },

  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_GALLERY,
    component: GallertKozhencherry,
    name: GALLERY_ROUTE_NAMES.GALLERY_POYANIL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },

  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_GALLERY,
    component: GalleryPunalur,
    name: GALLERY_ROUTE_NAMES.GALLERY_POYANIL_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // NEWS
  {
    path: ROUTING_PATHS.TO_POYANIL_NEWS,
    component: News,
    name: NEWS_ROUTE_NAMES.NEWS_POYANIL,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_NEWS,
    component: NewsKozhencherry,
    name: NEWS_ROUTE_NAMES.NEWS_POYANIL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_NEWS,
    component: NewsPunalur,
    name: NEWS_ROUTE_NAMES.NEWS_POYANIL_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // Careers

  {
    path: ROUTING_PATHS.TO_POYANIL_CAREERS,
    component: Careers,
    name: CAREERS_ROUTE_NAME.CAREERS_POYANIL,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_CAREERS,
    component: CareersKozhencherry,
    name: CAREERS_ROUTE_NAME.CAREERS_POYANIL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_CAREERS,
    component: CareersPunalur,
    name: CAREERS_ROUTE_NAME.CAREERS_POYANIL_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // Testimonials
  {
    path: ROUTING_PATHS.TO_POYANIL_TESTIMONIALS,
    component: Testimonials,
    name: TESTIMONIALS_ROUTE_NAME.TESTIMONIALS_POYANIL,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_TESTIMONIALS,
    component: TestimonialsKozhencherry,
    name: TESTIMONIALS_ROUTE_NAME.TESTIMONIALS_POYANIL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_TESTIMONIALS,
    component: TestimonialsPunalur,
    name: TESTIMONIALS_ROUTE_NAME.TESTIMONIALS_POYANIL_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // Contact Us
  {
    path: ROUTING_PATHS.TO_POYANIL_CONTACT_US,
    component: ContactUs,
    name: CONTACT_US_ROUTE_NAME.CONTACT_US_POYANIL,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_CONTACT_US,
    component: ContactUsKozhencherry,
    name: CONTACT_US_ROUTE_NAME.CONTACT_US_POYANIL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_CONTACT_US,
    component: ContactUsPunalur,
    name: CONTACT_US_ROUTE_NAME.CONTACT_US_POYANIL_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // Appointment
  {
    path: ROUTING_PATHS.TO_POYANIL_APPOINTMENT,
    component: Appointment,
    name: APPOINTMENT_ROUTE_NAME.APPOINTMENT_POYANIL,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_APPOINTMENT,
    component: AppointmentKozhencherry,
    name: APPOINTMENT_ROUTE_NAME.APPOINTMENT_POYANIL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_APPOINTMENT,
    component: AppointmentPunalur,
    name: APPOINTMENT_ROUTE_NAME.APPOINTMENT_POYANIL_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // Doctor Detail Page
  {
    path: ROUTING_PATHS.TO_POYANIL_DOCTORS_DETAIL,
    component: DoctorDetails,
    name: DOCTOR_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL,
    meta: poyanil_routes,
  },

  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_DOCTORS_DETAIL,
    component: DoctorDetailsKozhencherry,
    name: DOCTOR_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },

  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_DOCTORS_DETAIL,
    component: DoctorDetailsPunalur,
    name: DOCTOR_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // News Detail Pages
  {
    path: ROUTING_PATHS.TO_POYANIL_NEWS_DETAIL,
    component: NewsDetails,
    name: NEWS_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_NEWS_DETAIL,
    component: NewsDetailsKozhencherry,
    name: NEWS_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_NEWS_DETAIL,
    component: NewsDetailsPunalur,
    name: NEWS_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // Career Detail Pages
  {
    path: ROUTING_PATHS.TO_POYANIL_CAREER_DETAIL,
    component: CareerDetail,
    name: CAREER_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_CAREER_DETAIL,
    component: CareerDetailKuzhencherry,
    name: CAREER_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_CAREER_DETAIL,
    component: CareerDetailPunalur,
    name: CAREER_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_PUNALUR,
    meta: poyanil_punalur_routes,
  },

  // Testimonials Detail
  {
    path: ROUTING_PATHS.TO_POYANIL_TESTIMONIALS_DETAIL,
    component: TestimonialDetail,
    name: TESTIMONIAL_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL,
    meta: poyanil_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_TESTIMONIALS_DETAIL,
    component: TestimonialDetailKuzhencherry,
    name: TESTIMONIAL_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
    meta: poyanil_kozhencherry_routes,
  },
  {
    path: ROUTING_PATHS.TO_POYANIL_PUNALUR_TESTIMONIALS_DETAIL,
    component: TestimonialDetailPunalur,
    name: TESTIMONIAL_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_PUNALUR,
    meta: poyanil_punalur_routes,
  },
  { path: '/:pathMatch(.*)*', component: NFPage },
];

const options: RouterOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    _savedPosition
  ) {
    if (to.name !== from.name) {
      return { top: 0, left: 0, behavior: 'auto' };
    }
  },
};

const router: Router = createRouter(options);
router.beforeEach(
  (
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    document.title = `${to.name?.toString()}`;
    next();
  }
);

export default router;
