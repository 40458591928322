<template>
  <div class="container mb-5" id="what-we-do">
    <div class="section-title">
      <h2>What We Do Best</h2>
      <p>
        Here is what we excel at. To avail our best in class facilities please
        contact us or come by and experience our exceptional service.
      </p>
    </div>

    <div class="row">
      <div
        class="col-lg-4 col-sm-6 mb-5"
        v-for="({ icon, title, description, link }, i) in items"
        :key="i"
      >
        <div class="what-we-do">
          <img :src="icon" class="img-fluid icon" />
          <h3>{{ title }}</h3>
          <p>{{ description }}</p>
          <router-link class="more-btn" :to="$route.meta.toHome + '/' + link">
            <i class="fa fa-arrow-right"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { WhatWeDoBestDataInterface } from "@/ts/interfaces";
import { whatWeDoBestItems } from "@/static/data";

export default defineComponent({
  data(): WhatWeDoBestDataInterface {
    return {
      items: whatWeDoBestItems,
    };
  },
  mounted() {},
});
</script>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
}
.section-title:after,
.section-title:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 50%;
  left: 10px;
  top: 0;
  background: #de0f17;
  transition: 0.5s;
}

.section-title:after {
  left: 0;
  height: 100%;
}

#what-we-do {
  margin-top: 100px;
}

.what-we-do {
  box-shadow: 0 8px 16px 0 rgba(146, 184, 255, 0.2);
  padding: 30px 20px;
}

.what-we-do img {
  margin-bottom: 25px;
}

.what-we-do p {
  margin-top: 20px;
  padding-bottom: 20px;
}

.what-we-do .more-btn {
  position: absolute;
  right: 15px;
  bottom: 0;
  width: 45px;
  height: 40px;
  line-height: 44px;
  text-align: center;
  box-shadow: 0 0 25px 0 rgba(0, 27, 103, 0.2);
  border-radius: 50% 0 0 0;
}

.what-we-do .more-btn:hover {
  background: #de0f17;
  color: #fff;
}
h3 {
  font-size: 20px;
  font-family: "ProximaNovaBold";
}
</style>
