
import { defineComponent } from "vue";

import { getDoctorsListBasedOnDepartment } from "@/graphql";
import { useQuery } from "@/hooks";
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from "@/ts/enum";
import Loader from "@/components/LoadingAnimation.vue";

interface DoctorListTemplateDataInterface {
  loading: boolean;
  doctorsList: DoctoresListTypeForDepartment[];
}

interface DoctoresListTypeForDepartment {
  name: string;
  email: string;
  centerOfExcellence: string;
  profilePic: {
    url: string;
  };
  sys: {
    id: string;
  };
}

export default defineComponent({
  data(): DoctorListTemplateDataInterface {
    return {
      loading: true,
      doctorsList: [],
    };
  },
  components: { Loader },

  props: {
    name: {
      type: String,
      required: true,
    },
    teamName: {
      type: String,
      required: true,
    },

    queryName: {
      type: String,
      required: true,
    },
  },

  mounted() {
    (async () => {
      if (this.queryName) {
        const { data, loading, errors } = await useQuery(
          getDoctorsListBasedOnDepartment,
          {
            hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_PUNALUR,
            department: this.queryName,
            limit: 10,
            skip: 0,
          }
        );
        this.loading = loading;

        if (data.doctorsListingForParticularDepartmentCollection.items.length) {
          this.doctorsList = data
            .doctorsListingForParticularDepartmentCollection.items[0]
            .doctorCollection.items as DoctoresListTypeForDepartment[];
        }
      }
    })();
  },
});
