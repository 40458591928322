<template>
  <div v-if="show" class="container-fluid p-0">
    <div class="section-box text-white text-center">
      <h2 class="text-white">
        Please feel free to contact us with any query,
      </h2>
      <p>
        Feedback or just to get in touch with us. We look forward to hearing
        from you.
      </p>
      <router-link :to="$route.meta.toContactUs">
        <button type="button" class="btn btn-primary btn-lg contact-btn">
          Contact Us
        </button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      show: true,
    };
  },

  mounted() {
    if (this.$route.path === this.$route.meta.toContactUs) {
      this.show = false;
    } else {
      this.show = true;
    }
  },
});
</script>

<style scoped>
.section-box {
  background-color: #1a5ec3;
  padding-top: 70px;
  padding-bottom: 70px;
}

.contact-btn {
  background: #fff;
  background-color: rgb(255, 255, 255);
  border-color: #1a5ec3;
  border-width: 2px;
  color: #1a5ec3;
  padding: 12px 30px 12px 30px;
  text-transform: uppercase;
  transition: 0.5s;
}
.contact-btn {
  background-color: #de0f17;
  color: #fff;
  margin-top: 25px;
  transition: all 0.3s;
}
.contact-btn:hover {
  background: #fff;
  border-color: #fff;
  color: #de0f17;
  transform: translateY(-5px);
}
</style>
