<template>
  <Template>
    <TestimonialDetail />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Template from '@/components/Templates/Punalur.vue';
import TestimonialDetail from '@/components/TestimonialDetail/Template3/index.vue';
import { TESTIMONIAL_DETAIL_PAGE_NAMES } from '@/ts/enum';

export default defineComponent({
  name: TESTIMONIAL_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_PUNALUR,
  components: {
    TestimonialDetail,
    Template,
  },
});
</script>
