<template>
  <div class="col-lg-5 facilities-list-box">
    <ul class="list-group">
      <li class="list-group-item list-group-item-heading">
        <h2>Our Facilities</h2>
      </li>

      <router-link
        v-for="({ name, path }, i) in $route.meta.toFacilities"
        :key="i"
        :to="path"
        active-class="active-facility"
      >
        <li class="list-group-item">
          <i class="fas fa-angle-right mr-2"></i>
          {{ name.split("_").join(" ") }}
        </li>
      </router-link>
    </ul>
  </div>
</template>

<style scoped>
.list-group-item {
  text-transform: capitalize;
}

.active-facility .list-group-item {
  background-color: #1a5ec3;
  color: #fff !important;
}

.active-facility .list-group-item i {
  background-color: #1a5ec3;
  color: #fff !important;
}
</style>
