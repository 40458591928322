
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Orthopaedics Doctors",
      queryName: "Orthopaedics",
      teamName: "Orthopaedics team",
    };
  },
  components: { DoctorsList },
});
