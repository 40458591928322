<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Neurology</h2>
      <p>
        There is the provision for diagnosis and treatment of neurological
        diseases under the supervision of a highly experienced consultant.
      </p>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate3.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Neurology Doctors",
      queryName: "Neurology",
      teamName: "Neurology team",
    };
  },
  components: { DoctorsList },
});
</script>
