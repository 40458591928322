
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import AppointementForm from "@/components/Appointment/Template2/Appointment.vue";
import Stats from "@/components/Stats.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
    AppointementForm,
  },
  data() {
    return {
      bgIMG: require("@/assets/img/about-banner.png"),
    };
  },
});
