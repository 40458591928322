<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Emergency Medicine</h2>
      <p>
        Emergency medicine is the medical specialty dedicated <br />to the
        diagnosis and treatment of unforeseen illness or injury.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/emergency-medicine.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          The Emergency Department in our hospital holds a pivotal role in the
          field of healthcare. We provide 24 X 7 service of all emergency
          conditions requiring urgent care. All kinds of acute medical, surgical
          and obstetric conditions are treated here.
        </p>

        <p>
          The department of Emergency Medicine is equipped with the latest
          health devices such as cardiac defibrillators, cardiac monitors,
          intubation kits, ECG, crash carts, 24 X 7 X - ray, Lab facilities etc.
        </p>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Doctors",
      queryName: "Emergency Medicine",
      teamName: "emergency care team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate2.scss";
</style>
