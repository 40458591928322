<template>
  <div class="container mb-5" id="what-we-do">
    <div class="section-title">
      <h2>What We Do Best</h2>
      <p>
        Here is what we excel at. To avail our best in class facilities please
        contact us or come by and experience our exceptional service.
      </p>
    </div>

    <div class="row">
      <div
        class="col-lg-4 col-sm-6 mb-5"
        v-for="({ icon, title, description, link }, i) in items"
        :key="i"
      >
        <div class="what-we-do">
          <img :src="icon" class="img-fluid icon" />
          <h3>{{ title }}</h3>
          <p>{{ description }}</p>
          <router-link class="more-btn" :to="$route.meta.toHome + '/' + link">
            <i class="fa fa-arrow-right"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { WhatWeDoBestDataInterface } from "@/ts/interfaces";
import { whatWeDoBestItems } from "@/static/data";

export default defineComponent({
  data(): WhatWeDoBestDataInterface {
    return {
      items: whatWeDoBestItems,
    };
  },
});
</script>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
}

#what-we-do {
  margin-top: 100px;
}

.more-btn i {
  position: relative;
  bottom: 2px;
}
h3 {
  font-size: 20px;
  font-family: "ProximaNovaBold";
}

.what-we-do {
  box-shadow: 0 8px 16px 0 rgba(146, 184, 255, 0.2);
  background-color: #fff;
  border-bottom: 4px solid #1a5ec3;
  padding: 30px 20px;
  text-align: center;
}

.what-we-do img {
  margin: auto;
  display: table;
}

.what-we-do h3 {
  padding-top: 20px;
}

.what-we-do {
  position: relative;
}

.what-we-do p {
  margin-top: 20px;
  padding-bottom: 50px;
  text-align: center;
}

.what-we-do .more-btn {
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 45px;
  height: 45px;
  line-height: 50px;
  text-align: center;
  box-shadow: 0 0 25px 0 rgb(0 27 103 / 20%);
  border-radius: 50%;
  left: 0;
  margin: auto;
}

.what-we-do .more-btn:hover {
  background: #de0f17;
  color: #fff;
}
</style>
