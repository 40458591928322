<template>
  <Carousel :carouselItems="caroselItems" />
  <WelcomeArea />
  <Appointment />
  <InsuranceList />
  <OurFacilities />
  <Departments />
  <WhatWeDoBest />
  <!-- <Testimonials /> -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { poyanilCarouselItems } from '@/static/data';

import Carousel from '@/components/Carousels/Type1.vue';
import WelcomeArea from '@/components/HospitalsHomePage/Hospital1/Welcome.vue';
import Appointment from '@/components/HospitalsHomePage/Hospital1/Appointment.vue';
import InsuranceList from '@/components/HospitalsHomePage/Hospital1/InsuranceList.vue';
import OurFacilities from '@/components/HospitalsHomePage/Hospital1/OurFacilities.vue';
import Departments from '@/components/HospitalsHomePage/Hospital1/Departments.vue';
import WhatWeDoBest from '@/components/HospitalsHomePage/Hospital1/WhatWeDoBest.vue';
import Testimonials from '@/components/HospitalsHomePage/Hospital1/Testimonials.vue';

export default defineComponent({
  name: 'Template 1',
  components: {
    Carousel,
    WelcomeArea,
    Appointment,
    InsuranceList,
    OurFacilities,
    Departments,
    WhatWeDoBest,
    Testimonials,
  },
  data() {
    return {
      caroselItems: poyanilCarouselItems,
    };
  },
});
</script>
