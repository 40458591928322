<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-7">
        <div class="section-title">
          <h2>Latest News</h2>
          <p>
            Here are the latest news and updates from Poyanil
            Hospital/Providence Hospital
          </p>
        </div>
        <div>
          <Loader v-if="loading" />
          <h3 v-if="!loading && !newsList.length">No news to view</h3>
          <div class="col-lg-12" id="news-section" v-if="newsList.length">
            <div
              class="media"
              v-for="{
                sys: { id, publishedAt },
                headline,
                subHeadline,
                thumbnail: { url },
              } in newsList"
              :key="id"
            >
              <img class="align-self-start mr-3" :src="url" :alt="headline" />
              <div class="media-body">
                <p class="mt-0 ">
                  <strong>
                    {{ headline }}
                  </strong>
                </p>
                <p class="mb-0">
                  <small>{{ getDate(publishedAt) }}</small>
                </p>
                <p class="mb-0">
                  {{ subHeadline }}
                </p>
                <router-link :to="`${$route.meta.toNews}/detail/${id}`">
                  <button type="button" class="btn btn-link news-btn">
                    <strong>Read More</strong>
                  </button>
                </router-link>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>

      <div class="col-lg-5">
        <ContactLinks />

        <Appointment />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { getNewsCollection } from '@/graphql';
import { useQuery } from '@/hooks';
import Loader from '@/components/LoadingAnimation.vue';
import Appointment from '@/components/News/BookAppointement.vue';
import ContactLinks from '@/components/News/ContactLinks.vue';
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';

export default defineComponent({
  data(): NewsListDataInterface {
    return {
      newsList: [],
      loading: true,
    };
  },
  components: {
    Loader,
    Appointment,
    ContactLinks,
  },

  mounted() {
    (async () => {
      const { data, loading, errors } = await useQuery(getNewsCollection, {
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
      });

      this.loading = loading;
      this.newsList = data.newsCollection.items as NewsListInterface[];
    })();
  },
  methods: {
    getDate(datetime: string) {
      let date = new Date(datetime).toString().split(' GMT')[0];
      return date;
    },
  },
});

interface NewsListInterface {
  subHeadline?: string;
  headline?: string;
  sys?: { id: string; publishedAt: string };
  thumbnail?: { url: string };
}

interface NewsListDataInterface {
  loading: boolean;
  newsList: NewsListInterface[];
}
</script>
