<template>
  <div class="container mt-5 mb-5">
    <Loader v-if="loading" />

    <div v-else class="row flex-column-reverse flex-lg-row">
      <div class="col-lg-5">
        <div class="about-image doctors-details">
          <img :src="doctorDetail.profilePic.url" class="img-fluid" />
        </div>
      </div>

      <div class="col-lg-6 offset-lg-1">
        <h2>{{ doctorDetail.name }}</h2>
        <p class="text-red">
          <strong>{{ doctorDetail.centerOfExcellence }}</strong>
        </p>

        <section class="mt-5">
          <div class="media">
            <img
              class="align-self-start mr-3"
              src="@/assets/img/call.svg"
              alt="call-image"
            />
            <div class="media-body">
              <p>
                <span v-for="(num, i) in doctorDetail.contactNumber" :key="i">
                  {{ num }} <br />
                </span>
              </p>
            </div>
          </div>

          <div class="media">
            <img
              class="align-self-start mr-3"
              src="@/assets/img/time.svg"
              alt="time-image"
            />
            <div class="media-body">
              <p style="white-space: pre-line">
                Consultation timings
                <br />
                {{ doctorDetail.consulting }}
              </p>
            </div>
          </div>

          <div class="media" v-if="doctorDetail.email">
            <img
              class="align-self-start mr-3"
              src="@/assets/img/email.svg"
              alt="call-image"
            />
            <div class="media-body mt-3">
              <p>{{ doctorDetail.email }}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <div v-if="!loading" class="container mt-2">
    <div class="row">
      <div class="col-lg-12 mt-3">
        <h3 class="text-blue mb-3">Education</h3>
        <p style="white-space: pre-line">
          {{ doctorDetail.education }}
        </p>
      </div>
    </div>
  </div>

  <div v-if="!loading" class="container mt-2">
    <div class="row" v-if="doctorDetail.skill">
      <div class="col-lg-12 mt-3">
        <h3 class="text-blue mb-3">Skill</h3>
        <p style="white-space: pre-line">
          {{ doctorDetail.skill }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getIndividualDoctorDetails } from '@/graphql';
import { useQuery } from '@/hooks';

import Loader from '@/components/LoadingAnimation.vue';
import {
  DoctorDetailDataInterface,
  DoctorDetailInterface,
} from '@/ts/interfaces';
import { DOCTOR_DETAIL_PAGE_NAMES } from '@/ts/enum';

export default defineComponent({
  data(): DoctorDetailDataInterface {
    return {
      loading: true,
      doctorDetail: {},
    };
  },
  components: {
    Loader,
  },

  mounted() {
    if (this.$route.params?.doctorId) {
      (async () => {
        const { data, loading, errors } = await useQuery(
          getIndividualDoctorDetails,
          {
            id: this.$route.params?.doctorId,
          }
        );

        this.loading = loading;
        if (errors) {
          alert(
            'Something went wrong, - possible reason :' + JSON.stringify(errors)
          );
        }

        this.doctorDetail = data.doctors as DoctorDetailInterface;

        document.title =
          DOCTOR_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL + this.doctorDetail.name;
      })();
    }
  },
});
</script>

<style scoped>
.doctors-details {
  position: relative;
}

.doctors-details::before {
  content: '';
  position: absolute;
  width: 130px;
  height: 5px;
  /* background: #de0f17; */
  border-radius: 10px;
  top: 60px;
}
</style>
