<template>
  <Header
    :bgIMG="bgIMG"
    :isCenter="true"
    :subTitle="'Poyanil Hospital'"
    :title="'Contact Us'"
  />
  <ContactForm />
  <Stats />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import ContactForm from "@/components/ContactUs/screen/Template2/ContactUs.vue";
import Stats from "@/components/Stats.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
    ContactForm,
  },
  data() {
    return {
      bgIMG: require("@/assets/img/about-banner.png"),
    };
  },
});
</script>
