<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>About Us</h2>
      <!-- <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        <br />
        tempor incididunt ut labore et dolore magna aliqua.
      </p> -->
      <p>
        <strong>Providence Hospital</strong> is It is a long established fact
        that a reader will be distracted by the readable content.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-5">
        <div class="about-image">
          <img src="@/assets/img/about-image-hsp1.png" class="img-fluid" />
        </div>
      </div>

      <div class="col-lg-6 offset-lg-1">
        <section>
          <div class="media">
            <img
              class="align-self-start mr-3"
              src="@/assets/img/eye.svg"
              alt="eye-img"
            />
            <div class="media-body">
              <!-- <h5 class="mt-0">Top-aligned media</h5> -->
              <p>
                Providence Hospital Alappuzha ( The Super Speciality Unit Of
                Poyanil Hospitals ) is a multi speciality medical centre serving
                the health care needs of Alappuzha and its adjoining areas.
              </p>
              <p class="thin">
                Our Mission "We Care, We Serve" is a personal promise from each
                of our staff to add our brand of humanity to the practice of
                medical science.
              </p>
              <p>
                Our Mission is to fulfill our patient's needs with a spirit of
                genuine care and concern for their welfare. We follow the
                highest ethical principles and provide our service in a fiscally
                responsible and prudent manner which generates measurable
                benefits for patients we serve. We strive for excellence,
                constantly improving everything we do.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate1.scss";

.about-image {
  position: relative;
}
.about-image::before {
  content: "";
  position: absolute;
  width: 130px;
  height: 5px;
  background: #de0f17;
  border-radius: 10px;
  top: 60px;
}
</style>
