<template>
  <Template>
    <News />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Template from "@/components/Templates/index.vue";
import News from "@/components/News/Template1/index.vue";
import { NEWS_ROUTE_NAMES } from "@/ts/enum";

export default defineComponent({
  name: NEWS_ROUTE_NAMES.NEWS_POYANIL,
  components: {
    News,
    Template,
  },
});
</script>
