
import { defineComponent } from "vue";
import { DOCTORS_ROUTE_NAMES } from "@/ts/enum";

import Template from "@/components/Templates/Kozhencherry.vue";
import Doctors from "@/components/Doctors/Template2/index.vue";

export default defineComponent({
  name: DOCTORS_ROUTE_NAMES.DOCTORS_POYANIL_KOZHENCHERRY,
  components: {
    Doctors,
    Template,
  },
});
