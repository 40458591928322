<template>
  <Header
    :bgIMG="bgIMG"
    :isCenter="true"
    :subTitle="'Poyanil Hospital'"
    :title="'Appointment'"
  />
  <AppointementForm />
  <Stats />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import AppointementForm from "@/components/Appointment/Template3/Appointment.vue";
import Stats from "@/components/Stats.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
    AppointementForm,
  },
  data() {
    return {
      bgIMG: require("@/assets/img/about-banner.png"),
    };
  },
});
</script>
