<template>
  <Template>
    <HomePageScreen />
    <SocialIcons />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MAIN_ROUTING_NAMES } from '@/ts/enum';

import SocialIcons from '@/components/SocialIcons.vue';

import HomePageScreen from '@/components/HospitalsHomePage/Hospital1/index.vue';
import Template from '@/components/Templates/index.vue';

export default defineComponent({
  name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL,
  components: {
    Template,
    HomePageScreen,
    SocialIcons,
  },
});
</script>
