import { Router, useRoute, useRouter } from "vue-router";
import {
  ABOUT_US_ROUTE_NAMES,
  FACILITIES_CHILDREN_ROUTING_PATHS,
  MAIN_ROUTING_NAMES,
  ROUTING_PATHS,
} from "@/ts/enum";
import { mainRouteOptions, aboutUsRouteOptions } from "@/ts/types";
import { OtherHospitalRouterLink } from "./interfaces";
import router from "@/router";

/**
 * @deprecated
 * use meta router to handle dynamic navihation
 * */
export const createDynamicHomeNavigation: () => mainRouteOptions = () => {
  const router: Router = useRouter();

  if (router.currentRoute.value.name === MAIN_ROUTING_NAMES.POYANIL_HOSPITAL) {
    return { name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL };
  }

  if (
    router.currentRoute.value.name ===
    MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY
  ) {
    return {
      name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
    };
  }

  if (
    router.currentRoute.value.name ===
    MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_PUNALUR
  ) {
    return {
      name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_PUNALUR,
    };
  }

  return { name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL };
};

/**
 * @deprecated
 * use meta router
 */
export const createDynamicAboutUsNavigation: () => aboutUsRouteOptions = () => {
  const router: Router = useRouter();

  if (
    router.currentRoute.value.meta.belongsTo ===
    MAIN_ROUTING_NAMES.POYANIL_HOSPITAL
  ) {
    return { name: ABOUT_US_ROUTE_NAMES.ABOUT_US_POYANIL };
  }

  if (
    router.currentRoute.value.meta.belongsTo ===
    ABOUT_US_ROUTE_NAMES.ABOUT_US_KOZHENCHERRY
  ) {
    return {
      name: ABOUT_US_ROUTE_NAMES.ABOUT_US_KOZHENCHERRY,
    };
  }

  if (
    router.currentRoute.value.meta.belongsTo ===
    MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_PUNALUR
  ) {
    return {
      name: ABOUT_US_ROUTE_NAMES.ABOUT_US_PUNALUR,
    };
  }

  return { name: ABOUT_US_ROUTE_NAMES.ABOUT_US_POYANIL };
};

export const otherHospitalsNavList: () => OtherHospitalRouterLink[] = () => {
  if (router.currentRoute.value.meta.toHome === ROUTING_PATHS.TO_POYANIL_HOME) {
    return [
      {
        link: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_HOME,
        name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
      },
      {
        link: ROUTING_PATHS.TO_POYANIL_PUNALUR_HOME,
        name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_PUNALUR,
      },
    ];
  }

  if (
    router.currentRoute.value.meta.toHome ===
    ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_HOME
  ) {
    return [
      {
        link: ROUTING_PATHS.TO_POYANIL_HOME,
        name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL,
      },
      {
        link: ROUTING_PATHS.TO_POYANIL_PUNALUR_HOME,
        name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_PUNALUR,
      },
    ];
  }

  if (
    router.currentRoute.value.meta.toHome ===
    ROUTING_PATHS.TO_POYANIL_PUNALUR_HOME
  ) {
    return [
      {
        link: ROUTING_PATHS.TO_POYANIL_HOME,
        name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL,
      },
      {
        link: ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_HOME,
        name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
      },
    ];
  }

  return [{ link: "/", name: "something went wrong" }];
};

export function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function facilitiesRoute(
  path: FACILITIES_CHILDREN_ROUTING_PATHS
): { name: string; path: string } | undefined {
  const route = useRoute();
  return route.meta.toFacilities.find((each: any) => each.name === path);
}
