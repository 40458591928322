<template>
  <div id="insurance">
    <div class="container">
      <div class="section-title">
        <h2>Insurance</h2>
        <p>
          We accept a wide range of Insurance providers.<br />
          If you use one of the following insurance providers please let us
          know.
        </p>
      </div>
      <div class="row">
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-1.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-13.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-14.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-15.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-16.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-17.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
  text-align: center;
}

#insurance {
  background-color: #f1f1f1;
  padding-top: 60px;
  padding-bottom: 80px;
}
</style>
