
import { defineComponent } from 'vue';

import LandingPage from '@/components/LandingPage/index.vue';
import { LANDING_ROUTE_NAME } from '@/ts/enum';

export default defineComponent({
  name: LANDING_ROUTE_NAME.NAME,
  components: {
    LandingPage,
  },
});
