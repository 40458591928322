<template>
  <Template>
    <HomePageScreen />
    <social-icons />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MAIN_ROUTING_NAMES } from '@/ts/enum';

import HomePageScreen from '@/components/HospitalsHomePage/Hospital2/index.vue';
import Template from '@/components/Templates/Kozhencherry.vue';
import SocialIcons from '@/components/SocialIcons.vue';

export default defineComponent({
  name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
  components: {
    HomePageScreen,
    Template,
    SocialIcons,
  },
});
</script>
