
import { defineComponent } from "vue";

import Template from "@/components/Templates/Kozhencherry.vue";
import Departments from "@/components/Departments/Template2/index.vue";
import { DEPARTMENT_PARENT_ROUTE_NAMES } from "@/ts/enum";

export default defineComponent({
  name: DEPARTMENT_PARENT_ROUTE_NAMES.DEPARTMENT_POYANIL_KOZHENCHERRY,
  components: {
    Template,
    Departments,
  },
});
