
import { defineComponent } from 'vue';
import WelcomeServiceList from '@/components/HospitalsHomePage/common/WelcomeServiceList.vue';

export default defineComponent({
  components: { WelcomeServiceList },
  name: 'Welcome Area for Template 3',
  methods: {
    goToAbtUs(): void {
      if (typeof this.$route.meta.toAboutUs === 'string') {
        this.$router.push(this.$route.meta.toAboutUs);
      }
    },
  },
});
