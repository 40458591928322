<template>
  <div id="insurance">
    <div class="container">
      <div class="section-title">
        <h2>Insurance</h2>
        <p>
          We accept a wide range of Insurance providers.<br />
          If you use one of the following insurance providers please let us
          know.
        </p>
      </div>
      <div class="row">
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-1.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-2.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-3.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-4.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-5.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-6.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-7.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-8.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-9.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-10.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-11.png"
            class="img-fluid"
          />
        </div>
        <div class="col">
          <img
            src="@/assets/img/insurance/insurance-logo-12.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
}
.section-title:after,
.section-title:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 50%;
  left: 10px;
  top: 0;
  background: #de0f17;
  transition: 0.5s;
}

.section-title:after {
  left: 0;
  height: 100%;
}

#facilities,
#departments,
#what-we-do,
#testimonials {
  margin-top: 100px;
}

#departments,
#testimonials {
  background-color: #f1f1f1;
  padding-top: 60px;
  padding-bottom: 80px;
}

#departments .departments {
  background-color: #fff;
  padding: 30px 20px;
  border: dashed 1px #eeeeee;
  text-align: center;
}
#insurance {
  background-color: #f1f1f1;
  padding-top: 60px;
  padding-bottom: 80px;
}
</style>
