<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Urology</h2>
      <p>
        Urology also known as genitourinary surgery, is the branch of medicine
        that focuses on surgical and medical diseases of the male and female
        urinary-tract system and the male reproductive organs.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/urology.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          The department of urology at providence hospital is devoted to
          clinical excellence in urology with cutting edge techniques and state
          of the art equipment. We strive to serve our patients with best
          possible care and experience within all sections of our department. We
          are equipped to handle all major pediatric and non-pediatric
          urological problems.
        </p>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Urology Doctors",
      queryName: "Urology",
      teamName: "Urology team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate2.scss";
</style>
