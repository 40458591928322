<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Pediatrics</h2>
      <p>
        Pediatrics is the branch of medicine that involves the medical care of
        infants, children, and adolescents.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/pediatrics.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          The department offers treatment for children with simple problems to
          very complex diseases. Treatment for diseases like long standing
          undiagnosed fevers, developmental problems, asthma, respiratory
          problems, bowel problems like constipation, heart diseases and
          intensive care for newborn are offered here.
        </p>

        <p>
          We also have immunization facility available six days a week. Breast
          feeding support and proper weaning advice is also given. Charting of
          growth & development of babies is also done here.
        </p>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate1.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Pediatrics Doctors",
      queryName: "Pediatrics",
      teamName: "Pediatrics team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate1.scss";
</style>
