<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Pulmonology</h2>
      <p>
        Pediatrics is the branch of medicine that involves the medical care of
        infants, children, and adolescents.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/pulmonology.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          Pulmonology today is an emerging branch of medical science.<br />
          There is an ever increasing number of respiratory diseases seen world
          over in the past few years.
        </p>

        <p>
          Bronchial asthma, COPD ( chronic obstructive pulmonary disease),
          emphysema and sleep apnea being the common entities seen these days.
        </p>
        <p>
          Various diagnostic tools are used to diagnose and optimally treat
          these diseases:- <br />
          Pulmonary function tests ( PFT)<br />
          Spirometry <br />
          Blood gas analysis etc.
        </p>
        <p>
          Allergy induced conditions can be diagnosed and treated by identifying
          triggering agents by skin tests and stabilising the immune response.
        </p>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate3.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Pulmonology Doctors",
      queryName: "Pulmonology",
      teamName: "Pulmonology team",
    };
  },
  components: { DoctorsList },
});
</script>
