import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const getIndividualDoctorDetails: DocumentNode = gql`
  query($id: String!) {
    doctors(id: $id) {
      name
      profilePic {
        url
      }
      consulting
      centerOfExcellence
      contactNumber
      email
      education
      skill
    }
  }
`;

export const getListOfDoctors: DocumentNode = gql`
  query DoctorsList($hospital: String!, $limit: Int!) {
    doctorsCollection(where: { hospital: $hospital }, limit: $limit) {
      total
      items {
        sys {
          id
        }
        name
        profilePic {
          url
        }
        centerOfExcellence
      }
    }
  }
`;

export const getNewsCollection: DocumentNode = gql`
  query($hospital: String!) {
    newsCollection(
      where: { hospitalName: $hospital }
      order: sys_publishedAt_DESC
    ) {
      items {
        sys {
          id
          publishedAt
        }
        headline
        subHeadline
        thumbnail {
          url
        }
      }
    }
  }
`;

export const getNewsDetails: DocumentNode = gql`
  query NewsDetail($id: String!) {
    news(id: $id) {
      sys {
        publishedAt
      }
      headline
      subHeadline
      content {
        json
        links {
          assets {
            block {
              url
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const getCarrersList: DocumentNode = gql`
  query CareerCollection($hospital: String!) {
    careersCollection(
      where: { hospital: $hospital }
      order: sys_publishedAt_DESC
    ) {
      total
      items {
        sys {
          id
        }
        jobTitle
        jobShortDescription
      }
    }
  }
`;

export const getCareerDetail: DocumentNode = gql`
  query CareerDetail($id: String!) {
    careers(id: $id) {
      jobTitle
      jobShortDescription
      numberOfVacancy
      jobDescription {
        json
      }
      responsibilities
      skills
      experience
    }
  }
`;

export const getTestimonial: DocumentNode = gql`
  query Testimonials($hospital: String!) {
    testimonialsCollection(where: { hospital: $hospital }, order: sys_id_DESC) {
      items {
        sys {
          id
        }
        video {
          url
          contentType
        }
        shortReview {
          json
        }
        reviewPoster {
          url
        }
        testimonialContent {
          json
        }
      }
    }
  }
`;

export const getDoctorsListBasedOnDepartment: DocumentNode = gql`
  query DoctorsListForDepartment(
    $hospital: String!
    $department: String!
    $limit: Int!
    $skip: Int!
  ) {
    doctorsListingForParticularDepartmentCollection(
      where: { hospital: $hospital, departments: $department }
      limit: $limit
      skip: $skip
    ) {
      items {
        doctorCollection {
          items {
            sys {
              id
            }
            name
            email
            centerOfExcellence
            profilePic {
              url
            }
          }
        }
      }
    }
  }
`;

export const getDepartmentsList: DocumentNode = gql`
  query DepartmentList($hospital: String!) {
    doctorsListingForParticularDepartmentCollection(
      where: { hospital: $hospital, doctorCollection_exists: true }
    ) {
      items {
        sys {
          id
        }
        departments
      }
    }
  }
`;

export const getDoctorsListBasedOfDepartmentSelected: DocumentNode = gql`
  query GetDoctorsBasedOnDeptID($deptId: String!) {
    doctorsListingForParticularDepartment(id: $deptId) {
      doctorCollection {
        items {
          name
          sys {
            id
          }
        }
      }
    }
  }
`;

export const getTestimonialDetail: DocumentNode = gql`
  query TestimonialDetail($id: String!) {
    testimonials(id: $id) {
      reviewer
      video {
        url
        contentType
      }
      testimonialContent {
        json
        links {
          assets {
            block {
              url
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`;
