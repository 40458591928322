
import { defineComponent } from "vue";

import Template from "@/components/Templates/Punalur.vue";
import News from "@/components/News/Template3/index.vue";
import { NEWS_ROUTE_NAMES } from "@/ts/enum";

export default defineComponent({
  name: NEWS_ROUTE_NAMES.NEWS_POYANIL_PUNALUR,
  components: {
    News,
    Template,
  },
});
