<template>
  <Layout
    :headerTitle="headerTitle"
    :sinceYear="sinceYear"
    :imgSub="imgSub"
    :logoIco="logoIco"
    footerColor="white"
    :showFooterLogo="false"
  >
    <slot></slot>
    <ContactUs />
  </Layout>
  <AppointmentFloatIcon />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { PoyanilHospitalPunalurDataInterface } from '@/ts/interfaces';

import Layout from '@/components/Layout.vue';
import ContactUs from '@/components/ContactUs/Template3.vue';
import AppointmentFloatIcon from '@/components/AppointmentFlatIcon.vue';

export default defineComponent({
  components: {
    Layout,
    ContactUs,
    AppointmentFloatIcon,
  },
  data(): PoyanilHospitalPunalurDataInterface {
    return {
      headerTitle: 'Serving Punalur',
      sinceYear: 2006,
      imgSub: true,
      logoIco: require('@/assets/img/logo-2.png'),
    };
  },
});
</script>
