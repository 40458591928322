<template>
  <Template>
    <CareersDetail />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Template from "@/components/Templates/Kozhencherry.vue";
import CareersDetail from "@/components/CareerDetail/Template2/index.vue";

export default defineComponent({
  components: {
    CareersDetail,
    Template,
  },
});
</script>
