<template>
  <section class="director-message mt-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xs-12">
          <div class="about-left">
            <img
              src="@/assets/img/director.png"
              class="img-responsive"
              alt=""
              style="min-height:430px;max-width:100%"
            />
            <div class="label director-labelling">
              <div class="content">
                <h3 class="text-white">Dr Joseph George</h3>
                <p>Managing Director</p>
              </div>
            </div>
          </div>
        </div>
        <!-- /col-6 -->
        <div class="col-lg-6 col-xs-12">
          <div class="about-right-content">
            <h2>Managing Director's Message</h2>
            <p>
              We at Poyanil Hospitals follow the highest ethical principles and
              provide our services in a fiscally responsible and prudent manner
              which generates measurable benefits for the patients we serve. We
              strive for excellence constantly improving everything we do. We
              believe that the care and satisfaction of every patient is our
              priority and hence we seek to achieve this goal by having a team
              of committed doctors and staff, renowned for compassion and
              excellence, putting the patient first. We believe in strong
              ethical standards and expect everyone to practice it with vigour.
            </p>
          </div>
        </div>
        <!-- /col-6 -->
      </div>
      <!-- /row -->
    </div>
  </section>

  <section class="superintendent-message mt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xs-12">
          <div class="about-left">
            <img
              src="@/assets/img/superintendent.png"
              class="img-responsive"
              alt=""
              style="min-height:430px;max-width:100%"
            />
            <div class="label director-labelling">
              <div class="content">
                <h3 class="text-white">Dr Madhavan G Menon</h3>
                <p>Medical Superintendent & Consultant Neuro Surgeon</p>
              </div>
            </div>
          </div>
        </div>
        <!-- /col-6 -->
        <div class="col-lg-6 col-xs-12">
          <div class="about-right-content">
            <h2>Medical Superintendent's Message</h2>
            <p>
              We the team of doctors, administrative, paramedical and associated
              staff at Providence Hospital ( The Super speciality unit of
              Poyanil Hospitals ) stand committed to give the best advanced
              health care facility under one roof at reasonable cost for all, in
              and around Alappuzha as well as beyond. We believe that the care
              and satisfaction of every patient is our priority and hence we
              seek to achieve this goal by having a team of committed doctors
              and staff, renowned for compassion and excellence, putting the
              patient first. We believe in strong ethical standards and expect
              everyone to practice it with vigour.
            </p>
          </div>
        </div>
        <!-- /col-6 -->
      </div>
      <!-- /row -->
    </div>
  </section>
</template>

<style scoped>
.director-message {
  background-color: #f1f1f1;
  padding-bottom: 130px;
  padding-top: 120px;
}

.director-message h3 {
  font-family: 'ProximaNovaRegular';
}

.about-left {
  position: relative;
  z-index: 1;
}

.about-left::before {
  top: 40px;
  left: -70px;
  height: 100%;
}

.about-left::before,
.about-left::after {
  position: absolute;
  content: '';
  width: 400px;
  background: rgba(26, 94, 195, 0.1);
  z-index: -1;
}

.director-message .label,
.superintendent-message .label {
  position: absolute;
  width: 480px;
  height: 110px;
  background: #1a5ec3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  right: 30px;
  bottom: 30px;
}
.director-message .label p,
.superintendent-message .label p {
  color: rgba(255, 255, 255, 0.5);
}

.about-left::after {
  right: 30px;
  bottom: 0;
  height: 438px;
}

.about-right-content p {
  margin-top: 3rem;
}

.img-responsive {
  display: block;
  max-width: 80%;
  height: auto;
}
</style>
