<template>
  <Header
    :bgIMG="bgImg"
    :isCenter="true"
    :subTitle="'Poyanil Hospital'"
    :title="'Departments'"
  />
  <slot></slot>
  <Stats />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import Stats from "@/components/Stats.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
  },
  data() {
    return {
      bgImg: require("@/assets/img/anesthesiology-banner.png"),
    };
  },
});
</script>
