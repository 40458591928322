<template>
  <div id="floating-button">
    <a
      v-if="whatsApp"
      :href="
        `https://api.whatsapp.com/send?phone=+91${whatsApp}&text=Hello%2C%20I%27m%20here%20about...`
      "
      target="_blank"
    >
      <p><i class="fab fa-whatsapp"></i></p
    ></a>
    <a :href="fbLink">
      <p><i class="fab fa-facebook-f"></i></p
    ></a>
    <a :href="'tel:' + contactNumber">
      <p><i class="fas fa-phone-alt"></i></p
    ></a>
    <a :href="'email:' + email">
      <p><i class="far fa-envelope"></i></p
    ></a>
  </div>
</template>

<script lang="ts">
import { ROUTING_PATHS } from '@/ts/enum';
import { SocialLinks } from '@/ts/interfaces';
import { defineComponent } from 'vue';
import { ALAPPUZHA_HSP_SOCIAL_LINKS } from '@/static/data';
import {
  KOZHENCHERRY_HSP_SOCIAL_LINKS,
  PUNALUR_HSP_SOCIAL_LINKS,
} from '@/static/data/hospitalsSocialLinks';

interface SocialIconsData extends SocialLinks {}

export default defineComponent({
  data(): SocialIconsData {
    return {
      whatsApp: 9656986668,
      email: 'providencepoyanil@gmail.com',
      contactNumber: '0468-2210600',
      fbLink:
        'https://m.facebook.com/providencehospitalalappuzha/?tsid=0.7830931174725102&source=result',
    };
  },
  methods: {
    handleLinks() {
      if (this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_HOME) {
        this.whatsApp = ALAPPUZHA_HSP_SOCIAL_LINKS.whatsApp;
        this.email = ALAPPUZHA_HSP_SOCIAL_LINKS.email;
        this.contactNumber = ALAPPUZHA_HSP_SOCIAL_LINKS.contactNumber;
        this.fbLink = ALAPPUZHA_HSP_SOCIAL_LINKS.fbLink;
      }
      if (
        this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_HOME
      ) {
        this.whatsApp = KOZHENCHERRY_HSP_SOCIAL_LINKS.whatsApp;
        this.email = KOZHENCHERRY_HSP_SOCIAL_LINKS.email;
        this.contactNumber = KOZHENCHERRY_HSP_SOCIAL_LINKS.contactNumber;
        this.fbLink = KOZHENCHERRY_HSP_SOCIAL_LINKS.fbLink;
      }

      if (this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_PUNALUR_HOME) {
        this.whatsApp = PUNALUR_HSP_SOCIAL_LINKS.whatsApp;
        this.email = PUNALUR_HSP_SOCIAL_LINKS.email;
        this.contactNumber = PUNALUR_HSP_SOCIAL_LINKS.contactNumber;
        this.fbLink = PUNALUR_HSP_SOCIAL_LINKS.fbLink;
      }
    },
  },
  mounted() {
    if (this.$route.meta.toHome) {
      this.handleLinks();
    }
  },

  watch: {
    $route(to, from) {
      if (this.$route.meta.toHome) {
        this.handleLinks();
      }
    },
  },
});
</script>
