<template>
  <Loader v-if="loading" />
  <div class="container mt-5" v-else>
    <div class="section-title">
      <h2 v-if="careerDetail.numberOfVacancy">
        {{ careerDetail.jobTitle }} ( {{ careerDetail.numberOfVacancy }}
        {{ careerDetail.numberOfVacancy > 1 ? 'Vacancies' : 'Vacancy' }} )
      </h2>
      <h2 v-else>No Vacancy</h2>
      <p>
        {{ careerDetail.jobShortDescription }}
      </p>
    </div>

    <div
      v-html="documentToHtmlString(careerDetail.jobDescription.json)"
      class="col-lg-12 mt-5"
    ></div>

    <div class="col-lg-12 mt-5">
      <h3>Responsibilities</h3>
      <ul class="list-unstyled mt-4">
        <li
          class="media mb-1"
          v-for="(resp, i) in careerDetail.responsibilities"
          :key="i"
        >
          <i class="far fa-check-circle text-red mt-1 mr-2"></i>
          <div class="media-body">
            <p class="mt-0 mb-1">
              {{ resp }}
            </p>
          </div>
        </li>
      </ul>
    </div>

    <div class="col-lg-12 mt-5">
      <h3>Skills</h3>
      <ul class="list-unstyled mt-4">
        <li
          class="media mb-1"
          v-for="(skill, i) in careerDetail.skills"
          :key="i"
        >
          <i class="far fa-check-circle text-red mt-1 mr-2"></i>
          <div class="media-body">
            <p class="mt-0 mb-1">
              {{ skill }}
            </p>
          </div>
        </li>
      </ul>
    </div>

    <div class="col-lg-12 mt-5" :v-if="careerDetail.experience">
      <h3>Experience</h3>
      <p class="mt-4">{{ careerDetail.experience }}</p>
    </div>

    <div class="col-lg-12 mt-5">
      <div
        v-if="!showJobApplication"
        @click="jobApplyStatus"
        class="btn btn-primary read-more-btn"
      >
        {{ applied ? 'Applied' : 'Apply Now' }}
      </div>
      <JobApplicationForm
        v-else
        :setShowApplication="jobApplyStatus"
        :setAppliedStatus="setAppliedStatus"
        :hospital="3"
        :careerName="careerDetail.jobTitle"
      />
      <!-- Apply For Job -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Loader from '@/components/LoadingAnimation.vue';
import { useQuery } from '@/hooks';
import { getCareerDetail } from '@/graphql';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  CareerDetailDataInterface,
  CareerDetailInterface,
} from '@/ts/interfaces';
import { CAREER_DETAIL_PAGE_NAMES } from '@/ts/enum';
import JobApplicationForm from '@/components/Job/index.vue';

export default defineComponent({
  components: { Loader, JobApplicationForm },
  data(): CareerDetailDataInterface {
    return {
      loading: true,
      careerDetail: {},
      showJobApplication: false,
      applied: false,
    };
  },
  methods: {
    documentToHtmlString,
    jobApplyStatus(): void {
      this.showJobApplication = !this.showJobApplication;
    },
    setAppliedStatus() {
      this.applied = true;
    },
  },
  mounted() {
    if (this.$route.params.careerId) {
      (async () => {
        const { data, loading, errors } = await useQuery(getCareerDetail, {
          id: this.$route.params.careerId,
        });

        this.loading = loading;

        this.careerDetail = data.careers as CareerDetailInterface;
        document.title =
          CAREER_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_PUNALUR +
          this.careerDetail.jobTitle;
      })();
    }
  },
});
</script>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
  text-align: center;
}

.read-more-btn {
  background: #fff;
  border-color: #1a5ec3;
  border-width: 2px;
  color: #1a5ec3;
  padding: 12px 30px 12px 30px;
  text-transform: uppercase;
  transition: 0.5s;
}
.read-more-btn:hover {
  background: #1a5ec3;
  border-color: #1a5ec3;
  transform: translateY(-5px);
  color: #fff;
}
</style>
