<template>
  <Header
    :bgIMG="bgIMG"
    :isCenter="true"
    :subTitle="'Poyanil Hospital'"
    :title="'News'"
  />
  <NewsDetails />
  <Stats />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import Stats from "@/components/Stats.vue";
import NewsDetails from "@/components/NewsDetails/Template3/Detail.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
    NewsDetails,
  },
  data() {
    return {
      bgIMG: require("@/assets/img/doctors-banner.png"),
    };
  },
});
</script>
