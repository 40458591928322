<template>
  <Header
    :bgIMG="bgImg"
    :isCenter="false"
    :subTitle="'Providence Hospital'"
    :title="'News'"
  />
  <Insurance />
  <NewsList />
  <Stats />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import Stats from "@/components/Stats.vue";
import Insurance from "@/components/HospitalsHomePage/Hospital2/InsuranceList.vue";
import NewsList from "@/components/News/Template2/NewsList.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
    Insurance,
    NewsList,
  },
  data() {
    return {
      bgImg: require("@/assets/img/doctors-banner.png"),
    };
  },
});
</script>
