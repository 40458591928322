<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Cardiology</h2>
      <p>
        The department of cardiology at Poyanil hospital offers 24 X 7 cardiac
        care facilities. The hospital is equipped with modern machines for
        conducting echocardiogram, treadmill tests and holter study. The
        intensive care unit is competent to take care of critically ill
        patients.
      </p>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Cardiology Doctors",
      queryName: "Cardiology",
      teamName: "Cardiology team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate2.scss";
</style>
