<template>
  <div class="container mt-5">
    <div class="row ">
      <div class="col-lg-12">
        <section class="ms-header">
          <h2 class="ms-header__title">
            Poyanil Hospital
            <div class="ms-slider">
              <ul class="ms-slider__words">
                <li class="ms-slider__word">24 Hour Clinic</li>
                <li class="ms-slider__word">Awesome Facilities</li>
                <li class="ms-slider__word">Good Departments</li>
                <li class="ms-slider__word">24 Hour Clinic</li>
              </ul>
            </div>
          </h2>
        </section>
      </div>

      <div class="col-lg-6">
        <p>
          Poyanil Hospital Punelur has been the best source of quality
          healthcare to the people of Punelur since 1999. Being the primary
          source of private healthcare in town, we take our job very seriously.
          Which is why we are the highest rated hospital in the area. For the
          past 20+ years we have been the primary source of affordable, quality
          healthcare to the people in Punelur. We Care about Punelur, So We
          Serve Punelur.
        </p>

        <div class="row mt-5">
          <div class="col-lg-8 col-8">
            <ul class="list-unstyled">
              <welcome-service-list />
            </ul>
          </div>
          <div class="col-lg-4 col-4">
            <div class="branding">
              <img src="@/assets/img/logo-2.png" class="img-fluid" />
            </div>
          </div>
        </div>

        <div @click="goToAbtUs" class="btn btn-primary read-more-btn">
          Read More
        </div>
      </div>

      <div class="col-lg-6">
        <div class="welcome-image">
          <img src="@/assets/img/welcome-image.png" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import WelcomeServiceList from '@/components/HospitalsHomePage/common/WelcomeServiceList.vue';

export default defineComponent({
  components: { WelcomeServiceList },
  name: 'Welcome Area for Template 3',
  methods: {
    goToAbtUs(): void {
      if (typeof this.$route.meta.toAboutUs === 'string') {
        this.$router.push(this.$route.meta.toAboutUs);
      }
    },
  },
});
</script>

<style scoped>
.ms-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.ms-header__title {
  flex: 1 1 100%;
  width: 100%;
  text-align: left;
  font-size: 35px;
  font-weight: bold;
  color: #1a5ec3;
}

.ms-slider {
  display: inline-block;
  height: 1.5em;
  overflow: hidden;
  vertical-align: middle;
  /*  -webkit-mask-image: linear-gradient(transparent, white, white, white, transparent);
          mask-image: linear-gradient(transparent, white, white, white, transparent);
  mask-type: luminance;
  mask-mode: alpha;*/
}
.ms-slider__words {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-animation-name: wordSlider;
  animation-name: wordSlider;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
}
.ms-slider__word {
  display: block;
  line-height: 1.5em;
  text-align: left;
  background-color: #de0f17;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

@-webkit-keyframes wordSlider {
  0%,
  27% {
    transform: translateY(0%);
  }
  33%,
  60% {
    transform: translateY(-25%);
  }
  66%,
  93% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(-75%);
  }
}

@keyframes wordSlider {
  0%,
  27% {
    transform: translateY(0%);
  }
  33%,
  60% {
    transform: translateY(-25%);
  }
  66%,
  93% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(-75%);
  }
}

.welcome-image img {
  border-top-right-radius: 30px;
}
.welcome-image::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 80%;
  background: #de0f17;
  left: 10px;
  bottom: 0;
  z-index: -1;
}
.welcome-image::after {
  content: '';
  position: absolute;
  width: 80%;
  height: 1%;
  background: #de0f17;
  left: 15px;
  bottom: 0;
}

/* .branding {
  background: #fff;
  border-radius: 85px;
  height: 150px;
  width: 150px;
  -webkit-box-shadow: 1px 1px 6px 0px rgba(50, 50, 50, 0.3);
  -moz-box-shadow: 1px 1px 6px 0px rgba(50, 50, 50, 0.3);
  box-shadow: 1px 1px 6px 0px rgba(50, 50, 50, 0.3);
}

.branding img {
  width: 85%;
  margin: auto;
  display: inherit;
  padding-top: 14px;
} */

.read-more-btn,
.contact-btn {
  background: #1a5ec3;
  border-color: #1a5ec3;
  border-width: 2px;
  color: #fff;
  padding: 12px 30px 12px 30px;
  text-transform: uppercase;
  transition: 0.5s;
}

.read-more-btn:hover,
.contact-btn:hover {
  background: #de0f17;
  border-color: #de0f17;
  transform: translateY(-5px);
}
</style>
