
import { defineComponent } from 'vue';

import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import Appointment from '@/components/News/BookAppointement.vue';
import ContactLinks from '@/components/News/ContactLinks.vue';
import { useQuery } from '@/hooks';
import { getNewsDetails } from '@/graphql';
import Loader from '@/components/LoadingAnimation.vue';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { NEWS_DETAIL_PAGE_NAMES } from '@/ts/enum';

export default defineComponent({
  components: {
    Appointment,
    ContactLinks,
    Loader,
  },
  methods: {
    documentToHtmlString,
  },
  data() {
    return {
      newsDetail: {},
      loading: true,
      options: {
        renderMark: {
          [MARKS.BOLD]: (text: string) => `<custom-bold>${text}<custom-bold>`,
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node: any, next: any) =>
            `<custom-paragraph>${next(node.content)}</custom-paragraph>`,
          [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const { content } = this.newsDetail as any;

            const img = content.links.assets.block.find(
              (entries: any) => entries.sys.id === node.data.target.sys.id
            );

            return `
            <div>
            <img
                style="width:100%"
               src=${img?.url}
               alt='content-img'
             />
            </div>
             `;
          },
        },
      },
    };
  },

  mounted() {
    if (this.$route.params.newsId) {
      (async () => {
        const { data, loading, errors } = await useQuery(getNewsDetails, {
          id: this.$route.params.newsId,
        });

        this.loading = loading;
        this.newsDetail = data?.news;

        document.title =
          NEWS_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL + data.news.headline;
      })();
    }
  },
});
