
import { defineComponent } from 'vue';
import DoctorsList from '@/components/Departments/common/DoctorsListTemplate2.vue';
import { DepartmentPropDataInterface } from '@/ts/interfaces';

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: 'ENT [EAR,NOSE,AND THROAT.] Doctors',
      queryName: 'E N T',
      teamName: 'ENT [EAR,NOSE,AND THROAT.] team',
    };
  },
  components: { DoctorsList },
});
