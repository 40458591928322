<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Dental</h2>
      <p>
        Comprehensive dental care including Periodontics, prosthodontics and
        Endodontics for both adults and children. Personalised high quality
        dental care following international standards and protocols. Our Dental
        department also closely works with maxillo facial surgeons.
      </p>
    </div>
    <div class="col-lg-8">
      <p><strong>Services Available:</strong></p>

      <div class="row">
        <div class="col-lg-6">
          <ul>
            <li>Dental Implants</li>
            <li>24 Hour Dental Theatre</li>
            <li>High Risk Dental Care</li>
            <li>Root Canal Treatment</li>
            <li>Orthodontics</li>
            <li>Paediatric Dentistry</li>
            <li>Tooth Coloring, Filling and Crowning</li>
          </ul>
        </div>

        <div class="col-lg-6">
          <ul>
            <li>Periodontal Flap Surgery</li>
            <li>Dental Bridge</li>
            <li>Removal Partial Dentures</li>
            <li>Dental Restoration</li>
            <li>Implants</li>
            <li>Tooth Extraction</li>
            <li>Surgical Removal of Impacted Tooth</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate3.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Dental Doctors",
      queryName: "Dental",
      teamName: "Dental team",
    };
  },
  components: { DoctorsList },
});
</script>
