<template>
  <section
    class="header-inner bg-overlay"
    :style="{ 'background-image': 'url(' + bgIMG + ')' }"
  >
    <div class="container">
      <div class="row d-flex justify-content-start">
        <div class="col-md-8" :class="{ 'm-auto': isCenter }">
          <div class="header-inner-title" :class="{ 'text-center': isCenter }">
            <h2 class="text-white">{{ title }}</h2>
            <h1 class="text-white mb-0">{{ subTitle }}</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    bgIMG: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    isCenter: {
      type: Boolean,
      required: true,
    },
  },
});
</script>

<style scoped>
.header-inner {
  padding: 190px 0px;
  background-size: cover;
  background-position: center center;
}

.bg-overlay {
  position: relative;
}

.bg-overlay::before {
  background: rgba(2, 45, 98, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
</style>
