<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>General Medicine</h2>
      <p>
        A general physician, or GP, is a medical doctor who specialises in many
        <br />
        diseases affecting the body, whose primary treatment does not involve
        surgery.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/general-medicine.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          The Department of medicine is prepared to diagnose and manage patients
          with common and emergency medical conditions and are able to do so
          when the individual has multiple conditions with limited access to
          other subspecialists.
        </p>
        <p>
          We provide comprehensive care of the adult patient in an integrated
          manner as opposed to an organ-centric or disease centric approach. We
          take an active role in disease prevention and risk factor reduction.
        </p>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "General Medicine Doctors",
      queryName: "General Medicine",
      teamName: "General Medicine team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate2.scss";
</style>
