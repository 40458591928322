<template>
  <Template>
    <Appointment />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Template from '@/components/Templates/Kozhencherry.vue';
import Appointment from '@/components/Appointment/Template2/index.vue';
import { APPOINTMENT_ROUTE_NAME } from '@/ts/enum';

export default defineComponent({
  name: APPOINTMENT_ROUTE_NAME.APPOINTMENT_POYANIL_KOZHENCHERRY,
  components: {
    Appointment,
    Template,
  },
});
</script>
