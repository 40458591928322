
import { defineComponent } from 'vue';
import { PoyanilHospitalDataInterface } from '@/ts/interfaces';

import Layout from '@/components/Layout.vue';
import ContactUs from '@/components/ContactUs/Template1.vue';
import AppointmentFloatIcon from '@/components/AppointmentFlatIcon.vue';

export default defineComponent({
  components: {
    Layout,
    ContactUs,
    AppointmentFloatIcon,
  },
  data(): PoyanilHospitalDataInterface {
    return {
      headerTitle: 'A Unit Of Poyanil Hospital',
      sinceYear: 1988,
      logoIco: require('@/assets/img/logo.png'),
      imgSub: false,
    };
  },
});
