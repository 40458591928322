import { ApolloClient } from "apollo-client";
import { createHttpLink, FetchOptions } from "apollo-link-http";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";

const SPACE: string = process.env.VUE_APP_GRAPHQL_SPACE_ID || "";
const TOKEN: string = process.env.VUE_APP_GRAPHQL_API_ACCESS_TOKEN || "";
const CONTENTFUL_URL: string = `https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/master?access_token=${TOKEN}`;

const options: FetchOptions = { uri: CONTENTFUL_URL };

const httpLink: ApolloLink = createHttpLink(options);

const cache: InMemoryCache = new InMemoryCache();

export const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient(
  {
    link: httpLink,
    cache,
  }
);
