import {
  FACILITIES_CHILDREN_ROUTING_PATHS,
  FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES,
  FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES,
  FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES,
} from "@/ts/enum";
import { RouteRecordRaw } from "vue-router";

import ContentDisplay from "@/components/Facilities/Template1/ContentDisplay.vue";
import ContentDisplayKozhencherry from "@/components/Facilities/Template2/ContentDisplay.vue";
import ContentDisplayPunalur from "@/components/Facilities/Template3/ContentDisplay.vue";

export const facilitiesChildrenRoute: RouteRecordRaw[] | undefined = [
  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CATHLAB,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CATHLAB,
  },
  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_LABORATORY,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CLINICAL_LABORATORY,
  },

  {
    path:
      FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
    component: ContentDisplay,
    name:
      FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CLINIC_24_x_7,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CLINIC_24_x_7,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CSSD,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.CSSD,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.DIALYSIS,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.DIALYSIS,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.EMERGENCY_OR_CASUALITY,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.EMERGENCY_OR_CASUALITY,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.ICU,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.ICU,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.IN_PATIENT_DEPARTMENT,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.IN_PATIENT_DEPARTMENT,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.OPERATION_THEATERS,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.OPERATION_THEATERS,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.OUT_PATIENT_DEPARTMENT,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.OUT_PATIENT_DEPARTMENT,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.PARAMEDICAL_SUPPORT,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.PARAMEDICAL_SUPPORT,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.PHARMACY_24_x_7,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.PHARMACY_24_x_7,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.POWER_BACKUP,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.POWER_BACKUP,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.PREVENTIVE_HEALTH_CHECKUP,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.PREVENTIVE_HEALTH_CHECKUP,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.RADIOLOGY_IMAGING,
    component: ContentDisplay,
    name: FACILITIES_POYANIL_CHILDREN_ROUTE_NAMES.RADIOLOGY_IMAGING,
  },
];

export const facilitiesKozhencherryChildrenRoute:
  | RouteRecordRaw[]
  | undefined = [
  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CATHLAB,
    component: ContentDisplayKozhencherry,
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.CATHLAB,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_LABORATORY,
    component: ContentDisplayKozhencherry,
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.CLINICAL_LABORATORY,
  },

  {
    path:
      FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
    component: ContentDisplayKozhencherry,
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CLINIC_24_x_7,
    component: ContentDisplayKozhencherry,
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.CLINIC_24_x_7,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CSSD,
    component: ContentDisplayKozhencherry,
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.CSSD,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.BLOOD_BANK,
    component: ContentDisplayKozhencherry,
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.BLOOD_BANK,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.PHYSIOTHERAPY,
    component: ContentDisplayKozhencherry,
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PHYSIOTHERAPY,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.DIALYSIS,
    component: ContentDisplayKozhencherry,
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.DIALYSIS,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.EMERGENCY_OR_CASUALITY,
    component: ContentDisplayKozhencherry,
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.EMERGENCY_OR_CASUALITY,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.ICU,
    component: ContentDisplayKozhencherry,
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.ICU,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.IN_PATIENT_DEPARTMENT,
    component: ContentDisplayKozhencherry,
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.IN_PATIENT_DEPARTMENT,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.OPERATION_THEATERS,
    component: ContentDisplayKozhencherry,
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.OPERATION_THEATERS,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.OUT_PATIENT_DEPARTMENT,
    component: ContentDisplayKozhencherry,
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.OUT_PATIENT_DEPARTMENT,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.PARAMEDICAL_SUPPORT,
    component: ContentDisplayKozhencherry,
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PARAMEDICAL_SUPPORT,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.PHARMACY_24_x_7,
    component: ContentDisplayKozhencherry,
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PHARMACY_24_x_7,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.POWER_BACKUP,
    component: ContentDisplayKozhencherry,
    name: FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.POWER_BACKUP,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.PREVENTIVE_HEALTH_CHECKUP,
    component: ContentDisplayKozhencherry,
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PREVENTIVE_HEALTH_CHECKUP,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.RADIOLOGY_IMAGING,
    component: ContentDisplayKozhencherry,
    name:
      FACILITIES_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.RADIOLOGY_IMAGING,
  },
];

export const facilitiesPunalurChildrenRoute: RouteRecordRaw[] | undefined = [
  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CATHLAB,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.CATHLAB,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_LABORATORY,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.CLINICAL_LABORATORY,
  },

  {
    path:
      FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
    component: ContentDisplayPunalur,
    name:
      FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.CLINICAL_PHYSIOTHERAPY_STARTING_SOON,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CLINIC_24_x_7,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.CLINIC_24_x_7,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.CSSD,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.CSSD,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.DIALYSIS,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.DIALYSIS,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.EMERGENCY_OR_CASUALITY,
    component: ContentDisplayPunalur,
    name:
      FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.EMERGENCY_OR_CASUALITY,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.ICU,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.ICU,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.IN_PATIENT_DEPARTMENT,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.IN_PATIENT_DEPARTMENT,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.OPERATION_THEATERS,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.OPERATION_THEATERS,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.OUT_PATIENT_DEPARTMENT,
    component: ContentDisplayPunalur,
    name:
      FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.OUT_PATIENT_DEPARTMENT,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.PARAMEDICAL_SUPPORT,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.PARAMEDICAL_SUPPORT,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.PHARMACY_24_x_7,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.PHARMACY_24_x_7,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.POWER_BACKUP,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.POWER_BACKUP,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.PREVENTIVE_HEALTH_CHECKUP,
    component: ContentDisplayPunalur,
    name:
      FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.PREVENTIVE_HEALTH_CHECKUP,
  },

  {
    path: FACILITIES_CHILDREN_ROUTING_PATHS.RADIOLOGY_IMAGING,
    component: ContentDisplayPunalur,
    name: FACILITIES_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.RADIOLOGY_IMAGING,
  },
];
