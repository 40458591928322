<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Anesthesiology</h2>
      <p>
        Anesthesiology, anaesthesiology, anaesthesia or anaesthetics (see
        Terminology) <br />is the medical specialty concerned with the total
        perioperative care of patients before, during and after surgery.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/anesthesiology.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          The department provides International evidence based anaesthesia
          cover, intensive critical care, pain and palliative care services to
          all the departments of the hospital.
        </p>
        <p>
          The department has set up its own high standards in patient care. All
          operations theatres are equipped with state of the art anesthesia
          workstations, infusion pumps, facilities for multi-parameter
          monitoring, along with dedicated staff and technicians. Post operative
          care unit adjoining the operation theatre is equipped with modern
          ventilators, multi-parameter monitors.
        </p>
        <p>
          Patients scheduled for surgeries or investigative procedures are being
          screened regularly in the pre anaesthesia clinic for their
          optimisation and surgical fitness.
        </p>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Anesthesiology Doctors",
      queryName: "Anesthesiology",
      teamName: "anesthesiology team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate2.scss";
</style>
