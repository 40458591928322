<template>
  <div class="container" id="facilities">
    <div class="section-title">
      <h2>Our Facilities</h2>
      <p>
        Our state of the art facilties will ensure that you can avail the best
        care and service possible in (Allapuzha, Kozhencherry or Punelur).
        <br />To avail our facilities please contact or visit us.
      </p>
    </div>

    <div class="row">
      <div
        class="col-lg-4 col-sm-6 mb-5"
        v-for="({ title, icon, description, link }, i) in ourFacilities"
        :key="i"
        style="display:grid;"
      >
        <div class="facilities-box">
          <img :src="icon" class="img-fluid" />
          <h3>{{ title }}</h3>
          <p>
            {{ description }}
          </p>
          <router-link :to="$route.meta.toHome + '/facilities/' + link">
            <div class="more-btn"><i class="fa fa-arrow-right"></i></div
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { OurFacilitiesDataInterface } from "@/ts/interfaces";
import { ourFacilities } from "@/static/data";

export default defineComponent({
  data(): OurFacilitiesDataInterface {
    return {
      ourFacilities,
    };
  },
});
</script>

<style scoped>
h3 {
  font-size: 20px;
  font-family: "ProximaNovaBold";
}
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
}

#facilities {
  margin-top: 100px;
}

.facilities-box {
  box-shadow: 0 8px 16px 0 rgba(146, 184, 255, 0.2);
  background-color: #fff;
  border-bottom: 4px solid #1a5ec3;
  padding: 30px 20px;
  text-align: center;
}
.facilities-box img {
  margin: auto;
  display: table;
}
.facilities-box h3 {
  padding-top: 20px;
}

.facilities-box {
  position: relative;
}

.facilities-box p {
  margin-top: 20px;
  padding-bottom: 50px;
  text-align: center;
}

.facilities-box .more-btn {
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 45px;
  height: 45px;
  line-height: 50px;
  text-align: center;
  box-shadow: 0 0 25px 0 rgb(0 27 103 / 20%);
  border-radius: 50%;
  left: 0;
  margin: auto;
}
.facilities-box .more-btn:hover {
  background: #de0f17;
  color: #fff;
}

.more-btn i {
  position: relative;
  bottom: 2px;
}
</style>
