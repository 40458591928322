
import { defineComponent } from "vue";

import Template from "@/components/Templates/index.vue";
import Appointment from "@/components/Appointment/Template1/index.vue";
import { APPOINTMENT_ROUTE_NAME } from "@/ts/enum";

export default defineComponent({
  name: APPOINTMENT_ROUTE_NAME.APPOINTMENT_POYANIL,
  components: {
    Appointment,
    Template,
  },
});
