<template>
  <div id="departments">
    <div class="container">
      <div class="section-title">
        <h6 class="text-red">High-Quality Emergency Medical Care</h6>
        <h2>Our Departments</h2>
        <p>
          Our qualified team of doctors will provide you with exceptional care
          and service in these departments.
        </p>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-sm-6 mb-5"
          v-for="({ title, description, link }, i) in departments"
          :key="i"
          style="display:grid;"
        >
          <div class="departments">
            <h3>{{ title }}</h3>
            <p>
              {{ description }}
            </p>
            <router-link :to="$route.meta.toHome + '/department/' + link">
              <div class="more-btn"><i class="fa fa-arrow-right"></i></div
            ></router-link>
          </div>
        </div>
      </div>

      <!-- <div @click="goToDepartments" class="col-lg-2 m-auto">
        <div class="btn btn-primary read-more-btn mt-5">Load More</div>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { DepartmentDataInterface } from "@/ts/interfaces";
import { departments } from "@/static/data";

export default defineComponent({
  data(): DepartmentDataInterface {
    return {
      departments,
    };
  },

  methods: {
    goToDepartments(): void {
      this.$router.push(this.$route.meta.toHome + "/department");
    },
  },
});
</script>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
}
.section-title:after,
.section-title:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 50%;
  left: 10px;
  top: 0;
  background: #de0f17;
  transition: 0.5s;
}

.section-title:after {
  left: 0;
  height: 100%;
}

#departments {
  margin-top: 100px;
}

#departments {
  background-color: #f1f1f1;
  padding-top: 60px;
  padding-bottom: 80px;
}

#departments .departments {
  background-color: #fff;
  padding: 25px 0px;
  border: dashed 1px #eeeeee;
  text-align: center;
}

.departments p {
  margin-top: 20px;
  padding-bottom: 20px;
}

.departments h3 {
  font-size: 20px;
  font-family: "ProximaNovaBold";
}

.departments .more-btn {
  position: absolute;
  right: 15px;
  bottom: 0;
  width: 45px;
  height: 40px;
  line-height: 44px;
  text-align: center;
  box-shadow: 0 0 25px 0 rgba(0, 27, 103, 0.2);
  border-radius: 50% 0 0 0;
}

.departments .more-btn:hover {
  background: #de0f17;
  color: #fff;
}

.read-more-btn,
.contact-btn {
  background: #1a5ec3;
  border-color: #1a5ec3;
  padding: 12px 30px 12px 30px;
  text-transform: uppercase;
  transition: 0.5s;
}
.read-more-btn:hover {
  background: #de0f17;
  border-color: #de0f17;
  transform: translateY(-5px);
}
</style>
