<template>
  <Header
    :bgIMG="bgImage"
    :isCenter="true"
    :subTitle="'Poyanil Hospital'"
    :title="'About Us'"
  />
  <!-- <AboutUs /> -->
  <!-- <WeCare /> -->
  <FounderDetails />
  <Stats />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Header from '@/components/CarouselHeader.vue';
// import AboutUs from '@/components/AboutUs/Template3/AboutUs.vue';
import WeCare from '@/components/AboutUs/Template3/WeCare.vue';
import FounderDetails from '@/components/AboutUs/Template3/FounderDetails.vue';
import Stats from '@/components/Stats.vue';
import { AboutUsDataInterface } from '@/ts/interfaces';

export default defineComponent({
  components: {
    Header,
    // AboutUs,
    WeCare,
    FounderDetails,
    Stats,
  },
  data(): AboutUsDataInterface {
    return {
      bgImage: require('@/assets/img/about-banner.png'),
    };
  },
});
</script>
