<template>
  <div class="col-lg-7">
    <div class="section-title">
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>
    </div>

    <p
      :v-if="contents.length"
      v-for="(content, i) in contents"
      class="mt-4"
      :key="i"
    >
      {{ content }}
    </p>

    <h5 class="text-red mt-4" :v-if="highlightText">
      <strong>{{ highlightText }}</strong>
    </h5>
  </div>
</template>

<script lang="ts">
import { facilitiesData } from "@/serverless/data/facilitiesData";
import { defineComponent } from "vue";

export default defineComponent({
  data(): FacilityContentDisplayInterface {
    return {
      title: "",
      description: "",
      contents: [],
      highlightText: "",
    };
  },
  methods: {
    contentProvider(): void {
      const data = facilitiesData.filter(
        ({ name }) => this.$route.name === name
      )[0];

      if (data) {
        this.contents = data.contents;
        this.highlightText = data.highlightText;
        this.title = data.title;
        this.description = data.description;
      } else {
        this.title = "Something went wrong while fetching data";
        this.description = "Something went wrong while fetching data";
      }
    },
  },

  mounted() {
    this.contentProvider();
  },

  watch: {
    $route() {
      this.contentProvider();
    },
  },
});

interface FacilityContentDisplayInterface {
  title: string;
  description: string;
  contents?: string[];
  highlightText?: string;
}
</script>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
}
</style>
