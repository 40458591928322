import {
  DEPARTMENT_CHILDREN_ROUTING_PATHS,
  DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES,
  DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES,
  DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES,
} from '@/ts/enum';
import { RouteRecordRaw } from 'vue-router';
// Department 1 Childrens
import Anesthesiology from '@/components/Departments/Template1/Anesthesiology.vue';
import AnesthesiologyKozhencherry from '@/components/Departments/Template2/Anesthesiology.vue';
import AnesthesiologyPunalur from '@/components/Departments/Template3/Anesthesiology.vue';

import Diabetology from '@/components/Departments/Template1/Diabetology.vue';
import DiabetologyKozhencherry from '@/components/Departments/Template2/Diabetology.vue';
import DiabetologyPunalur from '@/components/Departments/Template3/Diabetology.vue';

import EmergencyMedicine from '@/components/Departments/Template1/EmergencyMedicine.vue';
import EmergencyMedicineKozhencherry from '@/components/Departments/Template2/EmergencyMedicine.vue';
import EmergencyMedicinePunalur from '@/components/Departments/Template3/EmergencyMedicine.vue';

import GeneralMedicine from '@/components/Departments/Template1/GeneralMedicine.vue';
import GeneralMedicineKozhencherry from '@/components/Departments/Template2/GeneralMedicine.vue';
import GeneralMedicinePunalur from '@/components/Departments/Template3/GeneralMedicine.vue';

import GeneralSurgery from '@/components/Departments/Template1/GeneralSurgery.vue';
import GeneralSurgeryKozhencherry from '@/components/Departments/Template2/GeneralSurgery.vue';
import GeneralSurgeryPunalur from '@/components/Departments/Template3/GeneralSurgery.vue';

import InterventionalCardiology from '@/components/Departments/Template1/InterventionalCardiology.vue';
import InterventionalCardiologyKozhencherry from '@/components/Departments/Template2/InterventionalCardiology.vue';
import InterventionalCardiologyPunalur from '@/components/Departments/Template3/InterventionalCardiology.vue';

import Nephrology from '@/components/Departments/Template1/Nephrology.vue';
import NephrologyKozhencherry from '@/components/Departments/Template2/Nephrology.vue';
import NephrologyPunalur from '@/components/Departments/Template3/Nephrology.vue';

import NeuroMedicine from '@/components/Departments/Template1/NeuroMedicine.vue';
import NeuroMedicineKozhencherry from '@/components/Departments/Template2/NeuroMedicine.vue';
import NeuroMedicinePunalur from '@/components/Departments/Template3/NeuroMedicine.vue';

import NeuroSurgery from '@/components/Departments/Template1/NeuroSurgery.vue';
import NeuroSurgeryKozhencherry from '@/components/Departments/Template2/NeuroSurgery.vue';
import NeuroSurgeryPunalur from '@/components/Departments/Template3/NeuroSurgery.vue';

import ObstetricsAndGynaecology from '@/components/Departments/Template1/ObstetricsAndGynaecology.vue';
import ObstetricsAndGynaecologyKozhencherry from '@/components/Departments/Template2/ObstetricsAndGynaecology.vue';
import ObstetricsAndGynaecologyPunalur from '@/components/Departments/Template3/ObstetricsAndGynaecology.vue';

import Orthopaedics from '@/components/Departments/Template1/Orthopaedics.vue';
import OrthopaedicsKozhencherry from '@/components/Departments/Template2/Orthopaedics.vue';
import OrthopaedicsPunalur from '@/components/Departments/Template3/Orthopaedics.vue';

import Pediatrics from '@/components/Departments/Template1/Pediatrics.vue';
import PediatricsKozhencherry from '@/components/Departments/Template2/Pediatrics.vue';
import PediatricsPunalur from '@/components/Departments/Template3/Pediatrics.vue';

import Pulmonology from '@/components/Departments/Template1/Pulmonology.vue';
import PulmonologyKozhencherry from '@/components/Departments/Template2/Pulmonology.vue';
import PulmonologyPunalur from '@/components/Departments/Template3/Pulmonology.vue';

import Radiology from '@/components/Departments/Template1/Radiology.vue';
import RadiologyKozhencherry from '@/components/Departments/Template2/Radiology.vue';
import RadiologyPunalur from '@/components/Departments/Template3/Radiology.vue';

import Urology from '@/components/Departments/Template1/Urology.vue';
import UrologyKozhencherry from '@/components/Departments/Template2/Urology.vue';
import UrologyPunalur from '@/components/Departments/Template3/Urology.vue';

// extras
import OphthalmologyKozhencherry from '@/components/Departments/Template2/Ophthalmology.vue';
import MedicalGastroenterologyKozhencherry from '@/components/Departments/Template2/MedicalGastroenterology.vue';
import DermatologyKozhencherry from '@/components/Departments/Template2/Dermatology.vue';
import ENTKozhencherry from '@/components/Departments/Template2/ENT.vue';
import CardiologyKozhenCherry from '@/components/Departments/Template2/Cardiology.vue';
import PsychiatryKozhencherry from '@/components/Departments/Template2/Psychiatry.vue';
import DentalKozhencherry from '@/components/Departments/Template2/Dental.vue';
import FertilityCenterKozhencherry from '@/components/Departments/Template2/FertilityCenter.vue';

import NeurologyPunalur from '@/components/Departments/Template3/Neurology.vue';
import AyurvedamPunalur from '@/components/Departments/Template3/Ayurvedam.vue';
import DermatologyPunalur from '@/components/Departments/Template3/Dermatology.vue';
import ENTPunalur from '@/components/Departments/Template3/ENT.vue';
import DentalPunalur from '@/components/Departments/Template3/Dental.vue';
import FertilityCenterPunalur from '@/components/Departments/Template3/FertilityCenter.vue';

export const DepartmentChildrenRoutes: RouteRecordRaw[] | undefined = [
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ANESTHESIOLOGY,
    component: Anesthesiology,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.ANESTHESIOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DIABETOLOGY,
    component: Diabetology,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.DIABETOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_EMERGENCY_MEDICINE,
    component: EmergencyMedicine,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.EMERGENCY_MEDICINE,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_MEDICINE,
    component: GeneralMedicine,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.GENERAL_MEDICINE,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_SURGERY,
    component: GeneralSurgery,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.GENERAL_SURGERY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_INTERVENTIONAL_CARDIOLOGY,
    component: InterventionalCardiology,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.INTERVENTIONAL_CARDIOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEPHROLOGY,
    component: Nephrology,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.NEPHROLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROMEDICINE,
    component: NeuroMedicine,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.NEUROMEDICINE,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROSURGERY,
    component: NeuroSurgery,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.NEUROSURGERY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OBSTETRICSANDGYNAECOLOGY,
    component: ObstetricsAndGynaecology,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.OBSTETRICSANDGYNAECOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ORTHOPAEDICS,
    component: Orthopaedics,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.ORTHOPAEDICS,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PEDIATRICS,
    component: Pediatrics,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.PEDIATRICS,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PULMONOLOGY,
    component: Pulmonology,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.PULMONOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_RADIOLOGY,
    component: Radiology,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.RADIOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_UROLOGY,
    component: Urology,
    name: DEPARTMENT_POYANIL_CHILDREN_ROUTE_NAMES.UROLOGY,
  },
];

export const DepartmentChildrenRoutesKozhencherry:
  | RouteRecordRaw[]
  | undefined = [
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ANESTHESIOLOGY,
    component: AnesthesiologyKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.ANESTHESIOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DIABETOLOGY,
    component: DiabetologyKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.DIABETOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_EMERGENCY_MEDICINE,
    component: EmergencyMedicineKozhencherry,
    name:
      DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.EMERGENCY_MEDICINE,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_MEDICINE,
    component: GeneralMedicineKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.GENERAL_MEDICINE,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_SURGERY,
    component: GeneralSurgeryKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.GENERAL_SURGERY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_INTERVENTIONAL_CARDIOLOGY,
    component: InterventionalCardiologyKozhencherry,
    name:
      DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.INTERVENTIONAL_CARDIOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OPHTHALMOLOGY,
    component: OphthalmologyKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.OPHTHALMOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEPHROLOGY,
    component: NephrologyKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.NEPHROLOGY,
  },

  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROSURGERY,
    component: NeuroSurgeryKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.NEUROSURGERY,
  },

  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROMEDICINE,
    component: NeuroMedicineKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.NEUROMEDICINE,
  },

  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OBSTETRICSANDGYNAECOLOGY,
    component: ObstetricsAndGynaecologyKozhencherry,
    name:
      DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.OBSTETRICSANDGYNAECOLOGY,
  },

  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ORTHOPAEDICS,
    component: OrthopaedicsKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.ORTHOPAEDICS,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PEDIATRICS,
    component: PediatricsKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PEDIATRICS,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PULMONOLOGY,
    component: PulmonologyKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PULMONOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_RADIOLOGY,
    component: RadiologyKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.RADIOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_UROLOGY,
    component: UrologyKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.UROLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_MEDICAL_GASTROENTEROLOGY,
    component: MedicalGastroenterologyKozhencherry,
    name:
      DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.MEDICAL_GASTROENTEROLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DERMATOLOGY,
    component: DermatologyKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.DERMATOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ENT,
    component: ENTKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.ENT,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_CARDIOLOGY,
    component: CardiologyKozhenCherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.CARDIOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PSYCHIATRY,
    component: PsychiatryKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.PSYCHIATRY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DENTAL,
    component: DentalKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.DENTAL,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_FERTILITY_CENTER,
    component: FertilityCenterKozhencherry,
    name: DEPARTMENT_POYANIL_KOZHENCHERRY_CHILDREN_ROUTE_NAMES.FERTILITY_CENTER,
  },
];

export const DepartmentChildrenRoutesPunalur: RouteRecordRaw[] | undefined = [
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ANESTHESIOLOGY,
    component: AnesthesiologyPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.ANESTHESIOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DIABETOLOGY,
    component: DiabetologyPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.DIABETOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_EMERGENCY_MEDICINE,
    component: EmergencyMedicinePunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.EMERGENCY_MEDICINE,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_MEDICINE,
    component: GeneralMedicinePunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.GENERAL_MEDICINE,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_SURGERY,
    component: GeneralSurgeryPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.GENERAL_SURGERY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_INTERVENTIONAL_CARDIOLOGY,
    component: InterventionalCardiologyPunalur,
    name:
      DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.INTERVENTIONAL_CARDIOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEPHROLOGY,
    component: NephrologyPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.NEPHROLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROLOGY,
    component: NeurologyPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.NEUROLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROMEDICINE,
    component: NeuroMedicinePunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.NEUROMEDICINE,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROSURGERY,
    component: NeuroSurgeryPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.NEUROSURGERY,
  },

  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_OBSTETRICSANDGYNAECOLOGY,
    component: ObstetricsAndGynaecologyPunalur,
    name:
      DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.OBSTETRICSANDGYNAECOLOGY,
  },

  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ORTHOPAEDICS,
    component: OrthopaedicsPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.ORTHOPAEDICS,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PEDIATRICS,
    component: PediatricsPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.PEDIATRICS,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_PULMONOLOGY,
    component: PulmonologyPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.PULMONOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_RADIOLOGY,
    component: RadiologyPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.RADIOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_UROLOGY,
    component: UrologyPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.UROLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_AYURVEDAM,
    component: AyurvedamPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.AYURVEDAM,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DERMATOLOGY,
    component: DermatologyPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.DERMATOLOGY,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ENT,
    component: ENTPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.ENT,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DENTAL,
    component: DentalPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.DENTAL,
  },
  {
    path: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_FERTILITY_CENTER,
    component: FertilityCenterPunalur,
    name: DEPARTMENT_POYANIL_PUNALUR_CHILDREN_ROUTE_NAMES.FERTILITY_CENTER,
  },
];
