
import { otherHospitalsNavList } from "@/ts/functions";
import { OtherHospitalRouterLink } from "@/ts/interfaces";
import { defineComponent } from "vue";
import { RouteMeta } from "vue-router";

export default defineComponent({
  name: "Nav Bar",

  data(): NavBarDataInterface {
    return {
      observer: null,
      fixNav: false,
      toRoutes: {
        toDefaultHome: this.$route.meta.toDefaultHome,
        toHome: this.$route.meta.toHome,
        toAboutUs: this.$route.meta.toAboutUs,
        toDoctors: this.$route.meta.toDoctors,
        toDepartments: this.$route.meta.toDepartments,
        toFacilities: this.$route.meta.toFacilities,
        toGallery: this.$route.meta.toGallery,
        toNews: this.$route.meta.toNews,
        toCarrers: this.$route.meta.toCarrers,
        toTestimonials: this.$route.meta.toTestimonials,
        toContactUs: this.$route.meta.toContactUs,
        toAppointment: this.$route.meta.toAppointment,
      },
      ourHospitals: [{ link: "", name: "" }],
    };
  },

  mounted() {
    this.ourHospitals = otherHospitalsNavList();

    const element: HTMLElement | null = document.getElementById(
      "fixed-nav-ref"
    );

    this.observer = new IntersectionObserver((entries) => {
      const firstEntery = entries[0];
      if (firstEntery.isIntersecting) {
        this.fixNav = false;
      } else {
        this.fixNav = true;
      }
    });
    if (element && this.observer instanceof IntersectionObserver) {
      this.observer.observe(element);
    }
  },
  unmounted() {
    if (this.observer instanceof IntersectionObserver) {
      this.observer.disconnect();
    }
  },
});

interface NavBarDataInterface {
  observer: IntersectionObserver | null;
  fixNav: boolean;
  toRoutes: RouteMeta;
  ourHospitals: OtherHospitalRouterLink[];
}
