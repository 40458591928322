
import { ROUTING_PATHS } from '@/ts/enum';
import { SocialLinks } from '@/ts/interfaces';
import { defineComponent } from 'vue';
import { ALAPPUZHA_HSP_SOCIAL_LINKS } from '@/static/data';
import {
  KOZHENCHERRY_HSP_SOCIAL_LINKS,
  PUNALUR_HSP_SOCIAL_LINKS,
} from '@/static/data/hospitalsSocialLinks';

interface SocialIconsData extends SocialLinks {}

export default defineComponent({
  data(): SocialIconsData {
    return {
      whatsApp: 9656986668,
      email: 'providencepoyanil@gmail.com',
      contactNumber: '0468-2210600',
      fbLink:
        'https://m.facebook.com/providencehospitalalappuzha/?tsid=0.7830931174725102&source=result',
    };
  },
  methods: {
    handleLinks() {
      if (this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_HOME) {
        this.whatsApp = ALAPPUZHA_HSP_SOCIAL_LINKS.whatsApp;
        this.email = ALAPPUZHA_HSP_SOCIAL_LINKS.email;
        this.contactNumber = ALAPPUZHA_HSP_SOCIAL_LINKS.contactNumber;
        this.fbLink = ALAPPUZHA_HSP_SOCIAL_LINKS.fbLink;
      }
      if (
        this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_HOME
      ) {
        this.whatsApp = KOZHENCHERRY_HSP_SOCIAL_LINKS.whatsApp;
        this.email = KOZHENCHERRY_HSP_SOCIAL_LINKS.email;
        this.contactNumber = KOZHENCHERRY_HSP_SOCIAL_LINKS.contactNumber;
        this.fbLink = KOZHENCHERRY_HSP_SOCIAL_LINKS.fbLink;
      }

      if (this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_PUNALUR_HOME) {
        this.whatsApp = PUNALUR_HSP_SOCIAL_LINKS.whatsApp;
        this.email = PUNALUR_HSP_SOCIAL_LINKS.email;
        this.contactNumber = PUNALUR_HSP_SOCIAL_LINKS.contactNumber;
        this.fbLink = PUNALUR_HSP_SOCIAL_LINKS.fbLink;
      }
    },
  },
  mounted() {
    if (this.$route.meta.toHome) {
      this.handleLinks();
    }
  },

  watch: {
    $route(to, from) {
      if (this.$route.meta.toHome) {
        this.handleLinks();
      }
    },
  },
});
