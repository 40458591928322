<template>
  <Swiper
    :slides-per-view="sliderPerView"
    :space-between="25"
    :pagination="{ clickable: true }"
    class="swiper-container"
  >
    <SwiperSlide>
      <div class="testimonial-container">
        <div class="flex-column-1 ">
          <p class="pr-2 pl-3 pt-4">
            What a Service!!!!!Antony is one of the top Classy designer in the
            world.Exceeded expectation and designed my website to my
            requirments.Will be usingfor ongoing work.
          </p>
        </div>
        <div class="testimonials-author-details">
          <div class="profic-pic-testimonials">
            <img src="@/assets/img/testimonials-img-1.png" />
          </div>
          <div class="information  flex-column pl-3 pt-2">
            <p class="m-0">David Luiz</p>
            <p class="text-gray">Ceo &amp; Founder</p>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div class="testimonial-container">
        <div class="flex-column-1 ">
          <p class="pr-2 pl-3 pt-4">
            What a Service!!!!!Antony is one of the top Classy designer in the
            world.Exceeded expectation and designed my website to my
            requirments.Will be usingfor ongoing work.
          </p>
        </div>
        <div class="testimonials-author-details">
          <div class="profic-pic-testimonials">
            <img src="@/assets/img/testimonials-img-1.png" />
          </div>
          <div class="information  flex-column pl-3 pt-2">
            <p class="m-0">David Luiz</p>
            <p class="text-gray">Ceo &amp; Founder</p>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div class="testimonial-container">
        <div class="flex-column-1 ">
          <p class="pr-2 pl-3 pt-4">
            What a Service!!!!!Antony is one of the top Classy designer in the
            world.Exceeded expectation and designed my website to my
            requirments.Will be usingfor ongoing work.
          </p>
        </div>
        <div class="testimonials-author-details">
          <div class="profic-pic-testimonials">
            <img src="@/assets/img/testimonials-img-1.png" />
          </div>
          <div class="information  flex-column pl-3 pt-2">
            <p class="m-0">David Luiz</p>
            <p class="text-gray">Ceo &amp; Founder</p>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div class="testimonial-container">
        <div class="flex-column-1 ">
          <p class="pr-2 pl-3 pt-4">
            What a Service!!!!!Antony is one of the top Classy designer in the
            world.Exceeded expectation and designed my website to my
            requirments.Will be usingfor ongoing work.
          </p>
        </div>
        <div class="testimonials-author-details">
          <div class="profic-pic-testimonials">
            <img src="@/assets/img/testimonials-img-1.png" />
          </div>
          <div class="information  flex-column pl-3 pt-2">
            <p class="m-0">David Luiz</p>
            <p class="text-gray">Ceo &amp; Founder</p>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div class="testimonial-container">
        <div class="flex-column-1 ">
          <p class="pr-2 pl-3 pt-4">
            What a Service!!!!!Antony is one of the top Classy designer in the
            world.Exceeded expectation and designed my website to my
            requirments.Will be usingfor ongoing work.
          </p>
        </div>
        <div class="testimonials-author-details">
          <div class="profic-pic-testimonials">
            <img src="@/assets/img/testimonials-img-1.png" />
          </div>
          <div class="information  flex-column pl-3 pt-2">
            <p class="m-0">David Luiz</p>
            <p class="text-gray">Ceo &amp; Founder</p>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div class="testimonial-container">
        <div class="flex-column-1 ">
          <p class="pr-2 pl-3 pt-4">
            What a Service!!!!!Antony is one of the top Classy designer in the
            world.Exceeded expectation and designed my website to my
            requirments.Will be usingfor ongoing work.
          </p>
        </div>
        <div class="testimonials-author-details">
          <div class="profic-pic-testimonials">
            <img src="@/assets/img/testimonials-img-1.png" />
          </div>
          <div class="information  flex-column pl-3 pt-2">
            <p class="m-0">David Luiz</p>
            <p class="text-gray">Ceo &amp; Founder</p>
          </div>
        </div>
      </div>
    </SwiperSlide>
  </Swiper>
</template>
<script>
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      sliderPerView: 1,
      windowWidth: window?.innerWidth,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    slidesPerViewSetter(width) {
      if (width > 1199) {
        this.sliderPerView = 3;
      }
      if (width > 768 && width < 1199) {
        this.sliderPerView = 2;
      }
      if (width < 768) {
        this.sliderPerView = 1;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.slidesPerViewSetter(this.windowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    windowWidth(newWidth, _oldWidth) {
      this.slidesPerViewSetter(newWidth);
    },
  },
});
</script>

<style scoped>
.swiper-pagination-bullet {
  width: 8px !important;
  height: 1px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  opacity: 0.2;
}

.profic-pic-testimonials img {
  width: 70px;
  height: 70px;
}

.profic-pic-testimonials:hover {
  transform: scale(1.1);
  transition: all 0.5s;
}

.swiper-pagination {
  bottom: 0 !important;
}

.testimonials-author-details {
  display: flex;
  padding-left: 5%;
}
.testimonial-container {
  height: 250px;
  background-color: white;
  box-shadow: 0 3px 10px #5969f642;
  border-bottom: 4px solid #5969f6;
  color: black;
}

.testimonial-container:hover {
  box-shadow: 0 3px 15px #5969f666;
  border-bottom: 4px solid #de0f17;
  color: black;
  text-decoration: none;
  transition: all 0.4s;
}

.testimonial-container:hover .profic-pic-testimonials {
  transform: scale(1.1);
  transition: all 0.5s;
}
</style>
