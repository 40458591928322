
import { defineComponent } from 'vue';
import { MAIN_ROUTING_NAMES } from '@/ts/enum';

import HomePageScreen from '@/components/HospitalsHomePage/Hospital3/index.vue';
import Template from '@/components/Templates/Punalur.vue';
import SocialIcons from '@/components/SocialIcons.vue';

export default defineComponent({
  name: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_PUNALUR,
  components: {
    HomePageScreen,
    Template,
    SocialIcons,
  },
});
