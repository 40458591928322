
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate3.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Neurosurgery Doctors",
      queryName: "Neurosurgery",
      teamName: "Neurosurgery team",
    };
  },
  components: { DoctorsList },
});
