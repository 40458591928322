
import { defineComponent } from 'vue';
import Facilities from '@/components/Appointment/Facilities.vue';
import { useQuery } from '@/hooks';
import {
  getDepartmentsList,
  getDoctorsListBasedOfDepartmentSelected,
} from '@/graphql';
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';
import Loader from '@/components/LoadingAnimation.vue';
import {
  AppointmentDataInterface,
  DepartmentListPickTypeInterface,
  DoctorDataTypeInterface,
  MailSchemaInterface,
} from '@/ts/interfaces';
import { validateEmail } from '@/ts/functions';

export default defineComponent({
  data(): AppointmentDataInterface {
    return {
      departmentsList: [],
      loading: true,
      selectedDeptId: '',
      doctorsList: [],
      selectedDoctor: '',
      selectedDate: null,
      patientName: '',
      patientEmail: '',
      patientContactNumber: null,
      opNumber: null,
      patientMessage: '',
      error: null,
      sending: false,
      doctorsLoading: false,
      sent: false,
    };
  },
  components: {
    Facilities,
    Loader,
  },

  mounted() {
    (async () => {
      const { data, loading, errors } = await useQuery(getDepartmentsList, {
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
      });

      this.loading = loading;

      this.departmentsList = data
        .doctorsListingForParticularDepartmentCollection
        .items as DepartmentListPickTypeInterface[];
    })();
  },

  watch: {
    async selectedDeptId(curr, _next): Promise<void> {
      if (curr) {
        this.doctorsLoading = true;
        const { data, loading } = await useQuery(
          getDoctorsListBasedOfDepartmentSelected,
          {
            deptId: curr,
          }
        );

        this.doctorsLoading = loading;

        this.doctorsList = data.doctorsListingForParticularDepartment
          .doctorCollection.items as DoctorDataTypeInterface[];
      } else {
        this.selectedDoctor = '';
      }
    },
  },

  methods: {
    missingInputHandler(errMessage: string, ref: HTMLElement): void {
      this.error = errMessage;

      setTimeout(() => {
        this.error = null;
        ref.focus();
        ref.className = ref.className + ' is-invalid';
      }, 1000);

      setTimeout(() => {
        ref.className = ref.className.replace('is-invalid', '');
      }, 2000);
    },

    async sendMail() {
      if (
        !this.selectedDeptId &&
        this.$refs.selectedDeptId instanceof HTMLSelectElement
      ) {
        this.missingInputHandler(
          'Select a department',
          this.$refs.selectedDeptId
        );
        return;
      }

      // if (
      //   !this.selectedDoctor &&
      //   this.$refs.selectedDoctor instanceof HTMLSelectElement
      // ) {
      //   this.missingInputHandler("Select a doctor", this.$refs.selectedDoctor);
      //   return;
      // }

      // if (
      //   !this.selectedDate &&
      //   this.$refs.selectedDate instanceof HTMLElement
      // ) {
      //   this.missingInputHandler(
      //     "Select a date for appointment",
      //     this.$refs.selectedDate
      //   );
      //   return;
      // }

      if (
        !this.patientName &&
        this.$refs.patientName instanceof HTMLInputElement
      ) {
        this.missingInputHandler('Enter your name', this.$refs.patientName);
        return;
      }

      if (
        !this.patientEmail &&
        this.$refs.patientEmail instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your email-address',
          this.$refs.patientEmail
        );
        return;
      }

      if (
        this.patientEmail &&
        !validateEmail(this.patientEmail) &&
        this.$refs.patientEmail instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter valid email-address',
          this.$refs.patientEmail
        );
        return;
      }

      if (
        !this.patientContactNumber &&
        this.$refs.patientContactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your contact number',
          this.$refs.patientContactNumber
        );
        return;
      }

      if (
        this.patientContactNumber &&
        this.patientContactNumber.toString().length !== 10 &&
        this.$refs.patientContactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter valid contact number',
          this.$refs.patientContactNumber
        );
        return;
      }

      if (!process.env.VUE_APP_EMAIL_TWO) {
        return;
      }

      // AJAX
      const postDataModel: MailSchemaInterface = {
        patientName: this.patientName,
        doctor: this.selectedDoctor,
        department: this.departmentsList.filter(
          ({ sys: { id } }) => id === this.selectedDeptId
        )[0].departments,
        patientContactNumber: this.patientContactNumber,
        outPatientNumber: this.opNumber,
        message: this.patientMessage,
        appointmentOn: this.selectedDate,
        patientEmail: this.patientEmail,
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
      };

      try {
        this.sending = true;
        await this.axios.post(
          `${process.env.VUE_APP_BASE_URI}/api/v1/service/email`,
          { ...postDataModel, to: process.env.VUE_APP_EMAIL_TWO }
        );

        this.sending = false;
        this.sent = true;
      } catch (e) {
        this.sending = false;
        console.clear();
      }
    },
  },
});
