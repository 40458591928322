<template>
  <div class="container mt-5">
    <div class="row">
      <Loader v-if="loading" />

      <div class="col-lg-7" v-else>
        <div class="col-lg-12">
          <h3>
            {{ newsDetail.headline }}
          </h3>
          <p class="mb-0 ">
            <small>{{ newsDetail.subHeadline }}</small>
          </p>
          <p>
            <small>{{
              new Date(newsDetail.sys.publishedAt).toString().split(' GMT')[0]
            }}</small>
          </p>

          <hr />
          <!-- Dynamic content here -->
          <div
            v-html="documentToHtmlString(newsDetail.content.json, options)"
          ></div>
        </div>
      </div>

      <div class="col-lg-5">
        <ContactLinks />

        <Appointment />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import Appointment from '@/components/News/BookAppointement.vue';
import ContactLinks from '@/components/News/ContactLinks.vue';
import { useQuery } from '@/hooks';
import { getNewsDetails } from '@/graphql';
import Loader from '@/components/LoadingAnimation.vue';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { NEWS_DETAIL_PAGE_NAMES } from '@/ts/enum';

export default defineComponent({
  components: {
    Appointment,
    ContactLinks,
    Loader,
  },
  methods: {
    documentToHtmlString,
  },
  data() {
    return {
      newsDetail: {},
      loading: true,
      options: {
        renderMark: {
          [MARKS.BOLD]: (text: string) => `<custom-bold>${text}<custom-bold>`,
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node: any, next: any) =>
            `<custom-paragraph>${next(node.content)}</custom-paragraph>`,
          [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const { content } = this.newsDetail as any;

            const img = content.links.assets.block.find(
              (entries: any) => entries.sys.id === node.data.target.sys.id
            );

            return `
            <div>
            <img
                style="width:100%"
               src=${img?.url}
               alt='content-img'
             />
            </div>
             `;
          },
        },
      },
    };
  },

  mounted() {
    if (this.$route.params.newsId) {
      (async () => {
        const { data, loading, errors } = await useQuery(getNewsDetails, {
          id: this.$route.params.newsId,
        });

        this.loading = loading;
        this.newsDetail = data?.news;

        document.title =
          NEWS_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_PUNALUR + data.news.headline;
      })();
    }
  },
});
</script>
