
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      show: true,
    };
  },

  mounted() {
    if (this.$route.path === this.$route.meta.toContactUs) {
      this.show = false;
    } else {
      this.show = true;
    }
  },
});
