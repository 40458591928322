import {
  DEPARTMENT_CHILDREN_ROUTING_PATHS,
  FACILITIES_CHILDREN_ROUTING_PATHS,
} from '@/ts/enum';
import {
  CarouselPropType,
  OurFacilitiesInterface,
  DepartmentInterface,
  WhatWeDoBestInterface,
} from '@/ts/interfaces';

export const poyanilCarouselItems: CarouselPropType[] = [
  {
    title: 'Emergency Medicine',
    description:
      'We provide 24 X 7 service of all emergency conditions requiring urgent care.',
    image: require('@/assets/img/carousel-1.png'),
  },
  {
    title: 'Personal Care Personal Touch.',
    description:
      'We provide 24 X 7 service of all emergency conditions requiring urgent care.',
    image: require('@/assets/img/carousel-2.png'),
  },
  {
    title: 'Quality in everything we do',
    description:
      'We provide 24 X 7 service of all emergency conditions requiring urgent care.',
    image: require('@/assets/img/carousel-3.png'),
  },
];

export const poyanilKozhencherryCarouselItems: CarouselPropType[] = [
  {
    title: 'Emergency Medicine',
    description:
      'We provide 24 X 7 service of all emergency conditions requiring urgent care.',
    image: require('@/assets/img/carousel-hsp2-1.png'),
  },
  {
    title: 'Our Appointment',
    description:
      'Consultation timings are rescheduled due to Covid 19. Please contact for further details - +91 8281998174',
    image: require('@/assets/img/carousel-hsp2-2.png'),
  },
  {
    title: 'Our Clinical Laboratory',
    description:
      'We provide 24 X 7 service of all emergency conditions requiring urgent care.',
    image: require('@/assets/img/carousel-hsp2-3.png'),
  },
];

export const ourFacilities: OurFacilitiesInterface[] = [
  {
    description:
      'Our Pharmacy consists of certified professionals providing round the clock pharmacy service to patients. Our pharmacies cater to around 450 out patients and around 100 in-patients a day.',
    icon: require('@/assets/img/facilities-icon-1.svg'),
    title: '24 Hour Pharmacy',
    link: FACILITIES_CHILDREN_ROUTING_PATHS.PHARMACY_24_x_7,
  },
  {
    description:
      'If it’s an emergency, every second is crucial and so is the treatment given. Our 24 Hour Clinic provides daily, around the clock, medical services to patients in need.',
    icon: require('@/assets/img/facilities-icon-2.svg'),
    title: '24 Hour Clinic',
    link: FACILITIES_CHILDREN_ROUTING_PATHS.CLINIC_24_x_7,
  },
  {
    description:
      'A catheterization laboratory, commonly referred to as a cath lab, is an examination room in a hospital or clinic with diagnostic imaging equipment used to visualize the arteries of the heart and the chambers of the heart and treat any stenosis or abnormality found.',
    icon: require('@/assets/img/facilities-icon-3.svg'),
    title: 'Cathlab 24 X 7',
    link: FACILITIES_CHILDREN_ROUTING_PATHS.CATHLAB,
  },
  {
    description:
      'ICU is the  most important unit of a hospital and is considered as the backbone of all medical and surgical procedures. The department has a 24 X 7  critical care unit that is led by senior anesthetists and physicians assisted by Nurse Practitioners, Junior Doctors,   Technicians, Physiotherapists, Nutritionists, Radiologists and Imaging Technicians.',
    icon: require('@/assets/img/facilities-icon-4.svg'),
    title: 'ICU’s',
    link: FACILITIES_CHILDREN_ROUTING_PATHS.ICU,
  },
  {
    description:
      'State of art automated analyzers and other equipment that render accurate and precise test results at the shortest time possible. Manned by highly qualified and experienced technicians and ancillary staff who follow stringent quality control measures.',
    icon: require('@/assets/img/facilities-icon-5.svg'),
    title: 'Clinical Laboratory',
    link: FACILITIES_CHILDREN_ROUTING_PATHS.CLINICAL_LABORATORY,
  },
  {
    description:
      "Dialysis is a treatment that filters and purifies the blood using a machine. This helps keep your fluids and electrolytes in balance when the kidneys can't do their job. Dialysis has been used since the 1940s to treat people with kidney problems.",
    icon: require('@/assets/img/facilities-icon-6.svg'),
    title: 'Dialysis 24 X 7',
    link: FACILITIES_CHILDREN_ROUTING_PATHS.DIALYSIS,
  },
];

export const departments: DepartmentInterface[] = [
  {
    title: 'Anesthesiology',
    description:
      'The department provides International  evidence based anaesthesia cover, intensive critical care, pain and palliative care services to all the departments of the hospital.',
    link: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_ANESTHESIOLOGY,
  },
  {
    title: 'Diabetology',
    description:
      'The Department of Medicine and Diabetes caters to all referred and local patients, addressing general medicine and diabetic problems and its various complications.',
    link: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_DIABETOLOGY,
  },
  {
    title: 'Emergency Medicine',
    description:
      'The Emergency Department in our hospital holds a pivotal role in the field of healthcare.  We provide 24 X 7 service of all emergency conditions requiring urgent care.',
    link: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_EMERGENCY_MEDICINE,
  },
  {
    title: 'General Medicine',
    description:
      'The Department of medicine is prepared to diagnose and manage patients with common and emergency  medical conditions and are able to do so when the individual has multiple conditions  with limited access to other subspecialists.',
    link: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_MEDICINE,
  },
  {
    title: 'General Surgery',
    description:
      'The Department of medicine is prepared to diagnose and manage patients with common and emergency  medical conditions and are able to do so when the individual has multiple conditions  with limited access to other subspecialists.',
    link: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_GENERAL_SURGERY,
  },
  {
    title: 'Urology',
    description:
      'The department of urology at Poyanil hospital is devoted to clinical excellence in urology with cutting edge techniques and state of the art equipment. We strive to serve our patients with best possible care and experience within all sections of our department. ',
    link: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_UROLOGY,
  },
  {
    title: 'Nephrology',
    description:
      'The department of Nephrology has a state of art dialysis unit and caters to the patients around this area.',
    link: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEPHROLOGY,
  },
  {
    title: 'Neuro Medicine',
    description:
      'The services of highly qualified neuro physicians are available here. Specialised treatment for stroke, epilipsey, etc is available.',
    link: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROMEDICINE,
  },
  {
    title: 'Neuro Surgery',
    description:
      'Neurosurgery, or neurological surgery, is the medical specialty concerned with the prevention, diagnosis, surgical treatment, and rehabilitation of disorders which affect any portion of the nervous system including the brain, spinal cord, central and peripheral nervous system, and cerebrovascular system.',
    link: DEPARTMENT_CHILDREN_ROUTING_PATHS.TO_NEUROSURGERY,
  },
];

export const whatWeDoBestItems: WhatWeDoBestInterface[] = [
  {
    icon: require('@/assets/img/what-we-do-icon-1.svg'),
    title: 'Appointment',
    description: '',
    link: 'appointment',
  },
  {
    title: 'Emergency Care',
    description: '',
    icon: require('@/assets/img/what-we-do-icon-2.svg'),
    link: 'appointment',
  },
  {
    icon: require('@/assets/img/what-we-do-icon-3.svg'),
    title: 'Health Checkup',
    description: '',
    link: 'appointment',
  },
  {
    icon: require('@/assets/img/what-we-do-icon-4.svg'),
    title: 'Our Doctors',
    description: '',
    link: 'doctors',
  },
  {
    icon: require('@/assets/img/what-we-do-icon-5.svg'),
    title: 'Our Facilities',
    description: '',
    link: 'facilities/24_hour_clinic',
  },
];

export { ALAPPUZHA_HSP_SOCIAL_LINKS } from './hospitalsSocialLinks';
