<template>
  <div class="loader">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="-466.4 259.6 280.2 47.3"
      enable-background="new -466.4 259.6 280.2 47.3"
      xml:space="preserve"
    >
      <polyline
        class="animation"
        points="-465.4,281 -436,281 -418.9,281 -423.9,281 -363.2,281 -355.2,269 -345.2,303 -335.2,263 -325.2,291 -319.2,281 -187.2,281 "
      ></polyline>
    </svg>
  </div>
</template>

<style scoped>
@-webkit-keyframes move {
  to {
    stroke-dashoffset: -1200;
  }
}
@-moz-keyframes move {
  to {
    stroke-dashoffset: -1200;
  }
}
@-o-keyframes move {
  to {
    stroke-dashoffset: -1200;
  }
}
@keyframes move {
  to {
    stroke-dashoffset: -1200;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@-moz-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@-o-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.loader {
  border-radius: 10px;
  height: 200px;
  left: 0;
  position: relative;
  top: 50px;
  width: 100%;
  z-index: 1000;
}
.loader svg {
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.animation {
  fill: none;
  stroke: #ee3030;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
  opacity: 1;
  stroke-dasharray: 600;
  -webkit-animation: move 5s linear forwards infinite, fade 5s linear infinite;
  animation: move 5s linear forwards infinite, fade 5s linear infinite;
}
</style>
