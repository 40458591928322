
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';
import { defineComponent } from 'vue';

interface JobApplicationDataInterface {
  bgIMG: string;
  name?: string;
  email?: string;
  contactNumber?: number | null;
  experience?: number | null;
  resume?: Blob | null;
  fileCTX?: string;
  error?: null | string;
  sending: boolean;
  sent: boolean;
}

export default defineComponent({
  props: {
    setShowApplication: {
      type: Function,
      required: true,
    },
    setAppliedStatus: {
      type: Function,
      required: true,
    },
    careerName: {
      type: String,
      required: true,
    },
    hospital: {
      type: Number,
      required: true,
    },
  },
  data(): JobApplicationDataInterface {
    return {
      bgIMG: require('@/assets/img/about-banner.png'),
      name: '',
      email: '',
      contactNumber: null,
      experience: null,
      resume: null,
      fileCTX: 'Upload your resume',
      error: null || '',
      sending: false,
      sent: false,
    };
  },
  methods: {
    onFileChange(e: Event) {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];
      this.fileCTX = file.name || 'resume' + 'uploaded';
      this.resume = file;
    },
    missingInputHandler(errMessage: string, ref: HTMLElement): void {
      this.error = errMessage;

      setTimeout(() => {
        this.error = null;
        ref.focus();
        ref.className = ref.className + ' is-invalid';
      }, 1000);

      setTimeout(() => {
        ref.className = ref.className.replace('is-invalid', '');
      }, 2000);
    },

    async handleApplicationSetup() {
      if (!this.name && this.$refs.name instanceof HTMLInputElement) {
        this.missingInputHandler('Enter your name', this.$refs.name);
        return;
      }

      if (!this.email && this.$refs.email instanceof HTMLInputElement) {
        this.missingInputHandler('Enter your email', this.$refs.email);
        return;
      }

      if (
        !this.contactNumber &&
        this.$refs.contactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your contact Number',
          this.$refs.contactNumber
        );
        return;
      }

      if (
        this.contactNumber &&
        this.contactNumber.toString().length !== 10 &&
        this.$refs.contactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter valid contact number',
          this.$refs.contactNumber
        );
        return;
      }

      if (
        !this.experience &&
        this.$refs.experience instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your experience in years',
          this.$refs.experience
        );
        return;
      }

      if (
        typeof this.experience !== 'number' &&
        this.$refs.experience instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your experience in years',
          this.$refs.experience
        );
        return;
      }

      if (!this.resume && this.$refs.resume instanceof HTMLElement) {
        this.missingInputHandler('Select your resume', this.$refs.resume);
        return;
      }

      if (
        !this.name ||
        !this.email ||
        !this.contactNumber ||
        !this.experience ||
        !this.resume
      ) {
        return;
      }

      if (!process.env.VUE_APP_EMAIL_ONE) {
        return;
      }

      let to: string = '';
      let hspName: string = '';

      if (this.hospital === 1 && process.env.VUE_APP_EMAIL_ONE) {
        to = process.env.VUE_APP_EMAIL_ONE;
        hspName = GRAPHQL_QUERYING_HOSPITAL_NAMES.PROVIDENCE_HOSPITAL_ALAPPUZHA;
      }

      if (this.hospital === 2 && process.env.VUE_APP_EMAIL_TWO) {
        to = process.env.VUE_APP_EMAIL_TWO;
        hspName = GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY;
      }

      if (this.hospital === 3 && process.env.VUE_APP_EMAIL_THREE) {
        to = process.env.VUE_APP_EMAIL_THREE;
        hspName = GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_PUNALUR;
      }

      const formData = new FormData();

      formData.append('name', this.name);
      formData.append('email', this.email);
      formData.append('contactNumber', this.contactNumber.toString());
      formData.append('experience', this.experience.toString());
      formData.append('resume', this.resume);
      formData.append('to', to);
      formData.append('job', this.careerName);
      formData.append('subject', 'New job application recieved');
      formData.append('hospital', hspName);

      try {
        this.sending = true;
        await this.axios.post(
          `${process.env.VUE_APP_BASE_URI}/api/v1/jobs/job_application`,
          formData
        );

        this.sending = false;
        this.sent = true;
        this.setAppliedStatus();
        setTimeout(() => {
          this.setShowApplication();
        }, 2000);
      } catch (e) {
        this.sending = false;
        console.clear();
        alert('something went wrong');
      }
    },
  },
});
