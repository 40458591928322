<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Diabetology</h2>
      <p>
        Diabetology is the clinical science of diabetes mellitus, its diagnosis,
        treatment and follow-up.<br />It can be considered a specialised field
        of endocrinology. The term diabetologist is used in several ways.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/diabetology.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          The Department of Medicine and Diabetes caters to all refered and
          local patients, addressing general medicine and diabetic problems and
          its various complications. Having all allied specialists and being
          equipped with all the necessary investigation facilities, we are
          capable of giving state of the art care for all.
        </p>
        <p>
          The ancillary specialities of Podiatry, Diabetes, Dental care are
          scheduled to start shortly and will augument the functioning of the
          unit. The Department of Medicine and Diabetes caters to all refered
          and local patients, addressing general medicine and diabetic problems
          and its various complications. Having all allied specialists and being
          equipped with all the necessary investigation facilities, we are
          capable of giving state of the art care for all.
        </p>
        <p>
          The ancillary specialities of Podiatry, Diabetes, Dental care are
          scheduled to start shortly and will augument the functioning of the
          unit.
        </p>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate1.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Diabetology Doctors",
      queryName: "Diabetology",
      teamName: "anesthesiology team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate1.scss";
</style>
