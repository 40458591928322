<template>
  <Template>
    <Careers />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Template from "@/components/Templates/Punalur.vue";
import Careers from "@/components/Careers/Template3/index.vue";

export default defineComponent({
  components: {
    Careers,
    Template,
  },
});
</script>
