
import { defineComponent } from 'vue';

import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import Appointment from '@/components/News/BookAppointement.vue';
import ContactLinks from '@/components/News/ContactLinks.vue';
import { useQuery } from '@/hooks';
import { getTestimonialDetail } from '@/graphql';
import Loader from '@/components/LoadingAnimation.vue';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { TESTIMONIAL_DETAIL_PAGE_NAMES } from '@/ts/enum';

export default defineComponent({
  components: {
    Appointment,
    ContactLinks,
    Loader,
    VideoPlayer,
  },
  methods: {
    documentToHtmlString,
  },
  data() {
    return {
      videoOptions: {
        autoplay: false,
        controls: true,
      },
      video: null,
      testimonialContent: {},
      loading: true,
      options: {
        renderMark: {
          [MARKS.BOLD]: (text: string) => `<custom-bold>${text}<custom-bold>`,
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node: any, next: any) =>
            `<custom-paragraph>${next(node.content)}</custom-paragraph>`,
          [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const content = this.testimonialContent as any;

            console.log(this.testimonialContent);

            const img = content.links.assets.block.find(
              (entries: any) => entries.sys.id === node.data.target.sys.id
            );

            return `
            <div>
            <img
                style="width:100%"
               src=${img?.url}
               alt='content-img'
             />
            </div>
             `;
          },
        },
      },
    };
  },

  mounted() {
    if (this.$route.params.id) {
      (async () => {
        const { data, loading, errors } = await useQuery(getTestimonialDetail, {
          id: this.$route.params.id,
        });

        this.loading = loading;
        this.testimonialContent = data?.testimonials?.testimonialContent;
        this.video = data?.testimonials?.video || null;

        document.title =
          TESTIMONIAL_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL +
          data?.testimonials?.reviewer;
      })();
    }
  },
});
