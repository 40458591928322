
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import Stats from "@/components/Stats.vue";
import DoctorDetail from "@/components/DoctorDetailPages/Template1/DetailView.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
    DoctorDetail,
  },
  data() {
    return {
      bgIMG: require("@/assets/img/doctors-banner.png"),
    };
  },
});
