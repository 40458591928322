<template>
  <div id="our-banner" class="mt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-6 mt-3 mb-3">
          <h2 class="text-white">165 +</h2>
          <h2 class="text-white">Doctors</h2>
        </div>
        <div class="col-lg-3 col-6 mt-3 mb-3">
          <h2 class="text-white">4 +</h2>
          <h2 class="text-white">Ambulance Car</h2>
        </div>

        <div class="col-lg-3 col-6 mt-3 mb-3">
          <h2 class="text-white">245 +</h2>
          <h2 class="text-white">Employees</h2>
        </div>

        <div class="col-lg-3 col-6 mt-3 mb-3">
          <h2 class="text-white">1,25474+</h2>
          <h2 class="text-white">Patients</h2>
        </div>
      </div>
    </div>
  </div>
</template>
