<template>
  <li class="media">
    <i class="fas fa-check mt-1 mr-2 text-red"></i>
    <div class="media-body">
      <p class="mt-0 mb-1">
        24/7 Services in Casuality and Emergency Care
      </p>
    </div>
  </li>
  <li class="media">
    <i class="fas fa-check mt-1 mr-2 text-red"></i>
    <div class="media-body">
      <p class="mt-0 mb-1">
        Comprehensive Health Check Up Packages
      </p>
    </div>
  </li>
  <li class="media">
    <i class="fas fa-check mt-1 mr-2 text-red"></i>
    <div class="media-body">
      <p class="mt-0 mb-1">NABH Certified Hospital Services</p>
    </div>
  </li>
</template>
