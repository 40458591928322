<template>
  <Loader v-if="loading" />
  <template v-else-if="doctorsList.length">
    <div class="seperator"><i class="fa fa-chevron-down"></i></div>
    <div class="container mt-5">
      <div class="section-title">
        <h2>{{ name }}</h2>
        <p>
          Meet our <br />
          {{ teamName }}
        </p>
      </div>

      <div class="row">
        <div
          class="col-lg-4"
          v-for="{
            sys: { id },
            name,
            centerOfExcellence,
            profilePic: { url },
          } in doctorsList"
          :key="id"
          style="display:grid;"
        >
          <div class="department-thumbnail">
            <img :src="url" :alt="name" class="img-fluid" />
          </div>
          <div
            class="department-thumbnail-content .department-thumbnail-content-box-shadow "
          >
            <div class="doctor-name">
              <h3>{{ name }}</h3>
            </div>
            <div class="department-desc">
              <p>
                {{ centerOfExcellence }}
              </p>
            </div>
            <router-link :to="$route.meta.toAppointment">
              <button type="button" class="btn btn-link department-btn">
                Book Appointment
              </button></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { getDoctorsListBasedOnDepartment } from "@/graphql";
import { useQuery } from "@/hooks";
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from "@/ts/enum";
import Loader from "@/components/LoadingAnimation.vue";

interface DoctorListTemplateDataInterface {
  loading: boolean;
  doctorsList: DoctoresListTypeForDepartment[];
}

interface DoctoresListTypeForDepartment {
  name: string;
  email: string;
  centerOfExcellence: string;
  profilePic: {
    url: string;
  };
  sys: {
    id: string;
  };
}

export default defineComponent({
  data(): DoctorListTemplateDataInterface {
    return {
      loading: true,
      doctorsList: [],
    };
  },
  components: { Loader },

  props: {
    name: {
      type: String,
      required: true,
    },
    teamName: {
      type: String,
      required: true,
    },

    queryName: {
      type: String,
      required: true,
    },
  },

  mounted() {
    (async () => {
      if (this.queryName) {
        const { data, loading, errors } = await useQuery(
          getDoctorsListBasedOnDepartment,
          {
            hospital:
              GRAPHQL_QUERYING_HOSPITAL_NAMES.PROVIDENCE_HOSPITAL_ALAPPUZHA,
            department: this.queryName,
            limit: 10,
            skip: 0,
          }
        );
        this.loading = loading;

        if (data.doctorsListingForParticularDepartmentCollection.items.length) {
          this.doctorsList = data
            .doctorsListingForParticularDepartmentCollection.items[0]
            .doctorCollection.items as DoctoresListTypeForDepartment[];
        }
      }
    })();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate1.scss";

.department-thumbnail {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;

  .img-fluid {
    max-height: 330px !important;
  }
}

.department-thumbnail-content {
  padding: 34px 12px 30px;
  margin: -55px 20px 0;
  text-align: center;
  border-bottom: 3px solid #1a5ec3;
  position: relative;
  background-color: #fff;
}

.department-thumbnail-content-box-shadow {
  -webkit-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  -moz-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
}
.doctor-name {
  color: #1a5ec3;
}
.department-desc p {
  font-size: 14px;
  margin-top: 1rem;
}

.department-btn {
  color: #de0f17;
  font-family: "ProximaNovaBold";
}
.department-btn:hover {
  color: #1a5ec3;
  text-decoration: none;
}
.department-btn:focus {
  outline: 0;
  box-shadow: inherit;
}
</style>
