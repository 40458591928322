<template>
  <footer>
    <div class="container" v-if="showLogo">
      <div class="d-flex justify-content-between">
        <div><img src="@/assets/img/logo.png" class="img-fluid logo" /></div>
        <div><i class="fab fa-facebook"></i></div>
      </div>
    </div>
    <hr v-if="showLogo" />
    <section :class="footerClass">
      <div class="container">
        <div class="row">
          <div class="col-lg-2">
            <p :class="linksColor">Quick Links</p>
            <ul
              class="list-unstyled "
              :class="{ blacktext: footerColor === 'blue' }"
            >
              <router-link :to="toRoutes.toHome"><li>Home</li></router-link>
              <router-link :to="toRoutes.toAboutUs"
                ><li>About Us</li></router-link
              >
              <router-link :to="toRoutes.toAppointment"
                ><li>Appointment</li></router-link
              >
              <router-link :to="toRoutes.toDefaultHome"
                ><li>Our Hospitals</li></router-link
              >
              <router-link :to="toRoutes.toCarrers"
                ><li>Careers</li></router-link
              >
              <router-link :to="toRoutes.toDoctors"
                ><li>Doctors</li></router-link
              >
              <a href="https://www.poyanilcollegeofnursing.in/" target="_blank"
                ><li>Institutions</li></a
              >
            </ul>
          </div>

          <div class="col-lg-3">
            <p :class="linksColor">Departments</p>
            <ul
              class="list-unstyled "
              :class="{ blacktext: footerColor === 'blue' }"
            >
              <router-link :to="toRoutes.toHome + '/department/anesthesiology'"
                ><li>Anesthesiology</li></router-link
              >
              <router-link :to="toRoutes.toHome + '/department/diabetology'"
                ><li>Diabetology</li></router-link
              >
              <router-link
                :to="toRoutes.toHome + '/department/emergency_medicine'"
                ><li>Emergency Medicine</li></router-link
              >
              <router-link
                :to="toRoutes.toHome + '/department/general_medicine'"
                ><li>General Medicine</li></router-link
              >
              <router-link :to="toRoutes.toHome + '/department/general_surgery'"
                ><li>General Surgery</li></router-link
              >
              <router-link
                :to="toRoutes.toHome + '/department/interventional_cardiology'"
                ><li>Interventional Cardiology</li></router-link
              >
              <router-link :to="toRoutes.toHome + '/department/nephrology'"
                ><li>Nephrology</li></router-link
              >
            </ul>
          </div>

          <div class="col-lg-3">
            <p :class="linksColor">Facilities</p>
            <ul
              class="list-unstyled "
              :class="{ blacktext: footerColor === 'blue' }"
            >
              <router-link :to="toRoutes.toHome + '/facilities/24_hour_clinic'"
                ><li>24 Hour Clinic</li></router-link
              >
              <router-link
                :to="toRoutes.toHome + '/facilities/24_x_7_power_back_up'"
                ><li>24 X 7 Power Back Up</li></router-link
              >
              <router-link :to="toRoutes.toHome + '/facilities/cathlab_24_x_7'"
                ><li>Cathlab 24 X 7</li></router-link
              >
              <router-link
                :to="toRoutes.toHome + '/facilities/clinical_laboratory'"
                ><li>Clinical Laboratory</li></router-link
              >
              <router-link
                :to="toRoutes.toHome + '/facilities/24_hour_pharmacy'"
                ><li>24 Hour Pharmacy</li></router-link
              >
              <router-link :to="toRoutes.toHome + '/facilities/ICU'"
                ><li>Operation Theaters</li></router-link
              >
              <router-link
                :to="toRoutes.toHome + '/facilities/preventive_health_checkup'"
                ><li>Preventive Health Checkup</li></router-link
              >
            </ul>
          </div>

          <div
            class="col-lg-4 "
            :class="footerColor === 'blue' ? 'text-black' : 'text-white'"
          >
            <p :class="linksColor">{{ address.heading }}</p>

            <dl class="row">
              <dd class="col-sm-4">Address :</dd>
              <dd class="col-sm-8">
                {{ address.address }}
              </dd>

              <dd class="col-sm-4">Phone :</dd>
              <dt class="col-sm-8" @click="'tel:' + phone">{{ phone }}</dt>

              <dd class="col-sm-4">Email :</dd>
              <dd class="col-sm-8" @click="'email:' + email">{{ email }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </section>

    <div
      class="copy text-center text-white "
      :class="footerColor === 'blue' ? 'copyright-blue' : 'copyright-red'"
    >
      <p>
        ©Copyright 2021 <strong>Providence Hospital</strong> All Rights Reserved
      </p>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FooterDateInterface } from '@/ts/interfaces';
import { ROUTING_PATHS } from '@/ts/enum';
import { ALAPPUZHA_HSP_SOCIAL_LINKS } from '@/static/data';
import {
  KOZHENCHERRY_HSP_SOCIAL_LINKS,
  PUNALUR_HSP_SOCIAL_LINKS,
} from '@/static/data/hospitalsSocialLinks';

export default defineComponent({
  name: 'Footer',
  data(): FooterDateInterface {
    return {
      footerClass: '',
      linksColor: '',
      address: { heading: '', address: '' },
      phone: '',
      email: '',
      toRoutes: {
        toDefaultHome: this.$route.meta.toDefaultHome,
        toHome: this.$route.meta.toHome,
        toAboutUs: this.$route.meta.toAboutUs,
        toDoctors: this.$route.meta.toDoctors,
        toDepartments: this.$route.meta.toDepartments,
        toFacilities: this.$route.meta.toFacilities,
        toGallery: this.$route.meta.toGallery,
        toNews: this.$route.meta.toNews,
        toCarrers: this.$route.meta.toCarrers,
        toTestimonials: this.$route.meta.toTestimonials,
        toContactUs: this.$route.meta.toContactUs,
        toAppointment: this.$route.meta.toAppointment,
      },
    };
  },
  props: {
    footerColor: {
      type: String,
      required: true,
    },
    showLogo: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    if (this.footerColor === 'blue') {
      this.footerClass = 'bg-color-white pt-5';
      this.linksColor = 'text-red link';
    }
    if (this.footerColor === 'white') {
      this.linksColor = 'text-white link';
      this.footerClass = 'bg-color-blue pt-5';
    }

    if (this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_HOME) {
      this.email = ALAPPUZHA_HSP_SOCIAL_LINKS.email;
      this.phone = ALAPPUZHA_HSP_SOCIAL_LINKS.contactNumber;
      this.address = {
        heading: 'Contact Providence Hospital',
        address:
          'Thumpoly Road, Near Pomkavu NH66 Junction, Thumpoly, Alappuzha, Kerala 688521',
      };
    }
    if (
      this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_KOZHENCHERRY_HOME
    ) {
      this.email = KOZHENCHERRY_HSP_SOCIAL_LINKS.email;
      this.phone = KOZHENCHERRY_HSP_SOCIAL_LINKS.contactNumber;
      this.address = {
        heading: 'Contact Poyanil Hospital',
        address:
          'Poyanil Junction, Kozhencherry Pathanamthitta District, Kerala 689641',
      };
    }

    if (this.$route.meta.toHome === ROUTING_PATHS.TO_POYANIL_PUNALUR_HOME) {
      this.email = PUNALUR_HSP_SOCIAL_LINKS.email;
      this.phone = PUNALUR_HSP_SOCIAL_LINKS.contactNumber;
      this.address = {
        heading: 'Contact Poyanil Hospital',
        address: 'Poyanil Hospital Chemmanthoor, Punalur, Kerala 691305',
      };
    }
  },
});
</script>

<style>
.bg-color-white {
  color: #fff;
}

.blacktext a {
  color: #293745 !important;
  text-decoration: none;
}

.blacktext a:hover {
  color: #1a5ec3 !important;
}

.copyright-red {
  background-color: #de0f17;
}

.copyright-blue {
  background-color: #1a5ec3;
}

.text-black dl {
  color: #293745;
}

a:hover {
  text-decoration: none !important;
}
</style>
