<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Obstetrics & Gynaecology</h2>
      <p>
        The department of Obstetrics and Gynaecology provides the best services
        in this part of the district. It has one of the most qualified and
        experienced consultants who have many years of service.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/obstetrics-gynaecology.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>Surgeries offered include:-</p>

        <p><strong>OBSTETRICS:</strong></p>
        <ol>
          <li>
            Routine and Antenatal cases - Antenatal care to normal and high risk
            pregnancies.
          </li>
          <li>
            High risk obstetric case - Consultation, Advanced pre and Antenatal
            diagnosis, ICU management of high risk cases.
          </li>
          <li>
            Family planning.
          </li>
          <li>
            Ultrasound- Offered to all pregnant women to detect abnormalities,
            growth of foetus and to offer the best treatment and investigations
            for high risk cases.
          </li>
        </ol>
      </div>
    </div>
  </div>

  <div class="container mt-4">
    <div class="row">
      <div class="col-lg-6">
        <div class="facilities-box text-left">
          <p><strong>GYNECOLOGY:</strong></p>
          <ol>
            <li>
              General Women Health -
              <ul>
                <li>Routine Gynaecological Case.</li>
                <li>Adolescent Gynaecological Case.</li>
                <li>Menstrual Disorders</li>
                <li>Breast Disease Screening</li>
                <li>Menopause Management</li>
              </ul>
            </li>
            <li>Menstrual Disorders - AUB, DUB, Adenomyosis Treatment</li>
            <li>
              Adolescant Gynaecology- Diagnosis And Management of PCOD, Anemia,
              Puberty, Menorrhagia.
            </li>

            <li>
              Uro-Gynaecology
              <ul>
                <li>Pelvic Organ Prolapse</li>
                <li>Urinary Incontinence</li>
              </ul>
            </li>

            <li>
              Menopausal Problems
              <ul>
                <li>Speciality clinics</li>
                <li>Antenatal clinic</li>
                <li>Postnatal case and family planning clinic</li>
                <li>Breast disease clinic</li>
                <li>Menopausal clinic</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="facilities-box text-left">
          <ul class="list-unstyled">
            <li>
              Gynaecological Surgeries
              <ul>
                <li>
                  Uterus-
                  <ul>
                    <li>Open and Laparoscopic Myomectomy for Fibroids.</li>
                    <li>TAH and TLH</li>
                    <li>Open and Laparoscopic Sterilisation.</li>
                  </ul>
                </li>

                <li>
                  Ovarian-
                  <ul>
                    <li>Ovarian drilling(Laparoscopic) for PCOD.</li>
                    <li>Ovarian Biopsy</li>
                    <li>Cystectomy(Open and Laparoscopic)</li>
                    <li>Oophorectomy (Open and Laparoscopic)</li>
                  </ul>
                </li>

                <li>
                  Tubal surgeries-
                  <ul>
                    <li>
                      Salpingectomy and Salpingostomy For Ectopic Gestation(
                      laparoscopic and open)
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="facilities-box mt-3 text-left">
          <p>Soon to start-</p>
          <ol>
            <li>Infertility Clinic</li>
            <li>Gynaecological Oncology</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Obstetrics And Gynaecology Doctors",
      queryName: "Obstetrics And Gynaecology",
      teamName: "Obstetrics And Gynaecology team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate2.scss";
</style>
