
import { defineComponent } from "vue";
import { OurFacilitiesDataInterface } from "@/ts/interfaces";
import { ourFacilities } from "@/static/data";

export default defineComponent({
  data(): OurFacilitiesDataInterface {
    return {
      ourFacilities,
    };
  },
});
