
import { defineComponent } from 'vue';

import { getNewsCollection } from '@/graphql';
import { useQuery } from '@/hooks';
import Loader from '@/components/LoadingAnimation.vue';
import Appointment from '@/components/News/BookAppointement.vue';
import ContactLinks from '@/components/News/ContactLinks.vue';
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';

export default defineComponent({
  data(): NewsListDataInterface {
    return {
      newsList: [],
      loading: true,
    };
  },
  components: {
    Loader,
    Appointment,
    ContactLinks,
  },

  mounted() {
    (async () => {
      const { data, loading, errors } = await useQuery(getNewsCollection, {
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
      });

      this.loading = loading;
      this.newsList = data.newsCollection.items as NewsListInterface[];
    })();
  },
  methods: {
    getDate(datetime: string) {
      let date = new Date(datetime).toString().split(' GMT')[0];
      return date;
    },
  },
});

interface NewsListInterface {
  subHeadline?: string;
  headline?: string;
  sys?: { id: string; publishedAt: string };
  thumbnail?: { url: string };
}

interface NewsListDataInterface {
  loading: boolean;
  newsList: NewsListInterface[];
}
