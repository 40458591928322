<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Radiology</h2>
      <p>
        Radiology is the medical discipline that uses medical imaging to
        diagnose and treat diseases within the bodies of animals, including
        humans.
      </p>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/radiology.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p>
          Radiology is a branch of medical science, which use radiation,
          ultrasound, and magnetic resonance as diagnostic, therapeutic, and
          research tools in medical practice. The Department of Radiology plays
          an important and significant role in the health care and academic
          activities of the Institute.
        </p>

        <p>
          We are a 24 hour service department that provides the following
          services: Ultrasonography including Doppler, X - rays and Digital
          Radiographs.
        </p>
        <p>
          Our department provides high precision guideline based diagnostic and
          imaging facilities to patients with a multi disciplinary approach.
        </p>
      </div>
    </div>
  </div>

  <div class="container mt-4">
    <p>We offers the following facilities:-</p>
    <div class="row mt-5">
      <div class="col-lg-6">
        <div class="facilities-box text-left">
          <p>1. High speed CT Scan-</p>
          <ul>
            <li>
              CT plain and contrast-Head, Neck, Thorax, Abdomen, Pelvis, CT
              extremities, Spine with 3D reconstruction.
            </li>
            <li>CT Urogram, CT Fistulogram.</li>
            <li>HRCT Temporal Bone, HRCT Thorax.</li>
            <li>CT guided Interventions-Biopsy, FNAC of mass lesions.</li>
          </ul>
        </div>

        <div class="facilities-box mt-3">
          <p>2. Plain Radiography With Digital Computed Radiography.</p>
          <ul>
            <li>
              X-Ray Stitching, Retrograde Urethrography(RGU), Micturating
              Cystourethrography(MCU), IVP.
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="facilities-box text-left">
          <p>3. State of the art Ultrasound-</p>
          <p class="mb-0">Fields of Interest-</p>
          <ul>
            <li>
              Obstetric and Gynecological Ultrasound which Includes Doppler,
              Follicular Studies.
            </li>
            <li>Musculoskeletal Ultrasound, Soft Tissue Ultrasound.</li>
            <li>
              Head and Neck Ultrasound-Includes Thyroid Scan, Neck Vessels,
              Salivary Glands, Cysts, Abscesses, Lesion.
            </li>
            <li>Breast ultrasound Including Intervention.</li>
            <li>
              Ultrasound Abdomen-includes USG KUB, Hepato-Bilary, Portal Vein
              Doppler, Renal Doppler.
            </li>
            <li>USG Scrotum, Inguinal Regions.</li>
            <li>Penile Doppler</li>
            <li>
              Vascular Imaging-Upper And Lower Limb Arterial And Venous System,
              Carotid Doppler, Renal, Portal Vein Doppler
            </li>
            <li>Thoracic Ultrasound</li>
            <li>Neurosonogram</li>
            <li>
              Special procedures-Transrectal Ultrasound, Intussusception
              Reduction, Aspiration of Pleural Fluid, Ascetic Fluid, Abscesses,
              FNAC/Biopsy.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate2.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Radiology Doctors",
      queryName: "Radiology",
      teamName: "Radiology team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate2.scss";
</style>
