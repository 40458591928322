
/**
 * @Shared between hospital 1 and 2
 */

import { defineComponent } from "vue";
import { CarouselPropType } from "@/ts/interfaces";

export default defineComponent({
  name: "Carousel",
  props: {
    carouselItems: {
      type: Object as () => CarouselPropType,
      required: true,
    },
  },
});
