
import { defineComponent } from "vue";

import Template from "@/components/Templates/Punalur.vue";
import Testimonials from "@/components/Testimonials/Template3/index.vue";

export default defineComponent({
  components: {
    Template,
    Testimonials,
  },
});
