<template>
  <Template>
    <ContactUs />
  </Template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Template from '@/components/Templates/Punalur.vue';
import ContactUs from '@/components/ContactUs/screen/Template3/index.vue';
import { CONTACT_US_ROUTE_NAME } from '@/ts/enum';

export default defineComponent({
  name: CONTACT_US_ROUTE_NAME.CONTACT_US_POYANIL_PUNALUR,
  components: {
    ContactUs,
    Template,
  },
});
</script>
