
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "Layout",
  props: {
    headerTitle: {
      type: String,
      required: true,
    },
    sinceYear: {
      type: Number,
      required: true,
    },

    imgSub: {
      type: Boolean,
    },
    logoIco: {
      type: String,
      required: true,
    },
    footerColor: {
      type: String,
      required: true,
    },
    showFooterLogo: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Header,
    NavBar,
    Footer,
  },
});
