
import { defineComponent } from "vue";

import Template from "@/components/Templates/Kozhencherry.vue";
import Careers from "@/components/Careers/Template2/index.vue";

export default defineComponent({
  components: {
    Careers,
    Template,
  },
});
