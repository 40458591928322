
import { defineComponent } from "vue";

import { WhatWeDoBestDataInterface } from "@/ts/interfaces";
import { whatWeDoBestItems } from "@/static/data";

export default defineComponent({
  data(): WhatWeDoBestDataInterface {
    return {
      items: whatWeDoBestItems,
    };
  },
  mounted() {},
});
