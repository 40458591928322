
import { defineComponent } from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/src/jquery.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'video.js/dist/video-js.css';

import Header from '@/components/Header.vue';
import SocialIcons from '@/components/SocialIcons.vue';

import { AppDataInterface } from '@/ts/interfaces';

export default defineComponent({
  name: 'App',
  components: { Header, SocialIcons },
  data(): AppDataInterface {
    return {
      hospitalName: 'A Unit Of Poyanil Hospital',
      sinceYear: 1990,
    };
  },
});
