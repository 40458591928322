<template>
  <Header
    :bgIMG="bgImg"
    :isCenter="true"
    :subTitle="'Poyanil Hospital'"
    :title="'Gallery'"
  />
  <Gallery />
  <Stats />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import Stats from "@/components/Stats.vue";
import Gallery from "@/components/Gallery/Template2/Gallery.vue";

export default defineComponent({
  components: {
    Header,
    Stats,
    Gallery,
  },
  data() {
    return {
      bgImg: require("@/assets/img/doctors-banner.png"),
    };
  },
});
</script>
