<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Neuro Medicine</h2>
      <p>
        The services of highly qualified neuro physicians are available here.
        Specialised treatment for stroke, epilipsey, etc is available.
      </p>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate1.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Neuromedicine Doctors",
      queryName: "Neuromedicine",
      teamName: "Neuromedicine team",
    };
  },
  components: { DoctorsList },
});
</script>

<style scoped lang="scss">
@import "@/scss/sectionTemplate1.scss";
</style>
