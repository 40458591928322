<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-5">
        <address>
          <ul class="list-unstyled">
            <li class="media">
              <img
                class="mr-3"
                src="@/assets/img/location.svg"
                alt="location-image"
              />
              <div class="media-body">
                <h4 class="mt-0 mb-1 text-blue">Address</h4>
                <p>
                  Poyanil Hospital, <br />
                  Chemmanthoor, Punalur, Kerala 691305
                </p>
              </div>
            </li>

            <li class="media">
              <img class="mr-3" src="@/assets/img/call.svg" alt="call-image" />
              <div class="media-body">
                <h4 class="mt-0 mb-1 text-blue">Call Us</h4>
                <p>
                  0475-2230600<br />
                  +91 9188387073
                </p>
              </div>
            </li>

            <li class="media">
              <img
                class="mr-3"
                src="@/assets/img/email.svg"
                alt="email-image"
              />
              <div class="media-body">
                <h4 class="mt-0 mb-1 text-blue">Email</h4>
                <p>admin.poyanilplr@gmail.com</p>
              </div>
            </li>
          </ul>
        </address>
      </div>

      <div class="col-lg-6 offset-lg-1">
        <div class="section-title">
          <h2>Contact Form</h2>
          <p>
            Please feel free to contact us with any query, feedback or just to
            get in touch with us. We look forward to hearing from you.
          </p>
        </div>

        <div class="contact-form" @submit.prevent="sendContactUsForm">
          <form>
            <div class="row">
              <div class="col-lg-6">
                <input
                  v-model.trim="name"
                  type="text"
                  ref="name"
                  class="form-control form-box"
                  placeholder="Name"
                />
              </div>
              <div class="col-lg-6">
                <input
                  v-model.trim="email"
                  type="text"
                  ref="email"
                  class="form-control form-box"
                  placeholder="E-mail"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-lg-6">
                <input
                  ref="contactNumber"
                  v-model.number="contactNumber"
                  type="text"
                  class="form-control form-box"
                  placeholder="Phone"
                />
              </div>
              <div class="col-lg-6">
                <input
                  v-model="subject"
                  ref="subject"
                  type="text"
                  class="form-control form-box"
                  placeholder="Subject"
                />
              </div>
            </div>

            <div class="form-group mt-4">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="4"
                v-model.trim="message"
                ref="message"
                placeholder="Message"
              ></textarea>
            </div>

            <p
              class="alert alert-danger"
              style="text-align:center;"
              v-if="error"
            >
              {{ error }}
            </p>

            <p
              class="alert alert-success"
              style="text-align:center;"
              v-if="sent"
            >
              Please wait, we will get back to you shortly
            </p>
            <div class="col-lg-4 col-12 mt-5">
              <button
                style="width: auto;"
                :disabled="sent || sending"
                type="submit"
                class="btn btn-primary btn-lg btn-block submit read-more-btn"
              >
                {{ sending ? 'Please wait' : 'Submit' }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <section
    @click="openMap"
    class="map"
    :style="{ 'background-image': 'url(' + mapIMG + ')', cursor: 'pointer' }"
  />
</template>

<script lang="ts">
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';
import { validateEmail } from '@/ts/functions';
import { defineComponent } from 'vue';

export interface ContactSchemaInterface {
  name?: string | null;
  email?: string | null;
  contactNumber?: number | null;
  subject?: string | null;
  message?: string | null;
  to?: string;
  hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES;
}

export interface ContactDataInterface {
  name: string | null;
  email: string | null;
  contactNumber: number | null;
  subject: string | null;
  message: string | null;
  mapIMG: string;
  error: null | string;
  sent: boolean;
  sending: boolean;
}

export default defineComponent({
  data(): ContactDataInterface {
    return {
      mapIMG: require('@/assets/img/map3.jpg'),
      name: null,
      email: null,
      contactNumber: null,
      subject: null,
      message: null,
      error: null,
      sent: false,
      sending: false,
    };
  },

  methods: {
    openMap() {
      window.open(
        `https://www.google.com/maps/place/Poyanil+Hospital/@9.0199927,76.9075519,17z/data=!4m12!1m6!3m5!1s0x3b0674188232c4f5:0x3fe79a598b761617!2sPoyanil+Hospital!8m2!3d9.0199927!4d76.909746!3m4!1s0x3b0674188232c4f5:0x3fe79a598b761617!8m2!3d9.0199927!4d76.909746`,
        '_blank'
      );
    },
    missingInputHandler(errMessage: string, ref: HTMLElement): void {
      this.error = errMessage;

      setTimeout(() => {
        this.error = null;
        ref.focus();
        ref.className = ref.className + ' is-invalid';
      }, 1000);

      setTimeout(() => {
        ref.className = ref.className.replace('is-invalid', '');
      }, 2000);
    },

    async sendContactUsForm() {
      if (!this.name && this.$refs.name instanceof HTMLInputElement) {
        this.missingInputHandler('Enter your name', this.$refs.name);
        return;
      }

      if (!this.email && this.$refs.email instanceof HTMLInputElement) {
        this.missingInputHandler('Enter your email-address', this.$refs.email);
        return;
      }

      if (
        this.email &&
        !validateEmail(this.email) &&
        this.$refs.email instanceof HTMLInputElement
      ) {
        this.missingInputHandler('Enter valid email-address', this.$refs.email);
        return;
      }

      if (
        !this.contactNumber &&
        this.$refs.contactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your contact number',
          this.$refs.contactNumber
        );
        return;
      }

      if (
        this.contactNumber &&
        this.contactNumber.toString().length !== 10 &&
        this.$refs.contactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter valid contact number',
          this.$refs.contactNumber
        );
        return;
      }

      if (!this.subject && this.$refs.subject instanceof HTMLInputElement) {
        this.missingInputHandler('Enter the subject', this.$refs.subject);
        return;
      }

      // if (!this.message && this.$refs.message instanceof HTMLInputElement) {
      //   this.missingInputHandler("Enter message", this.$refs.message);
      //   return;
      // }

      if (!process.env.VUE_APP_EMAIL_THREE) {
        return;
      }

      const postDataModel: ContactSchemaInterface = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
        to: process.env.VUE_APP_EMAIL_THREE,
        contactNumber: this.contactNumber,
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_PUNALUR,
      };

      try {
        this.sending = true;
        await this.axios.post(
          `${process.env.VUE_APP_BASE_URI}/api/v1/service/contact_us`,
          postDataModel
        );
        this.sending = false;
        this.sent = true;
      } catch (e) {
        console.clear();
        this.sending = false;
      }
    },
  },
});
</script>

<style scoped>
.read-more-btn,
.contact-btn {
  background: #1a5ec3;
  border-color: #1a5ec3;
  padding: 12px 30px 12px 30px;
  text-transform: uppercase;
  transition: 0.5s;
}
.read-more-btn:hover {
  background: #de0f17;
  border-color: #de0f17;
  transform: translateY(-5px);
}
</style>
