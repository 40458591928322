
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate1.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Neuromedicine Doctors",
      queryName: "Neuromedicine",
      teamName: "Neuromedicine team",
    };
  },
  components: { DoctorsList },
});
