<template>
  <div class="appointment-form">
    <h3 class="text-blue mb-4">Application Form</h3>
    <p>
      Please fill in the details below and submit.
    </p>
    <form @submit.prevent="handleApplicationSetup" novalidate>
      <h3 class="text-blue mb-4">Patient Details</h3>

      <div class="row">
        <div class="col-lg-6">
          <input
            type="text"
            v-model="name"
            ref="name"
            class="form-control form-box"
            placeholder="Name"
          />
        </div>
        <div class="col-lg-6">
          <input
            type="email"
            v-model="email"
            ref="email"
            class="form-control form-box"
            placeholder="E-mail"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6">
          <input
            v-model.number="contactNumber"
            ref="contactNumber"
            type="tel"
            class="form-control form-box"
            placeholder="Contact number"
          />
        </div>
        <div class="col-lg-6">
          <input
            v-model.number="experience"
            ref="experience"
            type="tel"
            class="form-control form-box"
            placeholder="Experience"
          />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6">
          <div class="content">
            <div class="box">
              <input
                type="file"
                name="resume"
                class="inputfile inputfile-1"
                ref="resume"
                id="file-1"
                @change="onFileChange"
                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
              <label for="file-1"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="17"
                  viewBox="0 0 20 17"
                >
                  <path
                    d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                  />
                </svg>
                <span>{{ fileCTX }}</span></label
              >
            </div>
          </div>
        </div>
      </div>

      <p class="alert alert-danger" style="text-align:center;" v-if="error">
        {{ error }}
      </p>

      <p class="alert alert-success" style="text-align:center;" v-if="sent">
        Thank You, Application submitted successfully, We will be in touch with
        you soon
      </p>

      <div class="row ">
        <div class="col-3 mt-5">
          <button
            :disabled="sending"
            type="submit"
            style="width: auto;"
            class="btn btn-primary btn-lg btn-block submit"
          >
            {{ sending ? 'Please wait' : 'Submit' }}
          </button>
        </div>

        <div :v-if="!sending" class="col-3 mt-5">
          <button
            :disabled="sending"
            @click="setShowApplication"
            class="btn btn-danger btn-lg btn-block reject"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';
import { defineComponent } from 'vue';

interface JobApplicationDataInterface {
  bgIMG: string;
  name?: string;
  email?: string;
  contactNumber?: number | null;
  experience?: number | null;
  resume?: Blob | null;
  fileCTX?: string;
  error?: null | string;
  sending: boolean;
  sent: boolean;
}

export default defineComponent({
  props: {
    setShowApplication: {
      type: Function,
      required: true,
    },
    setAppliedStatus: {
      type: Function,
      required: true,
    },
    careerName: {
      type: String,
      required: true,
    },
    hospital: {
      type: Number,
      required: true,
    },
  },
  data(): JobApplicationDataInterface {
    return {
      bgIMG: require('@/assets/img/about-banner.png'),
      name: '',
      email: '',
      contactNumber: null,
      experience: null,
      resume: null,
      fileCTX: 'Upload your resume',
      error: null || '',
      sending: false,
      sent: false,
    };
  },
  methods: {
    onFileChange(e: Event) {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];
      this.fileCTX = file.name || 'resume' + 'uploaded';
      this.resume = file;
    },
    missingInputHandler(errMessage: string, ref: HTMLElement): void {
      this.error = errMessage;

      setTimeout(() => {
        this.error = null;
        ref.focus();
        ref.className = ref.className + ' is-invalid';
      }, 1000);

      setTimeout(() => {
        ref.className = ref.className.replace('is-invalid', '');
      }, 2000);
    },

    async handleApplicationSetup() {
      if (!this.name && this.$refs.name instanceof HTMLInputElement) {
        this.missingInputHandler('Enter your name', this.$refs.name);
        return;
      }

      if (!this.email && this.$refs.email instanceof HTMLInputElement) {
        this.missingInputHandler('Enter your email', this.$refs.email);
        return;
      }

      if (
        !this.contactNumber &&
        this.$refs.contactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your contact Number',
          this.$refs.contactNumber
        );
        return;
      }

      if (
        this.contactNumber &&
        this.contactNumber.toString().length !== 10 &&
        this.$refs.contactNumber instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter valid contact number',
          this.$refs.contactNumber
        );
        return;
      }

      if (
        !this.experience &&
        this.$refs.experience instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your experience in years',
          this.$refs.experience
        );
        return;
      }

      if (
        typeof this.experience !== 'number' &&
        this.$refs.experience instanceof HTMLInputElement
      ) {
        this.missingInputHandler(
          'Enter your experience in years',
          this.$refs.experience
        );
        return;
      }

      if (!this.resume && this.$refs.resume instanceof HTMLElement) {
        this.missingInputHandler('Select your resume', this.$refs.resume);
        return;
      }

      if (
        !this.name ||
        !this.email ||
        !this.contactNumber ||
        !this.experience ||
        !this.resume
      ) {
        return;
      }

      if (!process.env.VUE_APP_EMAIL_ONE) {
        return;
      }

      let to: string = '';
      let hspName: string = '';

      if (this.hospital === 1 && process.env.VUE_APP_EMAIL_ONE) {
        to = process.env.VUE_APP_EMAIL_ONE;
        hspName = GRAPHQL_QUERYING_HOSPITAL_NAMES.PROVIDENCE_HOSPITAL_ALAPPUZHA;
      }

      if (this.hospital === 2 && process.env.VUE_APP_EMAIL_TWO) {
        to = process.env.VUE_APP_EMAIL_TWO;
        hspName = GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY;
      }

      if (this.hospital === 3 && process.env.VUE_APP_EMAIL_THREE) {
        to = process.env.VUE_APP_EMAIL_THREE;
        hspName = GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_PUNALUR;
      }

      const formData = new FormData();

      formData.append('name', this.name);
      formData.append('email', this.email);
      formData.append('contactNumber', this.contactNumber.toString());
      formData.append('experience', this.experience.toString());
      formData.append('resume', this.resume);
      formData.append('to', to);
      formData.append('job', this.careerName);
      formData.append('subject', 'New job application recieved');
      formData.append('hospital', hspName);

      try {
        this.sending = true;
        await this.axios.post(
          `${process.env.VUE_APP_BASE_URI}/api/v1/jobs/job_application`,
          formData
        );

        this.sending = false;
        this.sent = true;
        this.setAppliedStatus();
        setTimeout(() => {
          this.setShowApplication();
        }, 2000);
      } catch (e) {
        this.sending = false;
        console.clear();
        alert('something went wrong');
      }
    },
  },
});
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

/* Content */

.content {
  width: 100%;
  max-width: 80vw;
}

.content footer {
  color: #b39295;
  margin-top: 40px;
}

.content footer a:hover,
.content footer a:focus {
  color: #4b0f31;
}

/********** File Input Style starts here ********/

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  max-width: 80%;
  font-size: 1.25rem;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.no-js .inputfile + label {
  display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}

/* style 1 */

.inputfile-1 + label {
  color: #f1e5e6;
  background-color: #d3394c;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
  background-color: #722040;
}

/* style 2 */

.inputfile-2 + label {
  color: #d3394c;
  border: 2px solid currentColor;
}

.inputfile-2:focus + label,
.inputfile-2.has-focus + label,
.inputfile-2 + label:hover {
  color: #722040;
}

/* style 3 */

.inputfile-3 + label {
  color: #d3394c;
}

.inputfile-3:focus + label,
.inputfile-3.has-focus + label,
.inputfile-3 + label:hover {
  color: #722040;
}

/* style 4 */

.inputfile-4 + label {
  color: #d3394c;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
  color: #722040;
}

.inputfile-4 + label figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #d3394c;
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
  background-color: #722040;
}

.inputfile-4 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}

/* style 5 */

.inputfile-5 + label {
  color: #d3394c;
}

.inputfile-5:focus + label,
.inputfile-5.has-focus + label,
.inputfile-5 + label:hover {
  color: #722040;
}

.inputfile-5 + label figure {
  width: 100px;
  height: 135px;
  background-color: #d3394c;
  display: block;
  position: relative;
  padding: 30px;
  margin: 0 auto 10px;
}

.inputfile-5:focus + label figure,
.inputfile-5.has-focus + label figure,
.inputfile-5 + label:hover figure {
  background-color: #722040;
}

.inputfile-5 + label figure::before,
.inputfile-5 + label figure::after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
}

.inputfile-5 + label figure::before {
  border-top: 20px solid #dfc8ca;
  border-left: 20px solid transparent;
}

.inputfile-5 + label figure::after {
  border-bottom: 20px solid #722040;
  border-right: 20px solid transparent;
}

.inputfile-5:focus + label figure::after,
.inputfile-5.has-focus + label figure::after,
.inputfile-5 + label:hover figure::after {
  border-bottom-color: #d3394c;
}

.inputfile-5 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}

/* style 6 */

.inputfile-6 + label {
  color: #d3394c;
}

.inputfile-6 + label {
  border: 1px solid #d3394c;
  background-color: #f1e5e6;
  padding: 0;
}

.inputfile-6:focus + label,
.inputfile-6.has-focus + label,
.inputfile-6 + label:hover {
  border-color: #722040;
}

.inputfile-6 + label span,
.inputfile-6 + label strong {
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.inputfile-6 + label span {
  width: 200px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}

.inputfile-6 + label strong {
  height: 100%;
  color: #f1e5e6;
  background-color: #d3394c;
  display: inline-block;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
  background-color: #722040;
}

@media screen and (max-width: 50em) {
  .inputfile-6 + label strong {
    display: block;
  }
}
</style>
