<script lang="ts">
import { otherHospitalsNavList } from "@/ts/functions";
import { OtherHospitalRouterLink } from "@/ts/interfaces";
import { defineComponent } from "vue";
import { RouteMeta } from "vue-router";

export default defineComponent({
  name: "Nav Bar",

  data(): NavBarDataInterface {
    return {
      observer: null,
      fixNav: false,
      toRoutes: {
        toDefaultHome: this.$route.meta.toDefaultHome,
        toHome: this.$route.meta.toHome,
        toAboutUs: this.$route.meta.toAboutUs,
        toDoctors: this.$route.meta.toDoctors,
        toDepartments: this.$route.meta.toDepartments,
        toFacilities: this.$route.meta.toFacilities,
        toGallery: this.$route.meta.toGallery,
        toNews: this.$route.meta.toNews,
        toCarrers: this.$route.meta.toCarrers,
        toTestimonials: this.$route.meta.toTestimonials,
        toContactUs: this.$route.meta.toContactUs,
        toAppointment: this.$route.meta.toAppointment,
      },
      ourHospitals: [{ link: "", name: "" }],
    };
  },

  mounted() {
    this.ourHospitals = otherHospitalsNavList();

    const element: HTMLElement | null = document.getElementById(
      "fixed-nav-ref"
    );

    this.observer = new IntersectionObserver((entries) => {
      const firstEntery = entries[0];
      if (firstEntery.isIntersecting) {
        this.fixNav = false;
      } else {
        this.fixNav = true;
      }
    });
    if (element && this.observer instanceof IntersectionObserver) {
      this.observer.observe(element);
    }
  },
  unmounted() {
    if (this.observer instanceof IntersectionObserver) {
      this.observer.disconnect();
    }
  },
});

interface NavBarDataInterface {
  observer: IntersectionObserver | null;
  fixNav: boolean;
  toRoutes: RouteMeta;
  ourHospitals: OtherHospitalRouterLink[];
}
</script>

<template>
  <div id="fixed-nav-ref" />
  <div id="navbar" class="menu" :class="{ fixed: fixNav }">
    <div class="container">
      <nav class="navbar navbar-expand-lg auto-hiding-navbar navbar-light p-0">
        <div class="container-fluid p-0">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-content"
            aria-controls="navbar-content"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbar-content">
            <ul class="navbar-nav ml-auto nav-left">
              <li class="nav-item mx-1">
                <router-link class="nav-link" :to="toRoutes.toHome">
                  Home</router-link
                >
              </li>
              <li class="nav-item mx-1">
                <router-link class="nav-link" :to="toRoutes.toAboutUs">
                  About</router-link
                >
              </li>
              <li class="nav-item dropdown nav-pt">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Our Hospitals
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link
                    class="dropdown-item"
                    v-for="({ name, link }, i) in ourHospitals"
                    :key="i"
                    :to="link"
                    >{{ name }}</router-link
                  >
                </div>
              </li>
              <li class="nav-item dropdown nav-pt">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Departments
                </a>
                <div
                  class="dropdown-menu drops-nav-links"
                  aria-labelledby="navbarDropdown"
                  style="max-height: 80vh;overflow-x: scroll;"
                >
                  <router-link
                    v-for="({ name, path }, i) in toRoutes.toDepartments"
                    :key="i"
                    :to="path"
                    class="dropdown-item"
                    >{{ name.split("_").join(" ") }}</router-link
                  >
                </div>
              </li>
              <li class="nav-item dropdown nav-pt">
                <a
                  class="nav-link dropdown-toggle "
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Facilities
                </a>
                <div
                  class="dropdown-menu drops-nav-links"
                  aria-labelledby="navbarDropdown"
                  style="max-height: 80vh;overflow-x: scroll;"
                >
                  <router-link
                    v-for="({ name, path }, i) in toRoutes.toFacilities"
                    :key="i"
                    :to="path"
                    class="dropdown-item"
                    >{{ name.split("_").join(" ") }}</router-link
                  >
                </div>
              </li>
              <li class="nav-item mx-1">
                <router-link class="nav-link" :to="toRoutes.toDoctors">
                  Doctors</router-link
                >
              </li>
              <li class="nav-item mx-1">
                <router-link class="nav-link" :to="toRoutes.toGallery"
                  >Gallery</router-link
                >
              </li>
              <li class="nav-item mx-1">
                <router-link class="nav-link" :to="toRoutes.toNews"
                  >News</router-link
                >
              </li>
              <li class="nav-item mx-1">
                <router-link class="nav-link" :to="toRoutes.toCarrers"
                  >Careers</router-link
                >
              </li>
              <li class="nav-item mx-1">
                <router-link class="nav-link" :to="toRoutes.toTestimonials"
                  >Testimonials</router-link
                >
              </li>
              <li class="nav-item mx-1">
                <router-link class="nav-link" :to="toRoutes.toContactUs"
                  >Contact Us</router-link
                >
              </li>

              <li class="nav-item mx-1">
                <router-link class="nav-link" :to="toRoutes.toAppointment">
                  <button type="button" class="btn btn-link appointment-link">
                    Appointment
                  </button>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<style>
.drops-nav-links {
  text-transform: capitalize;
}
</style>
