<script lang="ts">
import { MAIN_ROUTING_NAMES } from '@/ts/enum';
import { LandingPageDataInterface } from '@/ts/interfaces';
import { defineComponent } from 'vue';
import SocialIcons from '../SocialIcons.vue';
// import AppointmentShortCut from "@/components/AppointmentFlatIcon.vue"

export default defineComponent({
  data(): LandingPageDataInterface {
    return {
      observer: null,
      fixNav: false,
      alappuzhaRoute: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL,
      kozhencherryRoute: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
      pulnalurRoute: MAIN_ROUTING_NAMES.POYANIL_HOSPITAL_PUNALUR,
    };
  },
  components: {
    SocialIcons,
    // AppointmentShortCut
  },
  methods: {
    handleNursingRedirect: () => {
      window.open('https://www.poyanilcollegeofnursing.in', '_blank');
    },
  },

  mounted() {
    const element: HTMLElement | null = document.getElementById(
      'fixed-nav-ref'
    );

    this.observer = new IntersectionObserver((entries) => {
      const firstEntery = entries[0];
      if (firstEntery.isIntersecting) {
        this.fixNav = false;
      } else {
        this.fixNav = true;
      }
    });
    if (element && this.observer instanceof IntersectionObserver) {
      this.observer.observe(element);
    }
  },
  unmounted() {
    if (this.observer instanceof IntersectionObserver) {
      this.observer.disconnect();
    }
  },
});
</script>

<template>
  <!-- <social-icons v-if="!$route.meta.toHome" /> -->
  <div id="top-navbar" class="menu" :class="{ fixed: fixNav }">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-4 m-auto pt-2 pb-2">
          <img src="@/assets/landing/img/logo.png" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-5">
    <div class="col-lg-4 col-12 m-auto">
      <div id="fixed-nav-ref" class="section-title text-center">
        <h2>Our Hospitals</h2>
      </div>
    </div>
    <div class="col-lg-12" id="our-hospital">
      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div class="d-none d-md-block">
            <div class="grid">
              <figure class="effect-layla">
                <img
                  src="@/assets/landing/img/hospital-image-1.jpg"
                  alt="img06"
                />
                <figcaption>
                  <h2>Providence Hospital <br />Alappuzha</h2>
                  <!-- <p>Lorem ipsum dolor sit amet</p> -->
                  <router-link :to="{ name: alappuzhaRoute }">1 </router-link>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class="d-block d-sm-none">
            <router-link :to="{ name: alappuzhaRoute }">
              <button type="button" class="btn btn-primary btn-lg btn-block">
                Providence Hospital Alappuzha
              </button>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6">
          <div class="d-none d-md-block">
            <div class="grid">
              <figure class="effect-layla">
                <img
                  src="@/assets/landing/img/kozhencherry_hsp.jpg"
                  alt="img06"
                />
                <figcaption>
                  <h2>Poyanil Hospital<br />Kozhencherry</h2>
                  <!-- <p>Lorem ipsum dolor sit amet</p> -->
                  <router-link :to="{ name: kozhencherryRoute }"
                    >View more</router-link
                  >
                </figcaption>
              </figure>
            </div>
          </div>
          <div class="d-block d-sm-none">
            <router-link :to="{ name: kozhencherryRoute }">
              <button type="button" class="btn btn-primary btn-lg btn-block">
                Poyanil Hospital Kozhencherry
              </button>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6">
          <div class="d-none d-md-block">
            <div class="grid">
              <figure class="effect-layla">
                <img src="@/assets/landing/img/punalur_hsp.jpg" alt="img06" />
                <figcaption>
                  <h2>
                    Poyanil Hospital<br />
                    Punalur
                  </h2>
                  <!-- <p>Lorem ipsum dolor sit amet</p> -->
                  <router-link :to="{ name: pulnalurRoute }"
                    >View more</router-link
                  >
                </figcaption>
              </figure>
            </div>
          </div>
          <div class="d-block d-sm-none">
            <router-link :to="{ name: pulnalurRoute }">
              <button type="button" class="btn btn-primary btn-lg btn-block">
                Poyanil Hospital Punalur
              </button></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-5" id="institutions">
    <div class="col-lg-4 col-12 m-auto">
      <div class="section-title text-center">
        <h2>Our Institutions</h2>
        <p>
          We also run and operate a few other Healthcare institutions around
          kerala. To know more about our other institutions see below
        </p>
      </div>
    </div>

    <div class="col-lg-12" id="our-hospital">
      <div class="row" style="justify-content: center;">
        <div class="col-lg-4 col-sm-6" @click="handleNursingRedirect">
          <div class="d-none d-md-block">
            <div class="grid">
              <figure class="effect-layla">
                <img
                  src="@/assets/landing/img/hospital-image-1.jpg"
                  alt="img06"
                />
                <figcaption>
                  <h2>
                    Poyanil College of Nursing
                  </h2>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class="d-block d-sm-none">
            <button
              @click="handleNursingRedirect"
              type="button"
              class="btn btn-primary btn-lg btn-block"
            >
              Poyanil College of Nursing
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="facilities">
    <div class="container mb-5">
      <div class="col-lg-6 col-12 m-auto">
        <div class="section-title text-center">
          <h2>Awesome Facilities</h2>
          <p>
            Our state of the art facilities will ensure you have the best
            treatment available. Please go through some our facilities below to
            know more
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-sm-6" style="display:grid">
          <div class="facilities-box">
            <img
              src="@/assets/landing/img/facilities-icon-1.svg"
              class="img-fluid icon"
            />
            <h3>24 Hour Pharmacy</h3>
            <p>
              Our Pharmacy consists of certified professionals providing round
              the clock pharmacy service to patients. Our pharmacies cater to
              around 450 out patients and around 100 in-patients a day.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6" style="display:grid">
          <div class="facilities-box">
            <img
              src="@/assets/landing/img/facilities-icon-2.svg"
              class="img-fluid"
            />
            <h3>24 Hour Clinic</h3>
            <p>
              If it’s an emergency, every second is crucial and so is the
              treatment given. Our 24 Hour Clinic provides daily, around the
              clock, medical services to patients in need.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6" style="display:grid">
          <div class="facilities-box">
            <img
              src="@/assets/landing/img/facilities-icon-3.svg"
              class="img-fluid"
            />
            <h3>Cathlab 24 X 7</h3>
            <p>
              A catheterization laboratory, commonly referred to as a cath lab,
              is an examination room in a hospital or clinic with diagnostic
              imaging equipment used to visualize the arteries of the heart and
              the chambers of the heart and treat any stenosis or abnormality
              found.
            </p>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-4 col-sm-6" style="display:grid">
          <div class="facilities-box">
            <img
              src="@/assets/landing/img/facilities-icon-4.svg"
              class="img-fluid icon"
            />
            <h3>ICU’s</h3>
            <p>
              ICU is the most important unit of a hospital and is considered as
              the backbone of all medical and surgical procedures. The
              department has a 24 X 7 critical care unit that is led by senior
              anesthetists and physicians assisted by Nurse Practitioners,
              Junior Doctors, Technicians, Physiotherapists, Nutritionists,
              Radiologists and Imaging Technicians.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6" style="display:grid">
          <div class="facilities-box">
            <img
              src="@/assets/landing/img/facilities-icon-5.svg"
              class="img-fluid"
            />
            <h3>Clinical Laboratory</h3>
            <p>
              State of art automated analyzers and other equipment that render
              accurate and precise test results at the shortest time possible.
              Manned by highly qualified and experienced technicians and
              ancillary staff who follow stringent quality control measures.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6" style="display:grid">
          <div class="facilities-box">
            <img
              src="@/assets/landing/img/facilities-icon-6.svg"
              class="img-fluid"
            />
            <h3>Dialysis 24 X 7</h3>
            <p>
              Dialysis is a treatment that filters and purifies the blood using
              a machine. This helps keep your fluids and electrolytes in balance
              when the kidneys can't do their job. Dialysis has been used since
              the 1940s to treat people with kidney problems.
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="copy text-center text-white">
        <p>
          ©Copyright 2021 <strong>Providence Hospital</strong> All Rights
          Reserved
        </p>
      </div>
    </footer>
  </div>
  <!-- <AppointmentShortCut/> -->
</template>

<style scoped>
h2 {
  font-size: 35px;
  color: #1a5ec3;
  font-family: 'ProximaNovaBold';
}

.thin {
  font-family: 'Proxima-Nova-Thin';
}

.text-red {
  color: #de0f17 !important;
}
.text-blue {
  color: #1a5ec3;
}

#top-navbar {
  margin-top: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.4s;
}

#top-navbar .fa-envelope {
  color: #de0f17;
}
#top-navbar .fa-facebook {
  color: #3b5998;
  font-size: 20px;
}

#top-navbar ul {
  margin-top: 8%;
}
#top-navbar .list-inline-item:not(:last-child) {
  margin-right: 3rem;
}

.fixed {
  background-color: #fff;
  position: fixed;
  width: 100%;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  left: 0;
  top: 0;
  z-index: 25;
}

/*=====================================================================*/
/*===== Button css and form css, Heading broder css =====*/

.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
}
.section-title:after,
.section-title:before {
  content: '';
  position: absolute;
  width: 3px;
  height: 50%;
  left: 10px;
  top: 0;
  background: #de0f17;
  transition: 0.5s;
}

.section-title:after {
  left: 0;
  height: 100%;
}

#our-hospital .btn-primary {
  margin: auto;
  display: block;
  background: #de0f17;
  border-color: #de0f17;
  padding: 14px;
  font-weight: 800;
  margin-bottom: 70px;
}

/*===== Button css and form css, Heading broder css =====*/
/*=====================================================================*/

#facilities {
  margin-top: 100px;
  background: rgba(52, 55, 145, 0.04);
  border-radius: 50% 0 0 0;
  padding-bottom: 1px;
  padding-top: 50px;
}

.facilities-box {
  box-shadow: 0 8px 16px 0 rgba(146, 184, 255, 0.2);
  padding: 30px 20px;
}
.facilities-box img {
  margin-bottom: 25px;
}
.facilities-box p {
  margin-top: 20px;
  padding-bottom: 20px;
}

.facilities-box:hover {
  background: #fff;
}

.copy {
  background-color: #1a5ec3;
  padding-top: 15px;
  padding-bottom: 10px;
  /*margin-top: 80px;*/
}
.copy p {
  padding-top: 15px;
}

/*===============================================================================================================*/
/************************************** Slider Css ***************************************************/

.slider {
  margin: 0%;
  padding: 0%;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  background: #0f0f0f;
  position: relative;
}

.image-slider {
  z-index: 0;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100vh;
}

.image-slide {
  height: 100vh;
  margin: 0 auto;
}

.slick-slide.slick-center {
  transform: scale(1.2);
  transition: transform 0.8s 1.4s cubic-bezier(0.84, 0, 0.08, 0.99);
}

.slick-slide {
  transition: transform 0.7s cubic-bezier(0.84, 0, 0.08, 0.99);
}

.slider-control {
  margin: 0%;
  position: absolute;
  z-index: 2;
  bottom: 4%;
  left: 15%;
  transform: translate(-50%, -50%);
  display: flex;
}

.slider button {
  color: #fff;
  background: none;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 16px;
  border-radius: 50%;
  margin: 0.4em;
  display: inline-block;
}

.slider button:focus {
  outline: none;
}

.block-1 {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 5%;
  left: 0%;
  background: #0f0f0f;
}

.block-2 {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 25%;
  left: 25%;
  background: #0f0f0f;
}

.block-3 {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 5%;
  right: 0%;
  background: #0f0f0f;
}

.overlay {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 20%;
  left: 5%;
  background: rgba(0, 0, 0, 0.65);
}

.text-slider-wrapper {
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 30%;
}

.text-slider {
  margin: 0%;
  padding: 0%;
  height: 100vh;
}

.text-slide h1 {
  color: #fff;
  font-size: 64px;
  font-family: 'ProximaNovaBold';
  font-weight: lighter;
  text-transform: uppercase;
  padding-left: 10%;
}

@media (max-width: 990px) {
  .block-2,
  .overlay {
    display: none;
  }

  .block-1 {
    width: 20%;
  }

  .block-3 {
    width: 12%;
  }

  .slide-slick {
    display: none !important;
  }

  .text-slide h1 {
    font-size: 30px !important;
  }

  .text-slider-wrapper {
    position: absolute;
    top: 20% !important;
  }

  .slider-control {
    left: 22.5%;
  }
}

/************************************** Slider Css close ***************************************************/
/*===============================================================================================================*/
.grid {
  position: relative;
  margin: 0 auto;
  padding: 1em 0 4em;
  max-width: 1000px;
  list-style: none;
  text-align: center;
}

/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 10px 1%;
  /*min-width: 608px;*/
  max-width: 608px;
  max-height: 470px;
  width: 100%;
  background: #000;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/*---------------*/
/***** Layla *****/
/*---------------*/

figure.effect-layla {
  background: #1a5ec3;
}

figure.effect-layla img {
  height: 100%;
}

figure.effect-layla figcaption {
  padding: 3em;
}

figure.effect-layla figcaption::before,
figure.effect-layla figcaption::after {
  position: absolute;
  content: '';
  opacity: 0;
}

figure.effect-layla figcaption::before {
  top: 50px;
  right: 30px;
  bottom: 50px;
  left: 30px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

figure.effect-layla figcaption::after {
  top: 30px;
  right: 50px;
  bottom: 30px;
  left: 50px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

figure.effect-layla h2 {
  padding-top: 26%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  color: #fff;
}

figure.effect-layla p {
  padding: 0.5em 2em;
  text-transform: none;
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

figure.effect-layla img,
figure.effect-layla h2 {
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
}

figure.effect-layla img,
figure.effect-layla figcaption::before,
figure.effect-layla figcaption::after,
figure.effect-layla p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-layla:hover img {
  opacity: 0.7;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-layla:hover figcaption::before,
figure.effect-layla:hover figcaption::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-layla:hover h2,
figure.effect-layla:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-layla:hover figcaption::after,
figure.effect-layla:hover h2,
figure.effect-layla:hover p,
figure.effect-layla:hover img {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

#institutions h3 {
  box-shadow: 0 8px 16px 0 rgb(146 184 255 / 20%);
  color: #1a5ec3;
  padding: 20px;
}

#floating-button {
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgb(146 184 255 / 20%);
  position: fixed;
  padding-top: 10px;
  width: 50px;
  right: 0;
  top: 30%;
  z-index: 50;
}

#floating-button p {
  text-align: center;
  border-bottom: 1px solid #f1f1f1;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
}

#floating-button p i {
  color: #f6f6f6;
  font-size: 22px;
}
#floating-button .fa-whatsapp {
  color: #2cb742;
}
#floating-button .fa-phone-alt,
#floating-button .fa-envelope,
#floating-button .fa-facebook-f {
  color: #1a5ec3;
}

/*========== media ==========*/

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
  .slider {
    height: 40vh;
  }
  #facilities {
    border-radius: 0;
  }
}

@media (max-width: 414px) {
  h2 {
    font-size: 25px;
  }
  #top-navbar ul {
    margin-top: 15%;
  }
  #top-navbar .mail-id {
    font-size: 12px;
  }
  #top-navbar .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  #top-navbar .fa-facebook {
    font-size: 15px;
  }
  .slider {
    height: 50vh;
  }

  #our-hospital .btn-primary {
    margin-bottom: 20px;
  }
}
</style>
