<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Neuro Surgery ( Brain & Spine Surgery )</h2>
      <p>
        Neurosurgery, or neurological surgery, is the medical specialty
        concerned with the prevention, diagnosis, surgical treatment, and
        rehabilitation of disorders which affect any portion of the nervous
        system including the brain, spinal cord, central and peripheral nervous
        system, and cerebrovascular system.
      </p>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <img
          src="@/assets/img/neuro-surgery.png"
          alt="..."
          class="img-thumbnail img-thumbnail-box"
        />
      </div>
      <div class="col-lg-8">
        <p><strong>Department of Brain and Spine Surgery</strong></p>
        <p>
          Our dedicated team is equipped and trained to manage the most
          complicated poly trauma cases
        </p>
        <p>Services provided:</p>
        <ol>
          <li>Traumatic Brain Injury management.</li>
          <li>Traumatic Spine Injury management.</li>
          <li>
            Pediatric Neurosurgeries for congenital neuro-spinal deformations.
          </li>
          <li>Surgery for Spinal tumors.</li>
          <li>
            Spine surgery for A.A.D, anterior cervical discectomy and surgery
            for degenerative spine (with and without instrumentation)
          </li>
          <li>Surgical procedures for stroke.</li>
          <li>Dedicated neuro-surgical ICU</li>
        </ol>
      </div>
    </div>
  </div>
  <DoctorsList :name="name" :queryName="queryName" :teamName="teamName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DoctorsList from "@/components/Departments/common/DoctorsListTemplate3.vue";
import { DepartmentPropDataInterface } from "@/ts/interfaces";

export default defineComponent({
  data(): DepartmentPropDataInterface {
    return {
      name: "Neurosurgery Doctors",
      queryName: "Neurosurgery",
      teamName: "Neurosurgery team",
    };
  },
  components: { DoctorsList },
});
</script>
