
import { defineComponent } from "vue";

import Header from "@/components/CarouselHeader.vue";
import Testimonials from "@/components/Testimonials/Template1/Lists.vue";
import Stats from "@/components/Stats.vue";

export default defineComponent({
  components: {
    Header,
    Testimonials,
    Stats,
  },
  data() {
    return {
      bgIMG: require("@/assets/img/doctors-banner.png"),
    };
  },
});
