<template>
  <div class="middle appointment-float-btn">
    <router-link :to="toLink" class="btn btn3"
      >Book <br />
      Appointment</router-link
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      toLink: this.$route.meta.toAppointment,
    };
  },
});
</script>

<style>
/* .appointment-float-btn {
  opacity: 0.8;
  transition: all 0.3s;
  z-index: 100;
}

.appointment-float-btn:hover,
.appointment-float-btn:active {
  opacity: 1;
} */
</style>
