
import { defineComponent } from 'vue';

import Loader from '@/components/LoadingAnimation.vue';
import { useQuery } from '@/hooks';
import { getCareerDetail } from '@/graphql';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  CareerDetailDataInterface,
  CareerDetailInterface,
} from '@/ts/interfaces';
import { CAREER_DETAIL_PAGE_NAMES } from '@/ts/enum';
import JobApplicationForm from '@/components/Job/index.vue';

export default defineComponent({
  components: { Loader, JobApplicationForm },
  data(): CareerDetailDataInterface {
    return {
      loading: true,
      careerDetail: {},
      showJobApplication: false,
      applied: false,
    };
  },
  methods: {
    documentToHtmlString,
    jobApplyStatus(): void {
      this.showJobApplication = !this.showJobApplication;
    },
    setAppliedStatus() {
      this.applied = true;
    },
  },
  mounted() {
    if (this.$route.params.careerId) {
      (async () => {
        const { data, loading, errors } = await useQuery(getCareerDetail, {
          id: this.$route.params.careerId,
        });

        this.loading = loading;

        this.careerDetail = data.careers as CareerDetailInterface;

        document.title =
          CAREER_DETAIL_PAGE_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY +
          this.careerDetail.jobTitle;
      })();
    }
  },
});
