
import { defineComponent } from "vue";
import { DOCTORS_ROUTE_NAMES } from "@/ts/enum";

import Template from "@/components/Templates/index.vue";
import Doctors from "@/components/Doctors/Template1/index.vue";

export default defineComponent({
  name: DOCTORS_ROUTE_NAMES.DOCTORS_POYANIL,
  components: {
    Doctors,
    Template,
  },
});
