<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>About Us</h2>
      <p>
        Kozhencherry is the home and origin of
        <strong> Poyanil Hospitals. </strong>
        <br />
        Poyanil hospital has been touching the lives of thousands of people
        since 1998. It was founded by Mr. George Joseph and we have been serving
        this community for the past 30+ years.
      </p>
      <p>
        Located at the heart of the town at Poyanil Junction our hospital
        provides the people with uncompromisingly affordable, quality
        healthcare. We Care about Kozhencherry and so We Serve Kozhencherry.
      </p>
    </div>

    <div class="row flex-column-reverse flex-lg-row">
      <div class="col-lg-5">
        <div class="about-image">
          <img src="@/assets/img/about-image.png" class="img-fluid" />
        </div>
      </div>

      <div class="col-lg-6 offset-lg-1">
        <section>
          <div class="media">
            <img
              class="align-self-start mr-3"
              src="@/assets/img/eye.svg"
              alt="eye-img"
            />
            <div class="media-body">
              <!-- <h5 class="mt-0">Top-aligned media</h5> -->
              <p>
                Poyanil Hospitals is a multi speciality medical centre serving
                the health care needs of Alappuzha and its adjoining areas.
              </p>
              <p class="thin">
                Our Mission "We Care, We Serve" is a personal promise from each
                of our staff to add our brand of humanity to the practice of
                medical science.
              </p>
              <p>
                Our Mission is to fulfill our patient's needs with a spirit of
                genuine care and concern for their welfare. We follow the
                highest ethical principles and provide our service in a fiscally
                responsible and prudent manner which generates measurable
                benefits for patients we serve. We strive for excellence,
                constantly improving everything we do.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({});
</script>

<style scoped>
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
  text-align: center;
}

.about-image,
.doctors-details {
  position: relative;
}
.about-image::before,
.doctors-details::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 15px;
  left: -15px;
  border-radius: 2px;
  border: 2px solid #1a5ec3;
  z-index: -1;
}
</style>
