<template>
  <div class="container mt-5">
    <div class="section-title">
      <h2>Our Doctors</h2>
      <!-- <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        <br />
        tempor incididunt ut labore et dolore magna aliqua.
      </p> -->
    </div>

    <div class="row">
      <Loader v-if="loading" />
      <h3 v-else-if="!doctorsList.length && !loading">No doctors found</h3>
      <div
        v-else
        class="col-lg-4 col-sm-6 col-12 mb-5"
        v-for="{
          name,
          sys: { id },
          centerOfExcellence,
          profilePic: { url },
        } in doctorsList"
        :key="id"
        style="display:grid;"
      >
        <div class="department-thumbnail">
          <img :src="url" class="img-fluid" />
        </div>
        <div
          class="department-thumbnail-content department-thumbnail-content-box-shadow "
        >
          <div class="doctor-name">
            <h3>
              {{ name }} <br />
              <small>{{ centerOfExcellence }}</small>
            </h3>
          </div>
          <router-link :to="`${$route.meta.toDoctors}/details/${id}`">
            <button type="button" class="btn btn-link department-btn">
              Details
              <span class="mt-1"
                ><i class="fas fa-angle-double-right pt-1"></i
              ></span></button
          ></router-link>
        </div>
      </div>
      <Loader v-if="moreLoading" />
      <div
        v-if="!moreLoading && !loading && doctorsList.length !== total"
        @click="handleViewMore()"
        class="btn btn-primary read-more-btn"
      >
        View more
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useQuery } from '@/hooks';
import { getListOfDoctors } from '@/graphql';
import {
  DoctorsBasicListDataType,
  DoctorsListDataInterface,
} from '@/ts/interfaces';
import Loader from '@/components/LoadingAnimation.vue';
import { GRAPHQL_QUERYING_HOSPITAL_NAMES } from '@/ts/enum';

export default defineComponent({
  data(): DoctorsListDataInterface {
    return {
      loading: true,
      doctorsList: [],
      limit: 6,
      moreLoading: false,
      total: 0,
    };
  },
  components: {
    Loader,
  },
  methods: {
    handleViewMore() {
      this.moreLoading = true;
      this.limit! += 6;
      (async () => {
        const { data, loading, errors } = await useQuery(getListOfDoctors, {
          hospital:
            GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
          limit: this.limit,
        });

        this.moreLoading = loading;
        this.doctorsList = data.doctorsCollection
          .items as DoctorsBasicListDataType[];
      })();
    },
  },

  mounted() {
    (async () => {
      const { data, loading, errors } = await useQuery(getListOfDoctors, {
        hospital: GRAPHQL_QUERYING_HOSPITAL_NAMES.POYANIL_HOSPITAL_KOZHENCHERRY,
        limit: this.limit,
      });

      this.loading = loading;
      this.doctorsList = data.doctorsCollection
        .items as DoctorsBasicListDataType[];
      this.total = data.doctorsCollection.total as number;
    })();
  },
});
</script>

<style scoped>
h3 {
  font-size: 20px;
  font-family: 'ProximaNovaBold';
}

.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
  text-align: center;
}

.department-thumbnail {
  position: relative;
  overflow: hidden;
}

.department-thumbnail-content {
  padding: 34px 12px 30px;
  margin: -55px 20px 0;
  text-align: center;
  border-bottom: 3px solid #1a5ec3;
  position: relative;
  background-color: #fff;
}

.department-thumbnail-content-box-shadow {
  -webkit-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  -moz-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
}
.doctor-name {
  color: #1a5ec3;
}
.department-desc p {
  font-size: 14px;
  margin-top: 1rem;
}

.department-btn {
  color: #de0f17;
  font-family: 'ProximaNovaBold';
}
.department-btn:hover {
  color: #1a5ec3;
  text-decoration: none;
}
.department-btn:focus {
  outline: 0;
  box-shadow: inherit;
}

.department-team {
  margin-bottom: 30px;
  text-align: center;
  background-color: #fafafa;
  position: relative;
  padding: 30px;
  border-radius: 5px;
}

.department-team .top img {
  border-radius: 50%;
  margin-bottom: 20px;
  max-width: 180px;
  margin-left: auto;
  margin-right: auto;
}

.read-more-btn,
.contact-btn {
  background: #1a5ec3;
  border-color: #1a5ec3;
  padding: 12px 30px 12px 30px;
  text-transform: uppercase;
  transition: 0.5s;
  margin: auto;
  display: block;
  width: 170px;
}
.read-more-btn:hover {
  background: #de0f17;
  border-color: #de0f17;
  transform: translateY(-5px);
}
</style>
